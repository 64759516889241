module.exports = `
... on ProductHeader {
  name
  tag
  description
  msrp
  ingredientsLink {
    label
    openInNewWindow
    page {
      url
    }
  }
  bvData {
    productId
  }
  productVariants: productVariantsCollection(limit: 8) {
    items {
      gtin
      size
      images: imagesCollection(limit: 15) {
        items {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
      }
    }
  }
  shareButton {
    title
    message
    hashtag
    buttonLabel
  }
  ariaLabel
}
`;
