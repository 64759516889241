import React from 'react';
import PropTypes from 'prop-types';

import { getFieldError } from './WriteReview.helper';
import labels from '../../constants/WriteReview.constants';

const ErrorMessage = ({ errors, errorMessages, id }) => {
    const error = getFieldError(errors);
    if (!error) return null;

    const errMessage = errorMessages.find(errMsg => errMsg.type === error);

    return <p id={id} role="alert" className="error">{(errMessage && errMessage.message) || labels.gerneral.genericFieldError }</p>;
};

ErrorMessage.propTypes = {
    id: PropTypes.string,
    errors: PropTypes.array,
    errorMessages: PropTypes.array,
};

export default ErrorMessage;
