module.exports = `
...on LandingPageBanner{
  __typename
    name
    leftImage{
      url
      description
    }
    rightImage{
      url
      description
    }
    bannercontent
    styles
  }
  
`;
