import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { buildFiltersWithOptions } from '../../../helpers/filtering';
import { getSortedFilters } from '../ProductListing.helper';
import AppContext from '../../../context/App.context';
import useClickedOutside from '../../Hooks/useClickedOutside';
import { Checkbox } from '../../UI';

const Filters = ({ activeFilters, onChange }) => {
    const { appData } = useContext(AppContext);
    const filtersWithOptions = getSortedFilters(buildFiltersWithOptions(appData.allFilters));
    const filtersRef = useRef();
    const [expandedSelector, setExpandedSelector] = useState('');
    const [isFilterActive, setFilterState] = useState(false);

    useClickedOutside(filtersRef, () => setExpandedSelector(''));

    const renderOption = (option, i) => {
        const { label, filterName } = option;

        const isChecked = Boolean(activeFilters[filterName] && activeFilters[filterName].find(filter => filter.label === label));

        return (
            <div className="option" key={i}>
                <Checkbox checked={isChecked} label={option.label} onChange={() => onChange(option)} />
            </div>
        );
    };

    const toggleFilters = (filterName, e) => {
        setFilterState(!isFilterActive);
        setExpandedSelector(filterName);
        if (e.key === 'Escape') setExpandedSelector('');
    };

    const renderFilter = (filter, i) => {
        const filterOptionsClassName = `filter-options ${expandedSelector === filter.filterName && isFilterActive ? '' : 'hidden'}`;

        return (
            <div
                className="filter-selector"
                onClick={(e) => toggleFilters(filter.filterName, e)}
                key={i}
                tabIndex={0}
                onKeyDown={(e) => toggleFilters(filter.filterName, e)}
            >
                {filter.filterName}
                <div className={filterOptionsClassName}>
                    {filter.options.map(renderOption)}
                </div>
            </div>
        );
    };

    return (
        <div className="filters" ref={filtersRef}>
            {filtersWithOptions.map(renderFilter)}
        </div>

    );
};

Filters.propTypes = {
    activeFilters: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Filters;
