import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import AppContext from '../../../context/App.context';
import { InternalLink, Img } from '../../UI';
import SearchMobile from '../Mobile/SearchMobile';
import labels from '../../../constants/label.constants';
import useScreenSize from '../../Hooks/useScreenSize';
import slashify from '../../../helpers/slashify';
import { getCdnImage } from '../../../adapters/cloudinary.adapter';

const Navigation = (props) => {
    const {
        navigationData,
        currentPath,
        setMobileNavState,
        isMobileNavOpen,
        openPopup,
        activeNavMenu,
        setActiveNavMenu,
        currentLocale,
        setMobileSearch } = props;

    const resolution = useScreenSize();
    const [activeImage, setActiveImage] = useState(null);

    const { appState } = useContext(AppContext);
    const { navigation } = appState;
    const { setIsNavigationRendered } = navigation;

    useEffect(() => {
        setIsNavigationRendered(true);
    }, []);

    const handleFeaturedImage = (url) => {
        const featureImage = !url ? null : url;
        setActiveImage(featureImage);
    };

    const navRef = useRef();
    const columnRef = useRef();

    const renderLink = (link) => {
        const url = slashify(link.url);
        const activeClass = url === currentPath ? 'current' : '';
        const imgUrl = get(link, 'page.featuredImage.cfmedia.url', '');

        return (
            <a
                onMouseOver={() => handleFeaturedImage(imgUrl)}
                onFocus={() => handleFeaturedImage(imgUrl)}
                onMouseOut={() => setActiveImage(null)}
                onBlur={() => setActiveImage(null)}
                className={`link event_menu_click ${activeClass}`}
                data-action-detail={`top_nav, ${process.env.DOMAIN_NAME}${url}`}
                href={url}
                title={link.title}
                target={link.target}
                aria-label={`Learn more about ${link && link.title && link.title.toLowerCase()}`}
            >
                {link.label}
            </a>
        );
    };

    const renderImage = (image) => {
        const imageSrc = activeImage === null ? image.src : activeImage;

        return (
            <Img src={imageSrc} className="column-image" alt="Column Image" />
        );
    };

    const renderColumn = (column) => {
        const { heading, links, exploreAllLink } = column;
        const renderColumnHeader = column.name === undefined ? '' : <h2 className="column-heading">{column.name}</h2>;
        const hLink = heading ? {
            url: heading.page.url,
            label: column.name,
        } : {};

        const closeNavOnLastItem = (ref, columnItem) => {
            if (columnItem.title === ref.current.lastChild.lastChild.innerHTML) {
                setActiveNavMenu(null);
            }
        };

        return (
            <nav>
                {
                    heading
                        ? <h2 className="column-heading"><InternalLink link={hLink} eventClass="event_menu_click" dataActionDetailPrefix="top_nav" /></h2>
                        : renderColumnHeader
                }
                <ul ref={columnRef}>
                    {links.map((columnItem, i) => columnItem.type === 'column-image'
                        ? <li key={i}>{renderImage(columnItem)}</li>
                        : <li onBlur={() => closeNavOnLastItem(columnRef, columnItem)} key={i}>{renderLink(columnItem)}</li>)}
                    {exploreAllLink && <li><InternalLink link={exploreAllLink} eventClass="event_menu_click" dataActionDetailPrefix="top_nav" /><i className="arrow" /></li>}
                </ul>
            </nav>
        );
    };

    const renderLandingPageLink = (link) => (
        <InternalLink link={link} className="all-products-link" eventClass="event_menu_click" dataActionDetailPrefix="top_nav" />
    );

    const itemActive = (columns) => {
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].type === 'column-links') {
                for (let j = 0; j < columns[i].links.length; j++) {
                    const { url } = columns[i].links[j];
                    const path = `${url}${url.slice(-1) === '/' ? '' : '/'}`;
                    if (path === currentPath) {
                        return 'active';
                    }
                }
            }
        }

        return '';
    };

    const renderDropdown = (item, index) => {
        const imgL = item.leftImage && getCdnImage(item.leftImage.cfmedia.url);
        const imgR = item.rightImage && getCdnImage(item.rightImage.cfmedia.url);
        const styles = {
            wallMenu: {
                height: item.label === 'Our Products' ? '540px' : '',
                backgroundImage: `url(${imgL}), url(${imgR})`,
            },
            items: { height: item.label === 'Our Products' ? '478px' : '' },
        };
        const activeClass = activeNavMenu === index ? 'active' : '';

        return (
            <>
                <a
                    id={item.label}
                    aria-haspopup="true"
                    className={itemActive(item.columns)}
                    tabIndex={0}
                    aria-label={`Learn more about ${item.label.toLowerCase()}`}
                >
                    {item.label}
                </a>
                <div
                    aria-expanded={activeNavMenu === index}
                    style={styles.wallMenu}
                    className={`wall-menu-item ${activeClass}`}
                >
                    {item.columns.map((column, i) => (
                        <div
                            style={styles.items}
                            className="items"
                            key={i}
                        >
                            {column.type === 'column-image' ? renderImage(column) : renderColumn(column)}
                        </div>
                    ))}
                    {item.categoryLandingPage && renderLandingPageLink(item.categoryLandingPage)}
                </div>
            </>
        );
    };

    const closeDesktopMenu = () => {
        setActiveNavMenu(null);
    };

    // Mobile nav
    const closeMobileNav = () => {
        if (isMobileNavOpen) {
            setMobileNavState(false);
        }
    };

    const closeWithEscape = (event) => {
        if (event && (event.key === 'Escape' || event.key === 'Esc')) {
            closeMobileNav();
            closeDesktopMenu();
        }
    };

    const toggleMobileNav = (event) => {
        if (event && event.key === 'Enter') {
            setMobileNavState(!isMobileNavOpen);
        }
    };

    useEffect(() => {
        if (process.browser) {
            navRef.current.onkeydown = closeWithEscape;
        }
    }, [isMobileNavOpen, navRef]);

    return (
        <div
            ref={navRef}
            style={{ zIndex: openPopup === null ? '9' : '' }}
            className="navigation"
            tabIndex={0}
            role="navigation"
        >
            <a rel="nofollow" role="button" className="skip-main" href="#collection-banner" onFocus={closeDesktopMenu}>{labels.skipToContent}</a>
            <a rel="nofollow" role="button" className="hiddenText" href="#OUR PRODUCTS">{labels.skipToOurProducts}</a>
            <a rel="nofollow" role="button" className="hiddenText" href="#OUR INGREDIENTS">{labels.skipToIngredients}</a>
            <a rel="nofollow" role="button" className="hiddenText" href="#OUR PHILOSOPHY">{labels.skipToOurPhilosophy}</a>
            <a rel="nofollow" role="button" className="hiddenText" href="#OUR KEW PARTNERSHIP">{labels.skipToOurKewPartnership}</a>
            <a rel="nofollow" role="button" className="hiddenText" href="#ARTICLES">{labels.skipToBlog}</a>
            <div
                className={`mobile-menu-icon hamburger event_button_click ${isMobileNavOpen ? 'is-active' : ''}`}
                role="button"
                tabIndex={0}
                data-action-detail={!isMobileNavOpen ? labels.navigation.openNavigation : labels.navigation.closeNavigation}
                aria-label={!isMobileNavOpen ? labels.navigation.openNavigation : labels.navigation.closeNavigation}
                aria-expanded={isMobileNavOpen ? 'true' : 'false'}
                onClick={() => setMobileNavState(!isMobileNavOpen)}
                onKeyPress={toggleMobileNav}
            >
                <span className="line" />
                <span className="line" />
                <span className="line" />
            </div>
            {resolution && resolution.isMobile
                ? (
                    <SearchMobile
                        redirectUrl={`/${currentLocale}/search/`}
                        setMobileSearch={setMobileSearch}
                    />
                )
                : null
            }
            <ul className="top-navigation-items">
                {navigationData.map((item, index) => item.type === 'link'
                    ? <li key={index}>{renderLink(item)}</li>
                    : (
                        <li
                            key={index}
                            onFocus={() => setActiveNavMenu(index)}
                            onMouseOver={() => setActiveNavMenu(index)}
                            onMouseLeave={() => setActiveNavMenu(null)}
                        >
                            {renderDropdown(item, index)}
                        </li>
                    ))}
            </ul>
        </div>
    );
};

Navigation.propTypes = {
    navigationData: PropTypes.array,
    currentPath: PropTypes.string,
    setMobileNavState: PropTypes.func,
    isMobileNavOpen: PropTypes.bool,
    openPopup: PropTypes.bool,
    activeNavMenu: PropTypes.number,
    setActiveNavMenu: PropTypes.func,
    currentLocale: PropTypes.string,
    setMobileSearch: PropTypes.func,
};

export default Navigation;
