module.exports = `
{
    productFilters: productFilterCollection {
      items {
        filterName
        nameEn
        label
      }
    }
  }`;
