import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import labels from '../../../constants/label.constants';
import { InternalLink } from '../../UI';
import AppContext from '../../../context/App.context';

const MobileNavigation = ({
    navigationData,
    mobileLinks,
    isMobileNavOpen,
    toggleCSelector,
    openPopup,
    setOpenPopup,
    isCSelectorOpen,
    setMobileNavState }) => {
    if (isCSelectorOpen) setMobileNavState(false);
    const mobileNavRef = useRef();

    const [isExpanded, setExpandedState] = useState(false);
    const [currentOpenLabel, setCurrentOpenLabel] = useState(null);

    const { appState } = useContext(AppContext);
    const { navigation } = appState;
    const { setIsNavigationRendered } = navigation;

    useEffect(() => {
        setIsNavigationRendered(true);
    }, []);

    const renderLinkIcon = () => (
        <span className="link-icon" />
    );

    const toggleExpandedState = (e) => {
        setExpandedState(true);
        setCurrentOpenLabel(e.target.innerText);
        if (currentOpenLabel === e.target.innerText && isExpanded) setExpandedState(false);
    };

    const renderExpandedCollapsedIcon = (isIconExpanded, expandingItemLabel) => (
        <span
            className={`icon ${isIconExpanded ? 'expanded-icon' : 'collapsed-icon'}`}
            onClick={toggleExpandedState}
            onKeyPress={toggleExpandedState}
        >
            {expandingItemLabel}
        </span>
    );

    const renderLink = (item, i) => {
        const backgroundImage = `url(${get(item, 'page.featuredImage.cfmedia.url', '')})`;
        const label = get(item, 'label', '');

        return (
            <InternalLink key={i} link={item} eventClass="event_menu_click" dataActionDetailPrefix="top_nav">
                <li className="link" key={i} aria-label={item.label}>
                    <div className="link-image" style={{ backgroundImage }} />
                    <span>{label}</span>
                </li>
            </InternalLink>
        );
    };

    // Category Popup
    const closePopupWithEnter = (event) => {
        if (event.key === 'Enter') {
            setOpenPopup(null);
        }
    };

    const closePopupWithEscape = (event) => {
        if (event && (event.key === 'Escape' || event.key === 'Esc')) {
            setOpenPopup(null);
        }
    };

    const toggleCategoryPopup = (event, index) => {
        if (event.key === 'Enter') {
            setOpenPopup(index);
        }
    };

    // Country selector
    const toggleCSWithEnter = (event) => {
        if (event.key === 'Enter') {
            toggleCSelector();
        }
    };

    useEffect(() => {
        if (process.browser) {
            mobileNavRef.current.onkeydown = closePopupWithEscape;
        }
    }, [openPopup, mobileNavRef]);

    const renderCategory = (column, i) => {
        const { links, exploreAllLink } = column;
        const openClass = openPopup === i ? 'open' : '';
        const icon = renderLinkIcon();

        return (
            <li className="category event_menu_click" key={i} aria-label={column.name} data-action-detail={column.name}>
                <a tabIndex={0} className="category-link" onClick={() => setOpenPopup(i)} onKeyPress={(e) => toggleCategoryPopup(e, i)}>{column.name} {icon}</a>
                <div className={`popup-container ${openClass}`}>
                    <div className={`category-popup ${openClass}`}>
                        <h4>{column.name.replace('By ', 'Select ')}:
                            <span
                                role="button"
                                tabIndex={0}
                                className="close"
                                onClick={() => setOpenPopup(null)}
                                onKeyPress={closePopupWithEnter}
                                aria-label={labels.navigation.closeCategory}
                            />
                        </h4>
                        <ul>
                            {links.map((link, index) => (renderLink(link, index)))}
                            {exploreAllLink && <li className="explore-all-link"><InternalLink link={exploreAllLink} eventClass="event_menu_click" dataActionDetailPrefix="top_nav" /></li>}
                        </ul>
                    </div>
                </div>
            </li>
        );
    };

    const renderLinkListing = (column, i) => (
        <li className="link-listing" key={i}>
            <ul>{column.links.map((link, index) => (renderLink(link, index)))}</ul>
        </li>
    );

    const renderLandingPageLink = (link) => {
        const icon = renderLinkIcon();
        return (
            <li className="category">
                <InternalLink
                    link={link}
                    className="category-link"
                    eventClass="event_menu_click"
                    dataActionDetailPrefix="top_nav"
                >
                    {labels.landingPageLabel}
                    {icon}
                </InternalLink>
            </li>
        );
    };

    const renderExpandingItem = (item, idx) => {
        const columnsWithoutImage = item.columns.filter(column => column.type === 'column-links');

        const className = `expanding-item event_menu_click ${isExpanded && item.label === currentOpenLabel ? 'expanded' : 'collapsed'}`;

        const icon = renderExpandedCollapsedIcon(isExpanded && item.label === currentOpenLabel, item.label);

        return (
            <li className={className} key={idx} aria-label={item.label} data-action-detail={item.label}>
                {icon}
                <div
                    role="button"
                    tabIndex={0}
                    aria-expanded={isExpanded}
                    onClick={toggleExpandedState}
                    onKeyPress={toggleExpandedState}
                >
                    <h3>
                        {item.label}
                    </h3>
                </div>
                <ul>
                    {columnsWithoutImage.map((column, i) => column.__typename === 'NavigationDropdownColumn'
                        ? renderCategory(column, i)
                        : renderLinkListing(column, i))}
                    {item.categoryLandingPage && renderLandingPageLink(item.categoryLandingPage)}
                </ul>
            </li>
        );
    };

    const renderMobileLinks = (link, i) => (
        <li className="mobile-link" key={i} aria-label={link.label} tabIndex={0}>
            <InternalLink link={link} eventClass="event_menu_click" dataActionDetailPrefix="top_nav" />
        </li>
    );

    return (
        <>
            <div ref={mobileNavRef} tabIndex={0} className={`mobile-navigation ${isMobileNavOpen ? 'open' : 'closed'}`}>
                <nav className="mobile-navigation-sidebar">
                    <ul className="top-navigation-items">
                        {navigationData.map((item, i) => item.type === 'link' ? renderLink(item, i) : renderExpandingItem(item, i))}
                    </ul>
                    <ul className="mobile-links">
                        <li className="mobile-link" tabIndex={0} onClick={toggleCSelector} onKeyPress={toggleCSWithEnter} aria-label={labels.countrySelector.label}>{labels.countrySelector.label}</li>
                        {mobileLinks.map((link, i) => renderMobileLinks(link, i))}
                    </ul>
                </nav>
            </div>
        </>
    );
};

MobileNavigation.propTypes = {
    navigationData: PropTypes.array,
    mobileLinks: PropTypes.array,
    isMobileNavOpen: PropTypes.bool,
    toggleCSelector: PropTypes.func,
    openPopup: PropTypes.bool,
    setOpenPopup: PropTypes.func,
    isCSelectorOpen: PropTypes.bool,
    setMobileNavState: PropTypes.func,
};

export default MobileNavigation;
