import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import BlockWrapper from '../BlockWrapper/BlockWrapper';
import BlockWrapperWithImage from '../BlockWrapperWithImage/BlockWrapperWithImage';
import { InternalLink, ExternalLink, MDLinkRenderer, ShareButton, Img } from '../UI';

const TextInfoBlock = (props) => {
    const { title, leftBlock, rightBlock, cornerImage, wrapper, share, currentPageUrl, ariaLabel, styles } = props;
    let Wrapper;
    let mainClass;
    if (styles === 'component-5') mainClass = 'component-5-text-info-block';
    else if (styles === 'component-6') mainClass = 'component-6-text-info-block';
    else mainClass = 'text-info-block';
    const c6_top_image = 'https://images.ctfassets.net/xq8n4mwvbz58/11KNnR0KolcFbGu08nN72S/8cc7408bed2223b7becbe13e4da27cd9/KEW_new_logo.png?h=250';
    const c6_bottom_image = 'https://images.ctfassets.net/xq8n4mwvbz58/Tc976aFDwR6ShqlqzZOqn/22849381fd8cc859b54fb56340addb6f/image_151.png?h=250';
    if (!wrapper) {
        const classNames = `no-wrapper ${styles}`;
        Wrapper = ({ children }) => <div className={classNames}>{children}</div>;
    } else {
        Wrapper = wrapper.__typename === 'BlockWrapper' ? BlockWrapper : BlockWrapperWithImage;
        mainClass = `${mainClass}${wrapper.topImage ? ' pulled' : ''}`;
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const richTextLinks = document.querySelectorAll('.no-wrapper .step-info-block .text-block p a');
            for (let i = 0; i < richTextLinks.length; i++) {
                richTextLinks[i].setAttribute('class', 'event_internal_link');
                richTextLinks[i].setAttribute('data-action-detail', richTextLinks[i].getAttribute('href'));
            }
        }

        if (typeof window !== 'undefined') {
            const richTextLinks11 = document.querySelectorAll('div.wrapper-block-column > div.block-column > div > p > a');
            for (let i = 0; i < richTextLinks11.length; i++) {
                richTextLinks11[i].setAttribute('class', 'event_internal_link');
                richTextLinks11[i].setAttribute('data-action-detail', richTextLinks11[i].getAttribute('href'));
            }
        }

        if (typeof window !== 'undefined') {
            const richTextLinks13 = document.querySelectorAll('div.layout > div.no-wrapper > div > div > p:nth-child(2) > a');
            for (let i = 0; i < richTextLinks13.length; i++) {
                richTextLinks13[i].setAttribute('class', 'event_outbound_link');
                richTextLinks13[i].setAttribute('data-action-detail', richTextLinks13[i].getAttribute('href'));
            }
        }

        if (typeof window !== 'undefined') {
            const richTextLinks11 = document.querySelectorAll('div.layout > div:nth-child(3) > div > div > p:nth-child(2) > a');
            for (let i = 0; i < richTextLinks11.length; i++) {
                richTextLinks11[i].setAttribute('class', 'event_internal_link');
                richTextLinks11[i].setAttribute('data-action-detail', richTextLinks11[i].getAttribute('href'));
            }
        }
    });

    if (typeof window !== 'undefined') {
        const richTextLinks3 = document.querySelectorAll('.column-text p a');
        for (let i = 0; i < richTextLinks3.length; i++) {
            richTextLinks3[i].setAttribute('class', 'event_internal_link');
            richTextLinks3[i].setAttribute('data-action-detail', richTextLinks3[i].getAttribute('href'));
        }
    }

    const renderColumnContent = (item, i) => {
        switch (item.__typename) {
            case 'ContentPlainTextMd':
                return (
                    <ReactMarkdown className="column-text" rehypePlugins={[rehypeRaw]} components={{ link: MDLinkRenderer }} key={i}>
                        {item.content}
                    </ReactMarkdown>
                );

            case 'Image':
                return (
                    <div className="column-image" key={i}>
                        <Img contentfulImage={item.cfmedia} alt={item.alt || item.cfmedia.description} className={item.styles} />
                    </div>
                );

            case 'LinkInternal':
                return <InternalLink className="column-link" link={item} key={i} />;

            case 'LinkExternal':
                return <ExternalLink data={item} className="column-link" key={i} />;

            default:
                return null;
        }
    };

    const renderShareButton = () => {
        if (share) {
            return (
                <ShareButton
                    url={currentPageUrl}
                    buttonLabel={share.buttonLabel}
                    hashtag={share.hashtag}
                    title={share.title}
                    message={share.message}
                    channels={['facebook', 'twitter', 'linkedin', 'email']}
                />
            );
        }
        return null;
    };

    return (
        <Wrapper {...wrapper}>
            {styles === 'component-6' ? (
                <div className="kewLogo">
                    <Img src={c6_top_image} alt="top_image" />
                </div>
            ) : null}
            {styles === 'component-2'
                ? (
                    <>
                        <div className="component-2-text-info-block" aria-label={ariaLabel}>
                            <div className="wrapper-block-column">
                                <div className="block-column left-column">
                                    {leftBlock.items.map(renderColumnContent)}
                                </div>
                                <div className="block-column right-column">
                                    {rightBlock.items.map(renderColumnContent)}
                                    {cornerImage && cornerImage.cfmedia && (<Img className="corner-image" contentfulImage={cornerImage.cfmedia} alt="Herbal Essences ingredient picture" />)}
                                </div>
                            </div>
                        </div>
                        <div className="block-image" />
                    </>
                )
                : (
                    <div className={mainClass} aria-label={ariaLabel}>
                        <ReactMarkdown className={`block-title ${share ? 'block-title-with-share' : ''}`} rehypePlugins={[rehypeRaw]}>
                            {title}
                        </ReactMarkdown>
                        {renderShareButton()}
                        <div className="wrapper-block-column">
                            <div className="block-column left-column">
                                {leftBlock.items.map(renderColumnContent)}
                            </div>
                            <div className="block-column right-column">
                                {rightBlock.items.map(renderColumnContent)}
                                {cornerImage && cornerImage.cfmedia && (<Img className="corner-image" contentfulImage={cornerImage.cfmedia} alt="Herbal Essences ingredient picture" />)}
                            </div>
                        </div>
                        {styles === 'component-6' ? (
                            <div className="tailimage">
                                <Img src={c6_bottom_image} alt="bottom_image" />
                            </div>
                        ) : null}
                    </div>
                )
            }
        </Wrapper>
    );
};

TextInfoBlock.propTypes = {
    title: PropTypes.string,
    leftBlock: PropTypes.object,
    rightBlock: PropTypes.object,
    cornerImage: PropTypes.object,
    wrapper: PropTypes.object,
    share: PropTypes.object,
    currentPageUrl: PropTypes.string,
    children: PropTypes.object,
    ariaLabel: PropTypes.string,
    styles: PropTypes.string,
};

export default TextInfoBlock;
