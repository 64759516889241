module.exports = `
... on IngredientsCarousel {
     slidesCollection {
       items {
         name
         title
         cloudinaryImage {
           name
           cfmedia {
              description
              url
              width
              height
           }
         }
         page {
           url
         }
       }
     }
     leftImage {
       cfmedia {
            description
            url
            width
            height
        }
     }
     rightImage {
       cfmedia {
            description
            url
            width
            height
        }
     }
    ariaLabel
   }
`;
