import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import StarRatings from 'react-star-ratings';
import labels from '../../../constants/label.constants';

const RatingStars = ({ averageRating = 0, showRatingsLabel, ratingsPostLabel, onChange, starDimension, ariaLabel, role, className = 'rating-stars' }) => {
    const sanitizaAverageRatingNumber = (number) => {
        if (!number || Number.isNaN(number)) {
            return 0;
        }

        return Number.isInteger(number) ? number : number.toFixed(1);
    };

    const ref = useRef();

    useEffect(() => {
        if (ref.current) ref.current.focus();
    }, []);

    const rating = Number.isNaN(averageRating) ? 0 : sanitizaAverageRatingNumber(averageRating);

    const pickRating = (e, value) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onChange(value);
        }
    };

    const renderKbdStar = (i) => {
        const value = i + 1;

        return (
            <a
                ref={ref}
                className="kbd-star"
                tabIndex={0}
                key={i}
                onKeyPress={(e) => pickRating(e, value)}
                style={{ width: starDimension }}
                role="button"
                aria-label={`${labels.writeReview.retingStarLabel} ${value}`}
            >
                {`Rating: ${value}`}
            </a>
        );
    };

    const renderKbdPicker = () => {
        return (
            <div className="kbd-picker" style={{ height: starDimension }}>
                {times(5, renderKbdStar)}
            </div>
        );
    };

    return (
        <div aria-label={`${ariaLabel} ${averageRating}/5`} role={role} className={className}>
            {
                process.browser && (
                    <StarRatings
                        rating={Number(rating)}
                        starDimension={starDimension || '17px'}
                        starSpacing="1px"
                        starRatedColor="#0E5329"
                        svgIconPath="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                        changeRating={onChange}
                    />
                )
            }
            {onChange && renderKbdPicker()}
            {showRatingsLabel && (
                <span className="rating-numbers">
                    {sanitizaAverageRatingNumber(averageRating)} / 5
                    {ratingsPostLabel && <span className="ratings-post-label"> {ratingsPostLabel}</span>}
                </span>
            )}
        </div>
    );
};

RatingStars.propTypes = {
    averageRating: PropTypes.number,
    ariaLabel: PropTypes.string,
    role: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    ratingsPostLabel: PropTypes.string,
    showRatingsLabel: PropTypes.bool,
    starDimension: PropTypes.string,
};

export default RatingStars;
