import React, { useContext } from 'react';
import get from 'lodash/get';
import Image from 'next/image';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { InternalLink, ExternalLink, Img } from '../UI';
import { bbbLogo, cookie, en } from '../../constants/label.constants';
import AppContext from '../../context/App.context';

const Footer = (props) => {
    const { socialBlockTitle, socialBlockLinksCollection, footerLinksCollection, ariaLabel, privacyLink } = props;
    const socialLinkItems = get(socialBlockLinksCollection, 'items', []);
    const socialLinks = socialLinkItems.map((item, index) => {
        return (
            <li key={index}>
                <ExternalLink data={item} eventClass="event_socialmedia_exit">
                    <Img contentfulImage={item.image.cfmedia} alt={item.image.name} />
                </ExternalLink>
            </li>
        );
    });
    const { appState } = useContext(AppContext);
    const { navigation } = appState;
    const { isNavigationRendered } = navigation;
    const { productListCollection } = appState;
    const { isModalOpen } = productListCollection;

    const footerLinkItems = get(footerLinksCollection, 'items', []);

    const footerLinks = footerLinkItems.map((item, index) => {
        return item.__typename === 'LinkInternal'
            ? <InternalLink key={index} link={item} eventClass="event_internal_link" dataActionDetailPrefix="bot_nav" />
            : <ExternalLink data={item} className="" key={index} en={en} privacyLink={privacyLink} />;
    });

    return (
        isNavigationRendered && (
            <footer aria-label={ariaLabel} id="footer-div" role="contentinfo">
                <div hidden={isModalOpen} aria-hidden={isModalOpen} tabIndex={isModalOpen ? -1 : 0} className="footer-inner">
                    <span
                        className="btnOneTrust"
                        dangerouslySetInnerHTML={{
                            __html: `<a class='cookieConsent' href='javascript:void(0)' onClick='Optanon && Optanon.ToggleInfoDisplay();' >
                            ${cookie.consent}</a>`,
                        }}
                        key="className"
                    />
                    <LazyLoad offset={20}>
                        <div className="social-smartlabel">
                            <div className="social-block">
                                <span>{socialBlockTitle}</span>
                                <div>
                                    <ul className="social-links">
                                        {socialLinks}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-links">
                            {footerLinks}
                        </div>
                        <div className="copyright">
                            <p>&copy; {new Date().getFullYear()} Procter &amp; Gamble</p>
                            <ExternalLink data={{ url: bbbLogo.url }}>
                                <Image className="BBBimg" alt={bbbLogo.alt} src={bbbLogo.src} width={15} height={23} />
                            </ExternalLink>
                        </div>
                    </LazyLoad>
                </div>
            </footer>
        )
    );
};

Footer.propTypes = {
    socialBlockTitle: PropTypes.string,
    socialBlockLinksCollection: PropTypes.object,
    footerLinksCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
    privacyLink: PropTypes.string,
};

export default Footer;
