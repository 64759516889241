const { validate, getFieldError, getErrorsAmount, getErrors } = require('./WriteReview.helper');
const { errorRules, validations, fieldNames } = require('./WriteReview.config');


describe('* Testing validate() function', () => {
    test('It should return undefined if there is a value for required filter', () => {
        expect(validate('some value', { rule: errorRules.REQUIRED })).toBe(undefined);
    });

    test('It should return "required" if there is empty string for required filter', () => {
        expect(validate('', { rule: errorRules.REQUIRED })).toBe(errorRules.REQUIRED);
    });

    test('It should return "required" if there is no value for required filter', () => {
        expect(validate(undefined, { rule: errorRules.REQUIRED })).toBe(errorRules.REQUIRED);
    });

    test('It should return "max_length" if value is bigger than the rule value', () => {
        expect(validate('abcdefgh', { rule: errorRules.MAX_LENGTH, ruleValue: 3 })).toBe(errorRules.MAX_LENGTH);
    });

    test('It should return "undefined" if value length is less to rule value', () => {
        expect(validate('abc', { rule: errorRules.MAX_LENGTH, ruleValue: 10 })).toBe(undefined);
    });

    test('It should return "undefined" if value length is less to rule value', () => {
        expect(validate('abc', { rule: errorRules.MAX_LENGTH, ruleValue: 3 })).toBe(undefined);
    });
    test('It should return "min_length" if value length  is less than the rule value', () => {
        expect(validate('abc', { rule: errorRules.MIN_LENGTH, ruleValue: 8 })).toBe(errorRules.MIN_LENGTH);
    });

    test('It should return "undefined" if value length is bigger than rule value', () => {
        expect(validate('abc', { rule: errorRules.MIN_LENGTH, ruleValue: 2 })).toBe(undefined);
    });

    test('It should return "undefined" if value length is bigger than rule value', () => {
        expect(validate('abc', { rule: errorRules.MIN_LENGTH, ruleValue: 3 })).toBe(undefined);
    });
});

describe('* Testing getFieldError() function', () => {
    test('It should return "required" if "required" is first in the array', () => {
        expect(getFieldError([errorRules.REQUIRED, errorRules.VALID_EMAIL])).toBe(errorRules.REQUIRED);
    });

    test('It should return null if there are no errors in the array', () => {
        expect(getFieldError([])).toBe(null);
    });

    test('It should return null there is non existing error in the array', () => {
        expect(getFieldError(['non existing error'])).toBe(null);
    });
});

describe('* Testing getErrorsAmount() function', () => {
    const errors = {
        someField: [errorRules.REQUIRED, errorRules.VALID_EMAIL],
        someOtherField: [],
        anotherField: [],
        oneMoreField: [errorRules.MAX_LENGTH],
    };

    test('It should return number 2 when there are 2 fields with errors', () => {
        expect(getErrorsAmount(errors)).toBe(2);
    });
});

describe('* Testing getErrors() function', () => {
    const fields = {
        [fieldNames.EMAIL]: 'invalidEmail',
        [fieldNames.REVIEW]: 'too short review...',
        [fieldNames.NICKNAME]: 'ValidNickname',
    };

    test('It should return error for invalid email address', () => {
        expect(getErrors(fields, validations).email).toEqual([errorRules.VALID_EMAIL]);
    });

    test('It should return return error for too short length', () => {
        expect(getErrors(fields, validations).review).toEqual([errorRules.MIN_LENGTH]);
    });

    test('It should return return nothing for valid field', () => {
        expect(getErrors(fields, validations).nickname).toEqual([]);
    });

    test('It should return the same amount of properties as there are fields', () => {
        expect(Object.keys(getErrors(fields, validations)).length).toEqual(Object.keys(validations).length);
    });
});
