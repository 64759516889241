import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { searchBtn } from '../../../constants/label.constants';

const SearchInput = ({ redirectUrl }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [fieldDirty, setFieldDirty] = useState(false);
    const onInputChange = (e) => {
        setFieldDirty(true);
        const content = e.target.value.toString().replace(/%/g, '');
        setSearchTerm(content);
    };

    const onInputBlur = () => {
        if (!searchTerm) {
            setFieldDirty(false);
        }
    };

    const onSearch = () => {
        if (typeof window !== 'undefined' && searchTerm) {
            window.location = `${redirectUrl}?q=${searchTerm}`;
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const inputClassName = fieldDirty ? 'dirty' : '';

    return (
        <div className="search-input" role="search">
            <input
                role="searchbox"
                id="search-field-dt"
                className={`js-search-field event_search ${inputClassName}`}
                value={searchTerm}
                onChange={onInputChange}
                onBlur={onInputBlur}
                onKeyDown={onKeyDown}
                placeholder="Enter your search term..."
            />
            <label htmlFor="search-field-dt">Search text field</label>
            <button
                type="button"
                className="js-search-button event_search"
                data-action-detail={searchTerm}
                onClick={onSearch}
                aria-label={searchBtn.ariaLabel}
            />
        </div>
    );
};

SearchInput.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
};

export default SearchInput;
