module.exports = `
{
  pageCollection(limit: 200) {
    items {
      sys {
        id
      }
      seoHead {
        nameEn
        pageType
        title
        description
        url
      }
    }
  }
}`;
