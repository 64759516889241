import React from 'react';
import PropTypes from 'prop-types';

const Loading = React.forwardRef(({ className }, ref) => {
    const loaderClassName = `loader-container ${className || ''}`;

    return (
        <div className={loaderClassName}>
            <div ref={ref} className="loader" />
        </div>
    );
});

Loading.propTypes = {
    className: PropTypes.string,
};

export default Loading;
