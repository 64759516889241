import React from 'react';
import get from 'lodash/get';
import Script from 'next/script';

const buildProductsShema = (productVariants, pageInfo, name, description, rating, totalReviewCount, reviews) => {
    const productItems = get(productVariants, 'items[0]', {});
    const productImage = get(productItems, 'images.items[0].cfmedia.url', '');
    const sku = get(productItems, 'gtin', '');

    const getReview = () => {
        if (reviews === undefined || reviews === []) return null;

        return (
            {
                '@type': 'Review',
                'reviewRating': {
                    '@type': 'Rating',
                    'ratingValue': reviews[0].Rating,
                    'bestRating': 5,
                },
                'author': {
                    '@type': 'Person',
                    'name': reviews[0].UserNickname,
                },
            }
        );
    };

    const schema = {
        '@context': 'https://www.schema.org',
        '@type': 'product',
        '@id': pageInfo,
        'brand': {
            '@type': 'Brand',
            'name': 'Herbal Essences',
        },
        'sku': sku,
        'gtin13': sku,
        'name': name,
        'image': productImage,
        'description': description,
        'review': getReview(),

    };

    return schema;
};

export default (productVariants, pageInfo, name, description, rating, totalReviewCount, reviews, domainName) => {
    const productschema = buildProductsShema(productVariants, pageInfo, name, description, rating, totalReviewCount, reviews, domainName);

    return (
        <Script
            strategy="afterInteractive"
            type="application/ld+json"
            key="seo"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(productschema) }}
        />
    );
};
