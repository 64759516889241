import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getCdnImage, getSrcSet } from '../../adapters/cloudinary.adapter';

const LandingPageBanner = (props) => {
    const { rightImage, bannercontent, styles } = props;
    return (
        <div className={styles}>
            <div className="banner-wrapper">
                <div className="rightimage">
                    <picture>
                        <source
                            srcSet={getSrcSet(rightImage?.url, 1600, 768)}
                            media="(min-width: 768px)"
                        />
                        <img src={getCdnImage(rightImage?.url)} alt={rightImage?.description} />
                    </picture>
                </div>
                <div className="bannerText">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {bannercontent}
                    </ReactMarkdown>
                </div>
            </div>
        </div>
    );
};

LandingPageBanner.propTypes = {
    leftImage: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
    }),
    rightImage: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
    }),
    bannercontent: PropTypes.string,
    styles: PropTypes.string,
};

export default LandingPageBanner;
