module.exports = `
... on HairQuiz {
  title
  link {
      label
      page {
          url
      }
  }
  image{
    name
    cfmedia {
      description
      url
      width
      height
    }
  }
  ariaLabel
}
`;
