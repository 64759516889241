import { useState, useEffect } from 'react';

const ACCESSIBILITY_BREAKPOINT = 1400;
const DESKTOP_BREAKPOINT = 1025;
const TABLET_BREAKPOINT = 768;

const useScreenSize = () => {
    const [width, setWidth] = useState(null);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        if (process.browser) {
            setWidth(window.innerWidth);
            const handleResize = () => setWidth(window.innerWidth);
            const handleScroll = () => setScrollY(window.scrollY);
            window.addEventListener('resize', handleResize);
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('resize', handleResize);
                window.removeEventListener('scroll', handleResize);
            };
        }
    }, []);

    if (!width) {
        return null;
    }

    return {
        width,
        scrollY,
        accessibilityZoom: width < ACCESSIBILITY_BREAKPOINT,
        isMobile: width < TABLET_BREAKPOINT,
        isTablet: width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT,
        isHandheld: width < DESKTOP_BREAKPOINT,
        isDesktop: width >= DESKTOP_BREAKPOINT,
    };
};

export default useScreenSize;
