module.exports = `
... on StepInfoBlock {
    topImage {
      name
      cfmedia {
        description
        url
        width
        height
      }
    }
    textBlock
    wrapper {
      __typename
      ... on BlockWrapper {
        leftImage {
          cfmedia {
            description
            url
            width
            height
          }
        }
        rightImage {
          cfmedia {
            description
            url
            width
            height
          }
        }
      }
      ... on BlockWrapperWithImage {
        topImage {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
        leftImage {
          cfmedia {
            description
            url
            width
            height
          }
        }
        rightImage {
          cfmedia {
            description
            url
            width
            height
          }
        }
        logos: logosCollection(limit: 2) {
          items {
            name
            cfmedia {
              description
              url
              width
              height
            }
          }
        }
      }
    }
    ariaLabel
}  
`;
