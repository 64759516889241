import React from 'react';
import { CarouselArrow } from '../UI';

export const settingsDesktop = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
};
