import { useEffect, useState } from 'react';
import Algolia from '../../adapters/algoliaSearch.adapter';

const useAlgolia = (searchTerm) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [searchResults, setSearchResults] = useState();

    useEffect(() => {
        setIsLoading(true);

        Algolia.getSearchResults(searchTerm).then(result => {
            setSearchResults(result);
            setIsLoading(false);
        }).catch(() => {
            setIsError(true);
            setIsLoading(false);
        });
    }, []);

    return {
        isLoading,
        isError,
        results: searchResults,
    };
};

export default useAlgolia;
