import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import get from 'lodash/get';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';
import { getImageData, transformImage } from '../../../helpers/imageFormat';
import { getCdnImage } from '../../../adapters/cloudinary.adapter';

const noop = () => undefined;

const Img = ({
    contentfulImage,
    src,
    alt,
    name,
    className,
    loading,
    additionalParams,
    disableLazyLoad = false,
    onClick = noop,
    skipWH = false,
}) => {
    if (!contentfulImage && !src) return null;
    const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });
    const img = contentfulImage && getImageData(contentfulImage, additionalParams);

    const placeholderH = get(img, 'height', 200);
    const placeholderStyle = {
        height: `${placeholderH}px`,
        display: 'block',
    };

    const imgSrc = get(img, 'url', transformImage(src, additionalParams));
    const imgAlt = get(img, 'alt', alt);
    const imgW = skipWH ? null : get(img, 'width', null);
    const imgH = skipWH ? null : get(img, 'height', null);

    const isInView = disableLazyLoad ? true : inView;

    return (
        <>
            {isInView ? (
                <Image
                    src={getCdnImage(imgSrc) || getCdnImage(src)}
                    alt={imgAlt || alt}
                    name={name}
                    className={className}
                    onClick={onClick}
                    width={imgW !== null ? imgW : 0}
                    height={imgH !== null ? imgH : 0}
                    style={imgW === null && { width: 'auto' }}
                    loading={loading || 'lazy'}
                />
            ) : (
                <span style={placeholderStyle} ref={ref} className={className} />
            )}
        </>
    );
};

Img.propTypes = {
    additionalParams: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    contentfulImage: PropTypes.object,
    disableLazyLoad: PropTypes.bool,
    onClick: PropTypes.func,
    skipWH: PropTypes.bool,
    src: PropTypes.string,
    name: PropTypes.string,
    loading: PropTypes.string,
};

export default Img;
