import React from 'react';
import PropTypes from 'prop-types';

import { ExternalLink } from '../../UI';

import labels from '../../../constants/WriteReview.constants';
import { writeReview } from '../../../constants/label.constants';

const WriteReviewFooter = ({ onPreviewClick, onCancelClick, gtin }) => {
    return (
        <div className="write-review-footer">
            <div>
                <div className="field-label" />
                <div className="footer-content">
                    <button type="button" data-action-detail={gtin} className="secondary-button event_cancel_review" onClick={onCancelClick} aria-label={writeReview.ariaLabelC}>{labels.userInformation.buttons.cancel}</button>
                    <button type="button" className="primary-button" onClick={onPreviewClick} aria-label={writeReview.ariaLabelP}>{labels.userInformation.buttons.preview}</button>
                    <div className="links">
                        <ExternalLink data={{ title: labels.termsConditionsText, url: labels.termsConditionsLink }} />
                    </div>
                    <p>{labels.footer.paragraph}</p>
                </div>
            </div>
        </div>
    );
};

WriteReviewFooter.propTypes = {
    onCancelClick: PropTypes.func,
    onPreviewClick: PropTypes.func,
    gtin: PropTypes.string,
};

export default WriteReviewFooter;
