
import { useState, useEffect } from 'react';
import mapValues from 'lodash/mapValues';

import BazzarVoiceApi from '../../adapters/bazaarVoice.adapter';
import { getProductIds } from '../../helpers/products';

const useProductRatings = (products, ratingLimit) => {
    const [allRatings, setAllRatings] = useState({});
    useEffect(() => {
        const productIds = getProductIds(products);
        BazzarVoiceApi.getRating(productIds, ratingLimit).then(res => {
            if (res && res.Results.length > 0) {
                const ratings = mapValues(res.Includes.Products, (product) =>
                    product.ReviewStatistics.AverageOverallRating);
                setAllRatings(ratings);
            }
        });
    }, []);

    return allRatings;
};

export default useProductRatings;
