import React from 'react';
import PropTypes from 'prop-types';


const GlossaryTable = (props) => {
    const { tableHeadings, tableRows } = props;

    const renderHeadingsOrRows = (items, headingsOrRows) => {
        return (
            items.map((item, i) => (
                <tr key={i}>
                    {headingsOrRows ? <th>{item.ingredientName}</th> : <td>{item.ingredientName}</td>}
                    {headingsOrRows ? <th>{item.purpose}</th> : <td>{item.purpose}</td>}
                    {headingsOrRows ? <th>{item.description}</th> : <td>{item.description}</td>}
                </tr>
            ))
        );
    };

    return (
        <div>
            <table>
                <thead>
                    {renderHeadingsOrRows(tableHeadings.items, true)}
                </thead>
                <tbody>
                    {renderHeadingsOrRows(tableRows.items, false)}
                </tbody>
            </table>
        </div>
    );
};

GlossaryTable.propTypes = {
    tableHeadings: PropTypes.object,
    tableRows: PropTypes.object,
};

export default GlossaryTable;
