module.exports = `
... on LinkBlockContainer {
    backgroundImage {
      cfmedia {
        description
        url
        width
        height
      }
    }
    contentCollection {
      items {
        title
        backgroundImage {
          cfmedia {
            description
            url
            width
            height
          }
        }
        page {
          name
          label
          page {
            nameEn
            title
            description
            url 
          }
        }
      }
    }
    ariaLabel
  }
`;
