import React, { useEffect, useState, useContext } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import RatingStars from '../UI/RatingStars/RatingStars';
import ReviewsFilter from './ReviewsFilter/ReviewsFilter';
import ReviewCard from './ReviewCard/ReviewCard';
import { useLocalStorage } from '../Hooks/useLocalStorage';
import SubmitHelpfulness from '../../adapters/bazaarVoice.adapter';
import useRatingAndReviews from '../Hooks/useRatingAndReviews';
import { reviews as reviewsLabels } from '../../constants/label.constants';
import AppContext from '../../context/App.context';
import { generateCallbackUrl } from '../../helpers/bazaarVoice';
import WriteReview from '../WriteReview/WriteReview';
import bvTrackPageView from './trackPageView.helper';

const Reviews = () => {
    const { appData, appState, ref } = useContext(AppContext);

    const [blackBoxString, setBlackBoxString] = useState('');
    const [callbackUrl, setCallbackUrl] = useState('');

    const { writeAndReview } = appState;
    const { isWriteReviewFormOpen, setIsWriteReviewFormOpen } = writeAndReview;

    const ratingReviewsRef = ref;

    if (!appData.reviewsData) return null;

    const { bvData, name, gtin } = appData.reviewsData;
    const productId = bvData && bvData.productId;
    if (!productId) return null;

    const title = name.indexOf('|') !== -1 ? name.substring(0, name.indexOf('|')) : name;

    const [selectedOpt, setSelectedOpt] = useState('Most recommended');

    const [offset, setOffset] = useState(0);
    const [params, setParam] = useState('');

    const sortingParam = (opt) => {
        switch (opt) {
            case 'Most recommended':
                return 'TotalPositiveFeedbackCount:desc';
            case 'Most recent':
                return 'SubmissionTime:desc';
            case 'Highest rating':
                return 'Rating:desc';
            case 'Lowest rating':
                return 'Rating:asc';
            default:
                return null;
        }
    };

    useEffect(() => {
        if (process.browser) {
            if (window.IGLOO && blackBoxString === '') {
                window.IGLOO.bb_callback = (string, isComplete) => {
                    if (isComplete) {
                        setBlackBoxString(string);
                    }
                };
            }

            if (callbackUrl === '') {
                setCallbackUrl(generateCallbackUrl(process.env.DOMAIN_NAME, process.env.LOCALE));
            }
        }
    }, [blackBoxString]);

    const changeOpt = (newOpt) => {
        setSelectedOpt(newOpt);
        setOffset(0);
    };

    const [reviews, setReviews] = useState([]);
    const { allReviews, totalReviewCount, averageOverallRating } = useRatingAndReviews(productId, offset, params);
    const [votedReview, setVotedReview] = useLocalStorage('vote', []);

    useEffect(() => {
        setReviews(allReviews || []);
        setParam(sortingParam(selectedOpt));
    }, [allReviews, selectedOpt]);

    const reviewCards = reviews.map((review, index) => {
        const { Id, ProductId } = review;

        const feedbackSubmit = (e) => {
            e.preventDefault();
            const userVote = e.target.name;
            SubmitHelpfulness.submitHelpfulness(userVote, Id).then((response) => {
                if (!response.HasErrors) setVotedReview(Id);
            });
        };

        return (
            <div className="review-items" key={index}>
                <ReviewCard
                    productId={ProductId}
                    review={review}
                    votedReview={votedReview}
                    id={Id}
                    feedbackSubmit={feedbackSubmit}
                />
            </div>
        );
    });

    useEffect(() => {
        if (process.browser && window.BV && window.BV.pixel) {
            bvTrackPageView(productId, totalReviewCount, averageOverallRating);
        }
    }, [totalReviewCount, productId]);

    useEffect(() => {
        setInterval(() => {
            const richTextLinks2 = document.querySelectorAll(
                '.bv_main_container_row_flex.bv_ratings_summary',
            );
            for (let i = 0; i < richTextLinks2.length; i++) {
                richTextLinks2[i].setAttribute(
                    'class',
                    'bv_main_container_row_flex bv_ratings_summary event_product_review_view',
                );
                richTextLinks2[i].setAttribute('data-action-detail', bvData.productId);
            }
        }, 1500);
    });

    const renderReviews = () => {
        return (
            <div
                id="reviews"
                className="rating-reviews-container"
                ref={ratingReviewsRef}
                aria-label={reviewsLabels.ariaLabel}
            >
                <Head>
                    <Script strategy="afterInteractive" src="/scripts/igloo-config.js" />
                    <Script strategy="afterInteractive" src="/scripts/iovation.js" />
                </Head>
                <div className="rating-reviews-heading">
                    <h2>
                        {reviewsLabels.rnrTitle} - {title}
                    </h2>
                    <div className="rating-reviews-stars">
                        <p className="overall-rating">
                            {reviews.length !== 0
                                ? `Overall rating from ${totalReviewCount} reviews`
                                : reviewsLabels.noReviewsText}
                        </p>
                        <RatingStars
                            showRatingsLabel={true}
                            averageRating={averageOverallRating}
                        />
                    </div>
                    <div className="rating-reviews-sort">
                        {reviews.length > 0 && <ReviewsFilter handleFilter={changeOpt} />}
                        <button
                            className="rating-reviews-btn event_product_review"
                            data-action-detail={gtin}
                            type="button"
                            aria-label={reviewsLabels.ariaLabel}
                            onClick={() => setIsWriteReviewFormOpen(true)}
                        >
                            Write a review
                        </button>
                    </div>
                </div>
                {reviewCards}
                <div className="reviews-load-more-btn">
                    {reviews.length > 0 && reviews.length !== totalReviewCount && (
                        <button
                            type="button"
                            onClick={() =>
                                setOffset(
                                    offset + parseInt(process.env.BAZAARVOICE_REVIEWS_LIMIT, 10),
                                )
                            }
                        >
                            Load More
                        </button>
                    )}
                </div>
            </div>
        );
    };

    const renderWriteReview = () => {
        return (
            <WriteReview
                gtin={gtin}
                product={appData.reviewsData}
                onCancel={() => setIsWriteReviewFormOpen(false)}
                blackBoxString={blackBoxString}
                callbackUrl={callbackUrl}
            />
        );
    };

    return (
        <div
            id={`${totalReviewCount ? 'bvCGC' : null}`}
            className="review-container"
            ref={ratingReviewsRef}
            tabIndex={0}
        >
            {isWriteReviewFormOpen ? renderWriteReview() : renderReviews()}
        </div>
    );
};

export default Reviews;
