import get from 'lodash/get';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

export const excludeHiddenFromNavigation = (navigation) => navigation.filter(item => {
    const hideInNavigation = get(item, 'page.hideInNavigation') || get(item, 'hideInNavigation');
    return !hideInNavigation;
});

export const buildInternalLink = (internalLinkData) => {
    const { label, page, openInNewWindow } = internalLinkData;

    return {
        type: 'link',
        label,
        page,
        title: label,
        url: page.url,
        target: openInNewWindow ? '_blank' : '_self',
    };
};

export const buildNavigationColumn = (navigationColumnData) => {
    const { name, nameEn, exploreAllLink, image, links, heading, __typename } = navigationColumnData;
    const src = getCdnImage(image && image.cfmedia.url);

    if (__typename === 'NavigationDropdownImage') {
        return {
            type: 'column-image',
            src,
        };
    }

    const filteredLinks = excludeHiddenFromNavigation(links.items);

    return {
        type: 'column-links',
        name,
        nameEn,
        heading,
        __typename,
        links: filteredLinks.map(buildInternalLink),
        exploreAllLink,
    };
};

export const buildNavigationDropdown = (navigationDropdownData) => {
    const { columns, label, leftImage, rightImage, categoryLandingPage } = navigationDropdownData;

    return {
        type: 'dropdown',
        label,
        leftImage,
        rightImage,
        columns: columns.items.map(buildNavigationColumn),
        categoryLandingPage,
    };
};

export const buildNavigation = (navigationData) => {
    const navigation = get(navigationData, 'items[0].links.items', []);

    const filteredNavigation = excludeHiddenFromNavigation(navigation);

    return filteredNavigation.map(item => {
        if (item.__typename === 'LinkInternal') {
            return buildInternalLink(item);
        }
        if (item.__typename === 'NavigationDropdown') {
            return buildNavigationDropdown(item);
        }
        return null;
    });
};
