import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import useScreenSize from '../Hooks/useScreenSize';
import LinkBlockContainer from '../LinkBlockContainer/LinkBlockContainer';
import { Img } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const ErrorPage = (props) => {
    const { errorTitle, leftBackgroundImage, rightBackgroundImage, image, notFoundText, errorMessage, linkBlock, ariaLabel } = props;
    const resolution = useScreenSize();

    const leftImagePath = getCdnImage(leftBackgroundImage && leftBackgroundImage.cfmedia.url);
    const rightImagePath = getCdnImage(rightBackgroundImage && rightBackgroundImage.cfmedia.url);

    const background = (resolution && resolution.width > 991) ? {
        backgroundImage: `url(${leftImagePath}), url(${rightImagePath})`,
    } : { backgroundImage: 'none' };

    return (
        <div className="error-page" style={background} aria-label={ariaLabel}>
            <h1>{errorTitle && errorTitle}</h1>
            <Img contentfulImage={image && image.cfmedia} />
            <h2>{notFoundText && notFoundText}</h2>
            <p>
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                >
                    {errorMessage && errorMessage}
                </ReactMarkdown>
            </p>
            <LinkBlockContainer {...linkBlock} />
        </div>
    );
};

ErrorPage.propTypes = {
    errorTitle: PropTypes.string,
    leftBackgroundImage: PropTypes.object,
    rightBackgroundImage: PropTypes.object,
    image: PropTypes.object,
    notFoundText: PropTypes.string,
    errorMessage: PropTypes.string,
    linkBlock: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ErrorPage;
