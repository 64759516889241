module.exports = {
    reviewsCard: {
        ariaLable: 'review card',
    },
    reviewsVoteIcons: {
        voteUp: 'Vote Up',
        voteDown: 'Vote Down',
    },
    zeroPercentImg: {
        alt: 'zero percent image',
    },
    mainLogo: {
        alt: 'Herbal Essences logo',
    },
    buyNowLabelText: 'Find Retailers',
    smartlabelLogo: {
        alt: 'smartlabel',
    },
    syndicationSource: {
        alt: 'Syndication Source Logo Image',
    },
    bbbLogo: {
        alt: 'BBB Homepage',
        src: '/images/BBB.png',
        url: 'https://www.bbb.org/us/oh/cincinnati/profile/detergent/the-procter-gamble-company-0292-3036',
    },
    countrySelector: {
        label: 'Select Country / Region',
        mobileClose: 'Close language and region selector',
    },
    shareButton: {
        facebook: {
            id: 'facebook-description',
            key: 'facebook',
            ariaLabel: 'Facebook',
            ariaDescribedby: 'facebook-description',
        },
        twitter: {
            id: 'twitter-description',
            key: 'twitter',
            ariaLabel: 'Twitter',
            ariaDescribedby: 'twitter-description',
        },
        linkedIn: {
            id: 'linkedin-description',
            key: 'linkedin',
            ariaLabel: 'LinkedIn',
            ariaDescribedby: 'linkedin-description',
        },
        email: {
            key: 'email',
        },
        description: 'Opens in a new window',
        ariaLabel: 'Share button',
    },
    reviews: {
        rnrTitle: 'Ratings & reviews',
        noReviewsText: 'No reviews currently',
        sortBy: 'Sort By Filter',
        ariaLabel: 'Write a review',
    },
    skipToContent: 'Skip to main content (press Enter)',
    skipToOurProducts: 'Skip to Our Products (press Enter)',
    skipToIngredients: 'Skip to Our Ingredients (press Enter)',
    skipToOurPhilosophy: 'Skip to Our Philosophy (press Enter)',
    skipToOurKewPartnership: 'Skip to Our Kew Partnership (press Enter)',
    skipToBlog: 'Skip to Our Articles (press Enter)',
    skipToHairQuiz: 'Salte a Cuestionario sobre el pello (presione Entrar)',
    navigation: {
        openNavigation: 'Open navigation',
        closeNavigation: 'Close navigation',
        closeCategory: 'Close category selector',
    },
    modal: {
        close: 'Close modal',
    },
    video: {
        play: 'Play video',
    },
    writeReview: {
        retingStarLabel: 'Rating star',
        ariaLabelC: 'Cancel',
        ariaLabelP: 'Preview',
    },
    landingPageLabel: 'ALL OUR PRODUCTS',
    searchBtn: {
        ariaLabel: 'Search button',
    },
    liveChat: {
        title: 'live chat',
        popupHeadingOne: 'Do you need any help?',
        popupHeadingTwo: 'Let’s chat about it.',
        chatNowButton: 'Chat Now',
        cancelButton: 'No, Thanks',
    },
    searchResults: {
        learnMore: 'Learn more',
    },
    contentType: {
        productListing: 'ProductListing',
        productHeader: 'ProductHeader',
    },
    cookie: {
        consent: 'Cookie Consent',
    },
    detailsPage: {
        articleDetail: 'Article Details Page',
        productDetail: 'Product Details Page',
    },
    // Privacy Links
    en: 'Privacy',
    ENLang: 'en',
    CountCodeUS: 'SA',
    privacyLinkCode: 'privacyLink',
};
