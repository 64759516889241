import React, { useState } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import { InternalLink } from '../UI';
import Navigation from '../Navigation/Desktop/Navigation';
import MobileNavigation from '../Navigation/Mobile/Navigation';
import Subheader from './Subheader/Subheader';
import { mainLogo } from '../../constants/label.constants';
import disableBodyScroll from '../../helpers/disalbeBodyScroll';
import useScreenSize from '../Hooks/useScreenSize';
import { buildNavigation } from './Header.helper';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Header = ({ navigation, countries, breadcrumbs, pageInfo }) => {
    const resolution = useScreenSize();
    const [isMobileNavOpen, setMobileNavState] = useState(false);
    const [isMobileSearchActive, setMobileSearch] = useState(false);
    const [isCSOpen, setIsCSOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(null);
    const [activeNavMenu, setActiveNavMenu] = useState(null);
    const toggleCSelector = () => setIsCSOpen(!isCSOpen);
    const openCSelectorOnHover = () => resolution.isDesktop && setIsCSOpen(true);
    const closeCSelectorOnHover = () => resolution.isDesktop && setIsCSOpen(false);
    const mobileLinks = get(navigation, 'items[0].mobileLinksCollection.items', []);
    const navigationData = buildNavigation(navigation);
    const conditionalWidthAndHeight = resolution?.isHandheld ? 100 : 141;

    const router = useRouter();
    const currentPath = router.asPath;

    const { currentLocale } = countries.items[0];

    if (!resolution) return null;

    const togglePopup = key => {
        setOpenPopup(key);
        disableBodyScroll((key !== null));
    };

    const showBreadcrumbs = `/${currentLocale}/` !== pageInfo.url;

    return (
        <>
            <div className="header" role="banner">
                <InternalLink
                    link={{ url: `/${currentLocale}/`, title: 'Home' }}
                    className={!isMobileNavOpen && !isMobileSearchActive ? 'logo-link' : 'is-open'}
                    onFocus={() => setActiveNavMenu(null)}
                    eventClass="event_image_click"
                    dataActionDetailPrefix="Homepage Logo"
                >
                    <Image
                        src="/images/HE_Biorenew_logo.png"
                        alt={mainLogo.alt}
                        className="logo"
                        width={conditionalWidthAndHeight}
                        height={conditionalWidthAndHeight}
                    />
                </InternalLink>
                <Subheader
                    countries={countries}
                    openCSelector={openCSelectorOnHover}
                    closeCSelector={closeCSelectorOnHover}
                    toggleCSelector={toggleCSelector}
                    isCSelectorOpen={isCSOpen}
                    currentLocale={currentLocale}
                    currentPath={currentPath}
                />
                <Navigation
                    openPopup={openPopup}
                    navigationData={navigationData}
                    currentPath={currentPath}
                    setMobileNavState={setMobileNavState}
                    isMobileNavOpen={isMobileNavOpen}
                    activeNavMenu={activeNavMenu}
                    setActiveNavMenu={setActiveNavMenu}
                    currentLocale={currentLocale}
                    setMobileSearch={setMobileSearch}
                />
                <Breadcrumbs breadcrumbs={breadcrumbs} isVisible={showBreadcrumbs} isMobileNavOpen={isMobileNavOpen} />
            </div>
            {resolution.accessibilityZoom
                && (
                    <MobileNavigation
                        toggleCSelector={toggleCSelector}
                        openPopup={openPopup}
                        setOpenPopup={togglePopup}
                        navigationData={navigationData}
                        isMobileNavOpen={isMobileNavOpen}
                        isCSelectorOpen={isCSOpen}
                        mobileLinks={mobileLinks}
                        setMobileNavState={setMobileNavState}
                    />
                )}
        </>
    );
};

Header.propTypes = {
    breadcrumbs: PropTypes.any,
    countries: PropTypes.object,
    navigation: PropTypes.any,
    pageInfo: PropTypes.object,
};

export default Header;
