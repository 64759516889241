import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Image from 'next/image';
import Head from 'next/head';
import rehypeRaw from 'rehype-raw';
import useScreenSize from '../Hooks/useScreenSize';
import AppContext from '../../context/App.context';
import { Img } from '../UI';

const ProductListingHeader = (props) => {
    const { mainDescription, additionalDescription, ariaLabel } = props;
    const { topImage, leftImage, rightImage } = props;
    const resolution = useScreenSize();
    const { appState } = useContext(AppContext);
    const { prodListing } = appState;
    const { setIsProdListRendered } = prodListing;

    useEffect(() => {
        setIsProdListRendered(true);

        if (typeof window !== 'undefined') {
            const richTextLinks3 = document.querySelectorAll('.additional-description.paralink p a');
            for (let i = 0; i < richTextLinks3.length; i++) {
                richTextLinks3[i].setAttribute('class', 'event_internal_link');
                richTextLinks3[i].setAttribute('data-action-detail', richTextLinks3[i].getAttribute('href'));
            }
        }
    }, []);

    return (
        <>
            <Head>
                <link rel="preload" as="image" href={`${topImage.cfmedia.url}?w=320&h=70&fm=webp`} crossOrigin="true" />
            </Head>
            <div className="product-listing-header" aria-label={ariaLabel}>
                <Image
                    className="top-image"
                    src={topImage.cfmedia.url}
                    alt={topImage.cfmedia.description}
                    width={resolution && resolution.isMobile ? '320' : '100'}
                    height={resolution && resolution.isMobile ? '70' : '100'}
                />
                {resolution && resolution.isDesktop && <Img className="left-image" contentfulImage={leftImage.cfmedia} />}
                {resolution && resolution.isDesktop && <Img className="right-image" contentfulImage={rightImage.cfmedia} />}
                <div className="content">
                    <div className="description">
                        <ReactMarkdown className="main-description" rehypePlugins={[rehypeRaw]}>
                            {mainDescription}
                        </ReactMarkdown>
                    </div>
                    <ReactMarkdown className="additional-description paralink" rehypePlugins={[rehypeRaw]}>
                        {additionalDescription}
                    </ReactMarkdown>
                </div>
            </div>
        </>

    );
};

ProductListingHeader.propTypes = {
    additionalDescription: PropTypes.string,
    leftImage: PropTypes.object,
    mainDescription: PropTypes.string,
    rightImage: PropTypes.object,
    topImage: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ProductListingHeader;
