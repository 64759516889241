module.exports = `
... on NixedIngredients {
    title
    titleAlignment
    backgroundImage {
        cfmedia {
            description
            url
            width
            height
        }
    }
    nixedIngredientsLeftColumn
    nixedIngredientsRightColumn
    iconsCollection(limit: 24) {
        items {
            title
            image {
                name
                cfmedia {
                    description
                    url
                    width
                    height
                }
            }
            nixedInternalLink {
                page {
                    url
                }
            }
        }
    }
    linkedPage {
        label
        page {
            url
        }
    }
    ariaLabel
}
`;
