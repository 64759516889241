import React from 'react';

const keyBase = 200;

const prefetch = [
    'https://script.crazyegg.com/',
    'https://www.google-analytics.com/',
    'https://stg.api.bazaarvoice.com/',
    'https://d.agkn.com/',
    'https://static.agkn.com/',
    'https://8630754.fls.doubleclick.net/',
    'https://insight.adsrvr.org/',
    'https://aa.agkn.com/',
    'https://www.facebook.com/',
    'https://adservice.google.com/',
    'https://js.adsrvr.org/',
    'https://www.google-analytics.com/',
    'https://connect.facebook.net/',
    'https://z.moatads.com/',
    'https://cdn.pricespider.com/',
];

const preconnect = [
    'https://www.googletagmanager.com/',
    'https://cdn.segment.com/',
    'https://c.lytics.io/',
    'https://api.lytics.io/',
    'https://cdn.cookielaw.org/',
    'https://js.agkn.com/',
];

export default () => {
    const fetch = prefetch.map((link, i) => <link rel="dns-prefetch" href={link} key={keyBase + i} />);
    const connect = preconnect.map((link, i) => <link rel="preconnect" href={link} key={keyBase + i + prefetch.length} />);

    return [...fetch, ...connect];
};
