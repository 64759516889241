import get from 'lodash/get';

const urlPath = 'cloudinaryExtension[0].secure_url';
const w = 'cloudinaryExtension[0].width';
const h = 'cloudinaryExtension[0].height';


const imageData = (image, additionalParams) => {
    let url = get(image, urlPath, '');
    if (process.browser) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
        const isIE = window.navigator.msPointerEnabled || false;
        if (!isIE && !isSafari) url = url.replace(/(\.jpg|\.jpeg|\.png)/, '.webp');
    }

    if (additionalParams && typeof additionalParams === 'string') url = url.replace('/upload/', `/upload/${additionalParams}/`);

    return {
        url,
        alt: (image && image.alt) || '',
        width: get(image, w, 0),
        height: get(image, h, 0),
    };
};

export default imageData;
