export const getItemsMatchingFilters = (collectionItemFilters, filterType, targetFilters) => {
    if (!collectionItemFilters || !collectionItemFilters.items) {
        console.error('Collection item does not contain filters property');
        return [];
    }
    return collectionItemFilters.items.filter(itemFilter =>
        itemFilter && itemFilter.filterName === filterType && targetFilters[filterType].find(i => i.label === itemFilter.label));
};

export const areAllFiltersMatching = (targetFilters, collectionItem, matchingStrategy) => {
    const strategy = matchingStrategy || {
        matchAllFilterTypes: true,
        matchAllFilters: true,
    };

    const filterTypes = Object.keys(targetFilters);

    const matchedFilterTypes = filterTypes.filter(filterType => {
        const itemsMatchingFilters = getItemsMatchingFilters(collectionItem.filters, filterType, targetFilters);
        const allFiltersMatched = itemsMatchingFilters.length === targetFilters[filterType].length;

        return strategy.matchAllFilters ? allFiltersMatched : itemsMatchingFilters.length > 0;
    });

    const allFilterTypesMatched = matchedFilterTypes.length === filterTypes.length;
    return strategy.matchAllFilterTypes ? allFilterTypesMatched : matchedFilterTypes.length > 0;
};

export const areFiltersEmpty = (filters) => {
    if (!filters) {
        return true;
    }

    return Object.keys(filters).filter(filter => filters[filter].length > 0).length === 0;
};

export const areFiltersValid = (filters) => {
    if (!filters) {
        return false;
    }

    const keys = Object.keys(filters);

    const areAllFilterTypesArray = keys.filter(key => Array.isArray(filters[key])).length === keys.length;

    if (!areAllFilterTypesArray) {
        return false;
    }

    // Must have "filterName" and "label" properties
    const hasValidProperties = keys.filter(key => filters[key].filter(k =>
        Boolean(k.label) && Boolean(k.filterName)).length === filters[key].length).length === keys.length;

    if (!hasValidProperties) {
        return false;
    }

    const areKeysAndLabelsEqual = keys.filter(key => filters[key].filter(k =>
        k.filterName === key).length === filters[key].length).length === keys.length;

    if (!areKeysAndLabelsEqual) {
        return false;
    }

    return true;
};

export const getFilteredCollection = (collection, filters, matchingStrategy) => {
    if (!areFiltersValid(filters)) {
        console.error('Filters object is not valid');
        return [];
    }

    if (!collection || !Array.isArray(collection)) {
        console.error('Collection for filtering is is not valid');
        return [];
    }

    if (areFiltersEmpty(filters)) {
        return collection;
    }

    return collection.filter(collectionItem => areAllFiltersMatching(filters, collectionItem, matchingStrategy));
};

export const updateExistingFilter = (filterToUpdate, selectedFilter) => {
    if (!filterToUpdate || !selectedFilter) {
        return null;
    }

    let existingFilterType = filterToUpdate;

    // Find specific filter within existing filter type
    const filterExists = Boolean(existingFilterType.find(filter => filter.label === selectedFilter.label));

    if (filterExists) {
        existingFilterType = existingFilterType.filter(filter => filter.label !== selectedFilter.label);
    } else {
        existingFilterType.push(selectedFilter);
    }

    return { [selectedFilter.filterName]: existingFilterType };
};

export const updateFilters = (selectedFilter, filters) => {
    const existingFilterType = filters[selectedFilter.filterName];

    const updatedFilterType = existingFilterType
        ? updateExistingFilter(existingFilterType, selectedFilter)
        : { [selectedFilter.filterName]: [selectedFilter] };

    return {
        ...filters,
        ...updatedFilterType,
    };
};

export const removeFilter = (filterToRemove, existingFilters) => {
    if (!filterToRemove || !existingFilters) {
        return null;
    }

    const filterType = filterToRemove.filterName;

    return {
        ...existingFilters,
        [filterType]: existingFilters[filterType].filter(filter => filter.label !== filterToRemove.label),
    };
};

export const buildFiltersWithOptions = (filters) => {
    const keys = filters.map(f => f.filterName);
    const uniqueFiltersNames = keys.filter((item, index) => keys.indexOf(item) === index);

    const allFilters = uniqueFiltersNames.map(uniqueFilterName => {
        return {
            filterName: uniqueFilterName,
            options: filters.filter(filter => filter.filterName === uniqueFilterName),
        };
    });

    return allFilters;
};

export const isFilterActive = (filter, activeFilters) => {
    if (!activeFilters[filter.filterName]) {
        return false;
    }

    return Boolean(activeFilters[filter.filterName].find(f => f.filterName === filter.filterName && f.label === filter.label));
};

export const buildFilters = (rawFilters) => {
    const filters = {};

    rawFilters.forEach(filter => {
        filters[filter.filterName] = [
            filters[filter.filterName],
            filter,
        ].filter(item => Boolean(item));
    });

    return filters;
};
