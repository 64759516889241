import bvPixel from '../../constants/bvPixel.constants';

const { bvProduct, brand, type } = bvPixel;

const bvTrackPageView = (productId, numReviews = 0, avgRating = 0) => {
    // BV lib. is loaded via Layout in the head of the document, and it can be used this way even
    // if it is not defined in this specific function
    // eslint-disable-next-line no-undef
    BV.pixel.trackPageView({
        bvProduct,
        productId,
        brand,
        type,
        numReviews,
        avgRating,
    });
};

export default bvTrackPageView;
