import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { searchBtn } from '../../../constants/label.constants';

const SearchMobile = ({ redirectUrl, setMobileSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [fieldDirty, setFieldDirty] = useState(false);

    const onChange = (e) => {
        setFieldDirty(true);
        const content = e.target.value.toString().replace(/%/g, '');
        setSearchTerm(content);
    };

    const onSearch = () => {
        setMobileSearch(true);
        if (typeof window !== 'undefined' && searchTerm) {
            window.location = `${redirectUrl}?q=${searchTerm}`;
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const inputClassName = fieldDirty ? 'dirty' : '';

    return (
        <div className="search-input" onClick={() => setMobileSearch(true)} role="search">
            <input
                role="searchbox"
                id="search-field-dt"
                className={`js-search-field ${inputClassName}`}
                value={searchTerm}
                onChange={onChange}
                onBlur={onChange}
                onKeyDown={onKeyDown}
                onFocus={() => setMobileSearch(true)}
                placeholder="Enter your search term..."
            />
            <label htmlFor="search-field-dt">Search text field</label>
            <button
                type="button"
                className="js-search-button"
                onClick={onSearch}
                aria-label={searchBtn.ariaLabel}
            />
        </div>
    );
};

SearchMobile.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    setMobileSearch: PropTypes.func,
};

export default SearchMobile;
