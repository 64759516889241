import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { InternalLink } from '../UI';
import { getSeoSchemaBreadcrumbs } from '../Scripts';

require('dotenv').config();

const Breadcrumbs = ({ breadcrumbs, isVisible, isMobileNavOpen }) => {
    if (!isVisible) return null;
    const { LOCALE } = process.env;
    const { DOMAIN_NAME } = process.env;
    const seoSchemaBreadcrumbs = breadcrumbs && getSeoSchemaBreadcrumbs(breadcrumbs, LOCALE, DOMAIN_NAME);

    return (
        <>
            <Head>
                {seoSchemaBreadcrumbs}
            </Head>
            <div className={`breadcrumbs ${isMobileNavOpen ? 'breadcrumbs-hidden' : ''}`}>
                <ul>
                    <li><InternalLink link={{ url: `/${LOCALE}/`, label: 'Home' }} /></li>
                    {breadcrumbs.map((part, i) => (
                        <li key={i}>
                            <InternalLink
                                link={{ url: `/${LOCALE}${part.url}`, label: part.label }}
                                isAriaCurrent={i === breadcrumbs.length - 1}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
    isVisible: PropTypes.bool,
    isMobileNavOpen: PropTypes.bool,
};

export default Breadcrumbs;
