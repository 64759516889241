const allPages = require('./allPages.query');
const singlePage = require('./singlePage.query');
const headerNavigation = require('./headerNavigation.query');
const footer = require('./footer.query');
const pageContentTypes = require('./pageContentTypes.query');
const pgDataLayer = require('./pgDataLayer.query');
const productListingFilters = require('./productListingFilters.query');
const productHeaders = require('./productHeaders.query');
const productCollections = require('./productCollections.query');
const productDetailPages = require('./productDetailPage.query');
const stickyBanner = require('./stickyBanner.query');

module.exports = {
    allPages,
    singlePage,
    headerNavigation,
    footer,
    pageContentTypes,
    pgDataLayer,
    productListingFilters,
    productHeaders,
    productCollections,
    productDetailPages,
    stickyBanner,
};
