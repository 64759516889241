import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Img, InternalLink } from '../UI';

const ProductLineBanners = ({ title, productLineCollection, ariaLabel }) => {
    const ProductLine = productLineCollection.items.map((item, i) => {
        const { title: text, image, internalLink } = item;

        return (
            <div className="product-line" key={i}>
                <InternalLink link={internalLink}>
                    <Img contentfulImage={image.cfmedia} />
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {text}
                    </ReactMarkdown>
                </InternalLink>
            </div>
        );
    });

    return (
        <div className="product-line-section" aria-label={ariaLabel}>
            <ReactMarkdown className="product-line-title" source={title} />
            <div className="product-line-container">
                {ProductLine}
            </div>
        </div>
    );
};

ProductLineBanners.propTypes = {
    title: PropTypes.string,
    productLineCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ProductLineBanners;
