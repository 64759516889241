module.exports = `
... on CollectionBanner {
  title
  page{
    url
  }
  image{
    name
    cfmedia {
      description
      url
      width
      height
    }
  }
  ariaLabel
}
`;
