import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import rehypeRaw from 'rehype-raw';
import useImageFormat from '../Hooks/useImageFormat';

function TextTable({ title, leftImage, rightImage, contentCollection, ariaLabel, styles }) {
    const imgL = useImageFormat(leftImage).url;
    const imgR = useImageFormat(rightImage).url;
    const texts = get(contentCollection, 'items', []);
    const backgroundImage = `url(${imgL}), url(${imgR})`;

    const renderText = () => (
        texts.map((text, index) => {
            const { nameEn, content } = text;
            return (
                <div className="text-block" key={index}>
                    <div className="text-title">
                        <h4>{nameEn}</h4>
                    </div>
                    <div className="text-content">
                        <p>{content}</p>
                    </div>
                </div>
            );
        })
    );

    return (
        <div className={`${styles} text-table-container`} style={{ backgroundImage }} suppressHydrationWarning={true} aria-label={ariaLabel}>
            <ReactMarkdown className="text-table-container-title" rehypePlugins={[rehypeRaw]}>
                {title}
            </ReactMarkdown>
            <div className="text-table">
                {renderText()}
            </div>
        </div>
    );
}

TextTable.propTypes = {
    title: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    contentCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
    styles: PropTypes.string,
};

export default TextTable;
