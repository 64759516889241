import { useState, useEffect } from 'react';
import imageData from '../../helpers/imageData';

const useImageFormat = (cfImage, additionalParams) => {
    const [image, setImage] = useState();

    useEffect(() => {
        if (process.browser) {
            const imgData = imageData(cfImage, additionalParams);
            const w = window.innerWidth;

            if (imgData.width > w && !additionalParams) {
                imgData.url = imgData.url.replace('/upload/', `/upload/w_${w}/`);
            }
            setImage(imgData);
        }
    }, []);

    return image || { url: '', alt: '' };
};

export default useImageFormat;
