import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Slider from 'react-slick';
import useScreenSize from '../Hooks/useScreenSize';

import Arrow from '../UI/CarouselArrow/CarouselArrow';
import { InternalLink, AriaLiveRegion, Img } from '../UI';

const HeaderCarousel = ({ slides, ariaLabel }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const resolution = useScreenSize();

    const isHandheld = resolution?.isHandheld;
    const isMobile = resolution?.isMobile;

    const slidesLength = slides.items.length;
    const counter = `${currentSlide + 1}/${slidesLength}`;

    const sliderRef = useRef();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        beforeChange: (currSlide, nextSlide) => {
            setTimeout(() => setCurrentSlide(nextSlide), 100);
        },
    };

    const onPrevClick = () => {
        sliderRef.current.slickPrev();
        if (process.browser && isMobile) window.scrollTo(0, 0);
    };

    const onNextClick = () => {
        sliderRef.current.slickNext();
        if (process.browser && isMobile) window.scrollTo(0, 0);
    };

    const renderSlides = () => (
        <>
            <h1 className="h1-home">
                Natural Hair Care Products
            </h1>
            <Slider {...settings} ref={sliderRef}>
                {slides.items.map((slide, i) => {
                    const { title, image, paragraph, link } = slide;
                    return (
                        <div className="slide-all" key={i}>
                            <div className="slide-image">
                                <Img contentfulImage={image.cfmedia} alt={image.cfmedia.description} loading="eager" />
                            </div>
                            <div className="slide-texts">
                                <ReactMarkdown className="text-block" rehypePlugins={[rehypeRaw]}>
                                    {title}
                                </ReactMarkdown>
                                <ReactMarkdown className="text-block" rehypePlugins={[rehypeRaw]}>
                                    {paragraph}
                                </ReactMarkdown>
                                {link && <InternalLink link={link} className={!title && !isHandheld ? 'no-title' : ''} />}
                                {slidesLength > 1
                                    && (
                                        <div className="paging" style={!title && !isHandheld ? { bottom: '-5vw', right: '10.4vw' } : null}>
                                            <Arrow className="slick-prev" onClick={onPrevClick} />
                                            <div className="numbers">
                                                {counter}
                                            </div>
                                            <Arrow className="slick-next" onClick={onNextClick} />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    );
                })}
            </Slider>
            <AriaLiveRegion current={currentSlide + 1} total={slidesLength} />
        </>
    );

    return (
        <div className="header-carousel" aria-label={ariaLabel}>
            {renderSlides()}
        </div>
    );
};

HeaderCarousel.propTypes = {
    slides: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default HeaderCarousel;
