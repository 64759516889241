import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';
import rehypeRaw from 'rehype-raw';
import { Header, Rating, Review, Information, WriteReviewFooter, ConfirmModal, NumberOfErrors } from './';
import fieldLabels from '../../constants/WriteReview.constants';
import { validations } from './WriteReview.config';
import { getErrors, getErrorsAmount, validateUserAge, setLocalStorageUnderage, getLocalStorageUnderage, getHairTypeId } from './WriteReview.helper';
import bazaarVoiceAdapter from '../../adapters/bazaarVoice.adapter';

const WriteReview = ({ product, onCancel, blackBoxString, callbackUrl, gtin }) => {
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState('');
    const [submitPreviewStatus, setSubmitPreviewStatus] = useState(null);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isUnderage, setIsUnderage] = useState(getLocalStorageUnderage());

    const removeEmojiFromInput = (val) => {
        const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        return val && val.replace(regex, '');
    };

    const onFieldChange = ({ field, value }) => {
        setFields({
            ...fields,
            [field]: typeof value === 'number'
        || typeof value === 'boolean'
                ? value
                : removeEmojiFromInput(value),
        });
    };

    const onFieldBlur = ({ field }) => {
        const fieldErrors = getErrors(fields, validations)[field];

        setErrors({
            ...errors,
            [field]: fieldErrors,
        });
    };

    const productId = product.bvData && product.bvData.productId;

    const onSubmit = () => {
        bazaarVoiceAdapter.submitReview(fields, productId, blackBoxString, callbackUrl)
            .then((response) => {
                if (response.data.Errors.length > 0) {
                    return setSubmitStatus('error');
                }

                return setSubmitStatus('success');
            })
            .catch(() => setSubmitStatus('error'));
    };

    const onPreviewClick = () => {
        fields.hairType = fields && fields.hairType && getHairTypeId(fields.hairType);
        bazaarVoiceAdapter.submitPreview(fields, productId, blackBoxString, callbackUrl)
            .then((response) => {
                const usernicknameError = get(response, 'data.FormErrors.FieldErrors.usernickname.Code', null);
                if (usernicknameError === 'ERROR_FORM_DUPLICATE') {
                    return setSubmitPreviewStatus('Nickname already used');
                }
                setSubmitPreviewStatus(null);
            });

        const errs = getErrors(fields, validations);
        const numberOfErrors = getErrorsAmount(errs);
        setErrors(errs);

        if (numberOfErrors === 0) {
            if (validateUserAge(fields['year'], fields['month'], 18)) {
                setIsPreviewModalOpen(true);
            } else {
                setIsUnderage(true);
                setLocalStorageUnderage(true);
            }
        }
    };

    const renderForm = () => (
        <div className="content">
            <Header
                title={fieldLabels.mainTitle}
                product={product}
            />
            <NumberOfErrors errors={errors} />
            <Rating
                fieldLabels={fieldLabels.productRating}
                onChange={onFieldChange}
                errors={errors}
            />
            <Review
                fieldLabels={fieldLabels.productReview}
                onChange={onFieldChange}
                onBlur={onFieldBlur}
                errors={errors}
            />
            <Information
                fieldLabels={fieldLabels.userInformation}
                onChange={onFieldChange}
                onBlur={onFieldBlur}
                errors={errors}
            />
            <WriteReviewFooter
                gtin={gtin}
                onPreviewClick={onPreviewClick}
                onCancelClick={onCancel}
            />
        </div>
    );

    const renderUnderage = () => (
        <div className="content underage">
            <p>
                <ReactMarkdown linkTarget="_blank" rehypePlugins={[rehypeRaw]}>
                    {fieldLabels.underageText}
                </ReactMarkdown>
            </p>
        </div>
    );

    return (
        <div className="write-review">
            {!isUnderage ? renderForm() : renderUnderage()}

            <ConfirmModal
                gtin={gtin}
                isOpen={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
                onSubmit={onSubmit}
                fields={fields}
                submitStatus={submitStatus}
                previewStatus={submitPreviewStatus}
            />
        </div>
    );
};

WriteReview.propTypes = {
    onCancel: PropTypes.func,
    product: PropTypes.object,
    blackBoxString: PropTypes.string,
    callbackUrl: PropTypes.string,
    gtin: PropTypes.string,
};

export default WriteReview;
