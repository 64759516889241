import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { CarouselArrow, Img } from '../../UI';

const Thumbnails = ({ images, onChange }) => {
    const settings = {
        afterChange: onChange,
        centerMode: true,
        focusOnSelect: true,
        infinite: true,
        verticalSwiping: true,
        swipeToSlide: true,
        nextArrow: <CarouselArrow />,
        prevArrow: <CarouselArrow />,
        slidesToScroll: 1,
        slidesToShow: 5,
        speed: 500,
        vertical: true,
    };

    return (
        <div className="thumbnails">
            <Slider {...settings}>
                {
                    images.map((image, index) => {
                        return (
                            <Img
                                contentfulImage={image.cfmedia}
                                key={index}
                                onClick={() => onChange(index)}
                            />
                        );
                    })
                }
            </Slider>
        </div>
    );
};

Thumbnails.propTypes = {
    images: PropTypes.array,
    onChange: PropTypes.func,
};

export default Thumbnails;
