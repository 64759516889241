import React from 'react';
import PropTypes from 'prop-types';

import { cutExtraTitle } from './SearchResults.helper';

import { InternalLink } from '../UI';

import { searchResults } from '../../constants/label.constants';

const ContentResults = ({ results, visible }) => {
    if (!visible) return false;

    const renderResult = (result, i) => {
        const { title, description, slug } = result;

        return (
            <div className="content-item" key={i}>
                <h2>{cutExtraTitle(title)}</h2>
                <p>{description}</p>
                <InternalLink className="link-btn" link={{ url: slug }}>{searchResults?.learnMore}</InternalLink>
            </div>
        );
    };

    return (
        <div id="search-content" className="search-content">
            {results.map(renderResult)}
        </div>
    );
};

ContentResults.propTypes = {
    results: PropTypes.array,
    visible: PropTypes.bool,
};

export default ContentResults;
