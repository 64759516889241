import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RatingStars, Checkbox } from '../../UI';
import ErrorMessage from '../ErrorMessage';
import useScreenSize from '../../Hooks/useScreenSize';

import { getFieldError, getLabelStyle } from '../WriteReview.helper';
import { fieldNames } from '../WriteReview.config';

const Rating = ({ fieldLabels, onChange, errors }) => {
    const { title, overallRating, recommend } = fieldLabels;

    const [recommendChoice, setRecommendChoice] = useState(null);
    const [averageRating, setAverageRating] = useState(null);

    const onRatingChange = (rating) => {
        setAverageRating(rating);
        onChange({
            field: fieldNames.OVERALL_RATING,
            value: rating,
        });
    };

    const onRecommendChange = (choice) => {
        setRecommendChoice(choice);
        onChange({
            field: fieldNames.RECOMMEND,
            value: choice,
        });
    };

    const recommendChoiceError = getFieldError(errors.recommend);
    const resolution = useScreenSize();
    const starDimension = resolution && resolution.isHandheld ? '50px' : '25px';

    return (
        <div className="rating">
            <h2>{title}</h2>
            <div>
                <div aria-required="true" className={`field-label ${getLabelStyle(fieldNames.OVERALL_RATING)}`}>
                    {overallRating.label}
                </div>
                <div>
                    <RatingStars averageRating={averageRating} onChange={onRatingChange} starDimension={starDimension} />
                    <ErrorMessage errors={errors.overallRating} errorMessages={overallRating.errorMessages} />
                </div>
            </div>
            <fieldset>
                <legend className={`field-label ${getLabelStyle(fieldNames.RECOMMEND)}`}>
                    {recommend.label}
                </legend>
                <Checkbox
                    label={recommend.optionYes}
                    checked={recommendChoice === 'yes'}
                    onChange={() => onRecommendChange('yes')}
                    hasError={recommendChoiceError}
                />
                <Checkbox
                    label={recommend.optionNo}
                    checked={recommendChoice === 'no'}
                    onChange={() => onRecommendChange('no')}
                    hasError={recommendChoiceError}
                />
                <ErrorMessage errors={errors.recommend} errorMessages={recommend.errorMessages} />
            </fieldset>
        </div>
    );
};

Rating.propTypes = {
    errors: PropTypes.object,
    fieldLabels: PropTypes.object,
    onChange: PropTypes.func,
};

export default Rating;
