module.exports = `
... on ProductCarousel {
  title
  leftImg: leftImage {
     cfmedia {
        description
        url
        width
        height
      }
  }
  rightImg: rightImage {
     cfmedia {
        description
        url
        width
        height
      }
  }
  slidesCollection(limit: 30) {
    items {
      name
      productBenefit
      bvData {
        productId
      }
      productvariants: productVariantsCollection(limit: 1){
        items{
          gtin
        }
      }
      seoHead {
        featuredImage {
           cfmedia {
              description
              url
              width
              height
            }
        }
        url
      }
    }
  }
  ariaLabel
}
`;
