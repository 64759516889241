import PropTypes from 'prop-types';
import React from 'react';

const Quiz = ({ iframeLink }) => (
    <div className="quiz">
        <iframe src={iframeLink && iframeLink} title="quiz" frameBorder="0" scrolling="no" width="100%" height="100%" />
    </div>
);


Quiz.propTypes = {
    iframeLink: PropTypes.string,
};

export default Quiz;
