import bvPixel from '../../../constants/bvPixel.constants';

const { bvProduct, contentType } = bvPixel;

const bvTrackImpression = (id, productId) => {
    // BV lib. is loaded via Layout in the head of the document, and it can be used this way even
    // if it is not defined in this specific function
    // eslint-disable-next-line no-undef
    BV.pixel.trackImpression({
        contentId: id,
        productId,
        bvProduct,
        contentType,
    });
};

export default bvTrackImpression;
