module.exports = `
... on Accordion {
    name
    items: itemsCollection(limit: 10) {
        items {
            title
            content: contentCollection(limit: 10) {
                items {
                    __typename
                    ... on ContentPlainTextMd {
                        content
                        columns
                    }
                }
            }
        }
    }
    ariaLabel
}`;
