import React from 'react';
import PropTypes from 'prop-types';
import fieldLabels from '../../../constants/WriteReview.constants';

const ReviewAccessibility = ({ inputType, inputCharLeft, textareaCharCount, textareaCharLeft }) => {
    const handelAccessibility = (useType) => {
        switch (useType) {
            case 'input':
                return (
                    <div className="accessiblity-container">
                        <p
                            className="accessibility-hidden-element"
                            aria-live="polite"
                            aria-atomic="true"
                        >
                            {inputCharLeft === 75
                                || inputCharLeft === 50
                                || inputCharLeft === 25 ? `${inputCharLeft} ${fieldLabels.productReview.accessibility.charLeftInfo}` : ''
                            }
                        </p>
                        <p
                            className="accessibility-hidden-element"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            {inputCharLeft === 10 || inputCharLeft === 0 ? `${inputCharLeft} ${fieldLabels.productReview.accessibility.charLeftInfo}` : ''}
                        </p>
                    </div>
                );

            case 'textarea':
                return (
                    <div className="accessiblity-container">
                        <p
                            className="accessibility-hidden-element"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            {textareaCharCount === 1 ? fieldLabels.productReview.accessibility.minCharInfo : ''}
                            {textareaCharCount === 50 ? fieldLabels.productReview.accessibility.success : ''}
                        </p>
                        <p
                            className="accessibility-hidden-element"
                            aria-live="polite"
                            aria-atomic="true"
                        >
                            {textareaCharLeft === 300
                                || textareaCharLeft === 200
                                || textareaCharLeft === 100
                                || textareaCharLeft === 50
                                || textareaCharLeft === 25 ? `${textareaCharLeft} ${fieldLabels.productReview.accessibility.charLeftInfo}` : ''
                            }
                        </p>
                        <p
                            className="accessibility-hidden-element"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            {textareaCharLeft === 10 || textareaCharLeft === 0 ? `${textareaCharLeft} ${fieldLabels.productReview.accessibility.charLeftInfo}` : ''}
                        </p>
                    </div>
                );

            default:
                return null;
        }
    };

    return handelAccessibility(inputType);
};

ReviewAccessibility.propTypes = {
    inputCharLeft: PropTypes.number,
    inputType: PropTypes.string,
    textareaCharLeft: PropTypes.number,
    textareaCharCount: PropTypes.number,
};

export default ReviewAccessibility;
