
import { useState, useEffect } from 'react';

const useElementSize = (element) => {
    const [width, setWidth] = useState(320);

    useEffect(() => {
        const divRef = element.current;
        if (divRef) {
            const divWidth = divRef.clientWidth;
            setWidth(divWidth);
        }
    });

    if (!width) {
        return null;
    }

    return { width };
};

export default useElementSize;
