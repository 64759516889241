export default {
    products: {
        searchBy: [
            'title',
            'description',
            'prodHeaderContent',
            'stepInfoContent',
            'strengthsContent',
            'textInfoContent',
        ],

        fetchFields: [
            'benefit',
            'description',
            'prodHeaderContent',
            'stepInfoContent',
            'strengthsContent',
            'textInfoContent',
            'image',
            'productId',
            'slug',
            'title',
        ],
    },
    content: {
        searchBy: ['title', 'description'],
        fetchFields: ['title', 'description', 'slug'],
    },
};
