import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { buildThumbnails } from './ProductImages.helper';
import useScreenSize from '../../Hooks/useScreenSize';

import Thumbnails from './Thumbnails';
import NumberSlider from './NumberSlider';
import { AriaLiveRegion, Img } from '../../UI';

const ProductImages = ({ images }) => {
    const resolution = useScreenSize();
    const [largeImageIndex, setLargeImageIndex] = useState(0);
    let thumbnailImages = buildThumbnails(images);
    const largeImage = thumbnailImages[largeImageIndex];

    useEffect(() => {
        thumbnailImages = buildThumbnails(images);
    }, [images]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const richTextLinks2 = document.querySelectorAll('div.slick-track img');
            for (let i = 0; i < richTextLinks2.length; i++) {
                richTextLinks2[i].setAttribute('class', 'event_image_click');
                richTextLinks2[i].setAttribute('data-action-detail', richTextLinks2[i].getAttribute('src'));
            }
        }
    });

    if (!largeImage) {
        return null;
    }

    return (
        <div className="product-images">
            <div className="large-image">
                <Img contentfulImage={largeImage.cfmedia} additionalParams={resolution && resolution.isMobile ? '?fm=webp' : null} />
            </div>
            <div className="images-navigation">
                {
                    resolution && !resolution.isDesktop
                        ? <NumberSlider images={thumbnailImages} onChange={setLargeImageIndex} current={largeImageIndex} />
                        : <Thumbnails images={thumbnailImages} onChange={setLargeImageIndex} />
                }
            </div>
            <AriaLiveRegion current={largeImageIndex + 1} total={thumbnailImages.length} />
        </div>
    );
};

ProductImages.propTypes = {
    images: PropTypes.object,
};

export default ProductImages;
