import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';
import rehypeRaw from 'rehype-raw';
import { InternalLink, Img } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const IngredientsBlockContainer = ({ title, leftImage, rightImage, contentCollection, ariaLabel }) => {
    const imgL = getCdnImage(leftImage && leftImage.cfmedia.url);
    const imgR = getCdnImage(rightImage && rightImage.cfmedia.url);
    const ingredientsItems = get(contentCollection, 'items', []);
    const backgroundImage = `url(${imgL}), url(${imgR})`;
    const ingSpecialClass = ingredientsItems.length < 5 ? 'center-ingredients' : '';

    const ingredients = ingredientsItems.map((ingredient, index) => {
        if (!ingredient) {
            return null;
        }

        const url = get(ingredient, 'page.url', '');
        const pageTitle = get(ingredient, 'page.title', '');

        return (
            /*
            Ingredient block can be rendered as a link or as a plain text. On CF there is an option that allows us to
            enter ingredient block without the url field, so here we have that render that use case too by testing
            the url variable value
            * */
            <div className={`ingredients-block ${url === '' ? 'ingredients-block-without-url' : ''} `} key={index}>
                {url && (
                    <InternalLink link={{ url, title: pageTitle }}>
                        <Img contentfulImage={ingredient.imageCloudinary.cfmedia} alt={ingredient.imageCloudinary.name} />
                        <ReactMarkdown className="ingredients-info" rehypePlugins={[rehypeRaw]}>
                            {ingredient.text}
                        </ReactMarkdown>
                    </InternalLink>
                )}
                {url === '' && (
                    <div className="ingredients-without-url">
                        <Img contentfulImage={ingredient.imageCloudinary.cfmedia} alt={ingredient.imageCloudinary.name} />
                        <ReactMarkdown className="ingredients-info" rehypePlugins={[rehypeRaw]}>
                            {ingredient.text}
                        </ReactMarkdown>
                    </div>
                ) }
            </div>
        );
    });

    return (
        <div className="ingredients-block-container" style={{ backgroundImage }} suppressHydrationWarning={true} aria-label={ariaLabel}>
            <ReactMarkdown className="ingredients-block-container-title" rehypePlugins={[rehypeRaw]}>
                {title}
            </ReactMarkdown>
            <div className={`ingredients-items-container ${ingSpecialClass}`}>
                {ingredients}
            </div>
        </div>
    );
};

IngredientsBlockContainer.propTypes = {
    title: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    contentCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default IngredientsBlockContainer;
