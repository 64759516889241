import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import { AriaLiveRegion } from '../UI';
import BackgroundImage from '../UI/BackgroundImage/BackgroundImage';
import ProductCard from '../ProductCard/ProductCard';
import { importedSettings } from './slickSettings.const';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const ProductCarousel = (props) => {
    const { title, slidesCollection, leftImg, rightImg, ariaLabel } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [clientRender, setClientRender] = useState(false);
    const slidesLength = slidesCollection.items.length;
    const settings = {
        ...importedSettings,
        afterChange: setCurrentSlide,
    };

    useEffect(() => {
        if (process.browser) {
            setClientRender(true);
        }
    }, []);

    const background = {
        backgroundImage: `url(${getCdnImage(leftImg.cfmedia.url)}), url(${getCdnImage(rightImg.cfmedia.url)})`,
    };

    const slides = slidesCollection.items.map((item, index) => {
        const { productvariants, seoHead, productBenefit, name } = item;
        const productVariant = productvariants?.items[0];
        if (productVariant && seoHead) {
            return (
                <div key={index} className="Slide">
                    <LazyLoad offset={20}>
                        <ProductCard
                            index={index}
                            slidesLength={slidesLength}
                            imgSrc={seoHead.featuredImage.cfmedia}
                            href={seoHead.url}
                            productBenefit={productBenefit}
                            name={name}
                            showRatingsLabel={true}
                            className="product-card"
                            sku={productVariant.gtin}
                        />
                    </LazyLoad>
                </div>
            );
        }
        return null;
    }).filter(slide => slide !== null);

    const renderSlides = () => (
        <>
            {!clientRender && <Slider {...settings}>{slides}</Slider>}
            {clientRender && <Slider {...settings}>{slides}</Slider>}
        </>
    );

    return (
        <LazyLoad offset={20}>
            <BackgroundImage
                className="product-carousel"
                backgroundImage={background}
                ariaLabel={ariaLabel}
            >
                <div className="slides-container">
                    <h2>{title}</h2>
                    <div className="slides">
                        {renderSlides()}
                        <AriaLiveRegion current={currentSlide + 1} total={slidesLength} />
                    </div>
                </div>
            </BackgroundImage>
        </LazyLoad>
    );
};

ProductCarousel.propTypes = {
    title: PropTypes.string,
    slidesCollection: PropTypes.object,
    leftImg: PropTypes.object,
    rightImg: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ProductCarousel;
