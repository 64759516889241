import useScreenSize from '../../Hooks/useScreenSize';

const TABLET_MIN = 768;
const TABLET_MAX = 991;

const Mobile = ({ children }) => {
    const resolution = useScreenSize();

    if (resolution && resolution.isMobile) {
        return children;
    }

    return null;
};

const Tablet = ({ children }) => {
    const resolution = useScreenSize();

    if (resolution && resolution.isTablet) {
        return children;
    }

    return null;
};

const TabletOrBigger = ({ children }) => {
    const resolution = useScreenSize();

    if (resolution && resolution.width >= TABLET_MIN) {
        return children;
    }

    return null;
};

const TabletOrSmaller = ({ children }) => {
    const resolution = useScreenSize();

    if (resolution && resolution.width <= TABLET_MAX) {
        return children;
    }

    return null;
};

const Desktop = ({ children }) => {
    const resolution = useScreenSize();

    if (resolution && resolution.isDesktop) {
        return children;
    }

    return null;
};

const Device = {
    Mobile,
    Tablet,
    TabletOrBigger,
    TabletOrSmaller,
    Desktop,
};

export default Device;
