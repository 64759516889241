module.exports = `
...on LandingPageCardCarousel{
  __typename
    name
    cardsCollection{
        items{
          name
          image{
            url
            description
        }
        title
        description
        link
        styles
        }
      }
    styles
    leftImg: leftImge {
     cfmedia {
        description
        url
        width
        height
      }
    }
    rightImg: rightImage {
     cfmedia {
        description
        url
        width
        height
      }
    }
  }
  
`;
