import ReactMarkdown from 'react-markdown';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import BlockWrapper from '../BlockWrapper/BlockWrapper';
import BlockWrapperWithImage from '../BlockWrapperWithImage/BlockWrapperWithImage';
import { MDLinkRenderer, Img } from '../UI';

const StepInfoBlock = (props) => {
    const { topImage, textBlock, wrapper, ariaLabel } = props;
    const [, setSpecialTag] = useState(null);
    let Wrapper;
    let mainClass = 'step-info-block';

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const richTextLinks12 = document.querySelectorAll('div.product-header > div > div.product-info > div.description p a');
            for (let i = 0; i < richTextLinks12.length; i++) {
                richTextLinks12[i].setAttribute('class', 'event_internal_link');
                richTextLinks12[i].setAttribute('data-action-detail', richTextLinks12[i].getAttribute('href'));
            }
        }
    }, []);

    if (typeof window !== 'undefined') {
        const richTextLinks4 = document.querySelectorAll('div.layout > div.no-wrapper > div > div > p:nth-child(2) > a');
        for (let i = 0; i < richTextLinks4.length; i++) {
            richTextLinks4[i].setAttribute('class', 'event_internal_link');
            richTextLinks4[i].setAttribute('data-action-detail', richTextLinks4[i].getAttribute('href'));
        }
    }

    if (typeof window !== 'undefined') {
        const richTextLinks4 = document.querySelectorAll('.step-info-block .text-block p a');
        for (let i = 0; i < richTextLinks4.length; i++) {
            richTextLinks4[i].setAttribute('class', 'event_internal_link');
            richTextLinks4[i].setAttribute('data-action-detail', richTextLinks4[i].getAttribute('href'));
        }
    }

    if (!wrapper) {
        Wrapper = ({ children }) => <div className="no-wrapper">{children}</div>;
    } else {
        Wrapper = wrapper.__typename === 'BlockWrapper' ? BlockWrapper : BlockWrapperWithImage;
        mainClass = `${mainClass}${wrapper.topImage ? ' pulled' : ''}`;
    }

    const BlockImage = topImage
        ? <Img className="block-image" contentfulImage={topImage.cfmedia} />
        : null;

    useEffect(() => {
        if (typeof window !== 'undefined' && window.location.pathname === '/en/potent-aloe-vera-collection/') {
            setSpecialTag('SULFATE FREE');
        }
    }, []);

    return (
        <Wrapper {...props.wrapper}>
            <div className={mainClass} aria-label={ariaLabel}>
                {BlockImage}
                <ReactMarkdown className="text-block" rehypePlugins={[rehypeRaw]} components={{ link: MDLinkRenderer }}>
                    {textBlock}
                </ReactMarkdown>
            </div>
        </Wrapper>
    );
};

StepInfoBlock.propTypes = {
    children: PropTypes.element,
    wrapper: PropTypes.object,
    topImage: PropTypes.object,
    textBlock: PropTypes.string,
    imgPath: PropTypes.object,
    ariaLabel: PropTypes.string,
    location: PropTypes.string,
};

export default StepInfoBlock;
