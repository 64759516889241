import uniqBy from 'lodash/uniqBy';

export const getGenericFilterNames = (filterNames, reverse) => {
    const genericNames = {};
    filterNames.forEach((filterName, index) => {
        if (reverse) {
            genericNames[`filter${index + 1}`] = filterName;
        } else {
            genericNames[filterName] = `filter${index + 1}`;
        }
    });

    return genericNames;
};

export const buildFiltersUrl = (activeFilters, allFilters) => {
    const uniqueFilterNames = uniqBy(allFilters, 'filterName').map(filter => filter.filterName);
    const filterNames = getGenericFilterNames(uniqueFilterNames);

    const arr = [];
    Object.keys(activeFilters).forEach(key => {
        arr.push(...activeFilters[key]);
    });

    const urlHash = arr.map(filter => {
        return `${filterNames[filter.filterName]}=${filter.nameEn}`;
    }).join('&');

    return `#${urlHash}`;
};

export const buildFiltersFromUrlHash = (urlHash, allFilters) => {
    const uniqueFilterNames = uniqBy(allFilters, 'filterName').map(filter => filter.filterName);

    if (uniqueFilterNames.length < 1) {
        return {};
    }

    const genericFilterNames = getGenericFilterNames(uniqueFilterNames, true);

    const hashArray = urlHash.replace('#', '').split('&');

    const filters = {};
    hashArray.forEach(item => {
        const splitItem = item.split('=');
        const filterName = splitItem[0];
        const filterValue = splitItem[1];
        const filter = genericFilterNames[filterName];

        if (filter) {
            const filterToAdd = allFilters.find(f => f.filterName === filter && f.nameEn === decodeURI(filterValue));

            if (filterToAdd) {
                filters[filter] = filters[filter]
                    ? [...filters[filter], filterToAdd]
                    : [filterToAdd];
            }
        }
    });

    return filters;
};

export const getSortedFilters = (filters) => {
    return [filters[1], filters[2], filters[0]];
};
