import algoliasearch from 'algoliasearch';

const remapSearchResults = (data) => ({
    content: data.filter(item => item.type === 'content'),
    products: data.filter(item => item.type === 'product'),
});

const getSearchResults = searchTerm => {
    if (!process.env.ALGOLIA_APP_ID || !process.env.ALGOLIA_APP_SEARCH_KEY || !process.env.ALGOLIA_INDEX) {
        throw new Error(
            'Cannot create algolia search client: ALGOLIA_APP_ID, ALGOLIA_APP_SEARCH_KEY or ALGOLIA_INDEX envs are empty',
        );
    }

    const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_APP_SEARCH_KEY);
    const index = client.initIndex(process.env.ALGOLIA_INDEX);

    return index.search(searchTerm, { 'hitsPerPage': 100 })
        .then((result) => remapSearchResults(result.hits))
        .catch(ex => console.warn('Error fetching search results: ', ex));
};

export default {
    getSearchResults,
};
