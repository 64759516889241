import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';

import { getFilteredCollection, buildFilters } from '../../../helpers/filtering';
import useScreenSize from '../../Hooks/useScreenSize';
import AppContext from '../../../context/App.context';

import ProductCard from '../../ProductCard/ProductCard';
import { Loading } from '../../UI';
import constants from '../../../constants/Products.constants';
import { loadCSwidget } from '../../../helpers/bazaarVoice';

/** CS Widget */
useEffect(() => {
    if (typeof window !== 'undefined') {
        loadCSwidget();
    }
}, []);

const Products = ({ products, filters }) => {
    const { appData } = useContext(AppContext);
    const resolution = useScreenSize();
    const [numberOfShowingItems, setNumberOfShowingItems] = useState({ dt: 12, tb: 9, sp: 10 });

    const productListContainsFilters = products.items.find(item => item.__typename === 'ProductFilter');

    const productProducts = products.items.filter(item => item.__typename === 'ProductHeader');
    const filterProducts = products.items.filter(item => item.__typename === 'ProductFilter');

    const filteredProducts = productListContainsFilters
        ? getFilteredCollection(getFilteredCollection(appData.allProducts, buildFilters(filterProducts)), filters)
        : getFilteredCollection(productProducts, filters);

    let productsToSlice;
    if (resolution && resolution.isMobile) productsToSlice = numberOfShowingItems.sp;
    if (resolution && resolution.isTablet) productsToSlice = numberOfShowingItems.tb;
    if (resolution && resolution.isDesktop) productsToSlice = numberOfShowingItems.dt;

    const renderProducts = () => {
        return (
            filteredProducts.map((product, i) => {
                const { name, seoHead, productBenefit, productvariants } = product;
                const image = product.seoHead.featuredImage.cfmedia;
                const GTIN = productvariants.items[0].gtin;
                const visible = i < productsToSlice ? 'block' : 'none';
                return (
                    <div
                        className="product-card-wrapper"
                        key={i}
                        style={{ display: visible }}
                    >
                        <ProductCard
                            name={name}
                            imgAlt={name}
                            href={seoHead.url}
                            imgSrc={image}
                            productBenefit={productBenefit}
                            showRatingsLabel={true}
                            ratingsPostLabel="Stars"
                            className="product-card"
                            sku={GTIN}
                        />
                    </div>
                );
            })
        );
    };

    const [ref, inView] = useInView({ threshold: 0 });

    useEffect(() => {
        if (inView) {
            setNumberOfShowingItems({
                dt: numberOfShowingItems.dt + numberOfShowingItems.dt,
                tb: numberOfShowingItems.tb + numberOfShowingItems.tb,
                sp: numberOfShowingItems.sp + numberOfShowingItems.sp,
            });
        }
    }, [inView]);

    const areAllLoaded = filteredProducts.length <= productsToSlice;
    return (
        <div className="products">
            <div className="products-count">{`${constants.showing} ${areAllLoaded ? filteredProducts.length : productsToSlice} ${constants.items}`}</div>
            <div className="products-wrapper">
                {renderProducts()}
                {!areAllLoaded && <Loading ref={ref} />}
            </div>
        </div>
    );
};

Products.propTypes = {
    products: PropTypes.object,
    filters: PropTypes.object,
};

export default Products;
