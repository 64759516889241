import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';

const BackgroundImage = ({ className, backgroundImage, children }) => {
    if (!backgroundImage) return null;
    const [ref, inView] = useInView({ threshold: 1, triggerOnce: true });

    return (
        <>
            <div style={inView ? backgroundImage : {}} ref={ref} className={className}>
                {inView && children}
            </div>
        </>
    );
};

BackgroundImage.propTypes = {
    className: PropTypes.string,
    backgroundImage: PropTypes.object,
};

export default BackgroundImage;
