import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LazyLoad from 'react-lazyload';
import labels from '../../constants/label.constants';
import useScreenSize from '../Hooks/useScreenSize';
import { Img } from '../UI';

const VideoBlock = (props) => {
    const [clicked, setClicked] = useState(false);
    const resolution = useScreenSize();

    const { videoTitle, playIconText, footnote, leftImage, rightImage, smallVideo, videoPoster, video, ariaLabel, styles } = props;
    const videoContainerClass = smallVideo ? 'small-video' : 'big-video';
    const h2Class = clicked && resolution && !resolution.isMobile ? 'hideable' : '';

    const clickHandler = () => {
        setClicked(true);
    };

    const playWithEnter = (event) => {
        if (event.key === 'Enter') {
            clickHandler();
        }
    };

    const renderVideo = () => {
        const getVideoId = (url) => {
            const regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
            const match = url.match(regExp);

            const videoIdIndex = 5;
            const videoIdLength = 11;

            return (match && match[videoIdIndex].length === videoIdLength)
                ? match[videoIdIndex]
                : null;
        };

        const videoId = getVideoId(video);

        if (!clicked) {
            return (
                <div
                    role="button"
                    tabIndex={0}
                    className="video-poster-wrapper"
                    onKeyPress={playWithEnter}
                    onClick={clickHandler}
                    aria-label={labels.video.play}
                >
                    <Img className="video-poster" alt="" contentfulImage={videoPoster?.cfmedia} />
                    <div className="play-wrapper">
                        <div className="arrow-play-video event_video_play" data-action-detail={videoId} />
                        <span>{playIconText}</span>
                    </div>
                </div>
            );
        }

        return (
            <iframe
                allow="autoplay"
                frameBorder="0"
                src={`//www.youtube.com/embed/${videoId}?controls=1&showinfo=0&autoplay=1`}
                title={videoTitle}
            />
        );
    };

    const classNames = `video-block ${styles}`;
    return (

        <div className={classNames} aria-label={ariaLabel}>
            <div className="video-wrapper">
                <div className="video-inner">
                    <LazyLoad offset={20}>
                        <ReactMarkdown className={`${h2Class} video-inner-title`} rehypePlugins={[rehypeRaw]}>
                            {videoTitle}
                        </ReactMarkdown>
                        <div className="decoration decoration-left">
                            <Img contentfulImage={leftImage?.cfmedia} />
                        </div>
                        <div className="decoration decoration-right">
                            <Img contentfulImage={rightImage?.cfmedia} />
                        </div>
                        <div className={videoContainerClass}>
                            {renderVideo()}
                        </div>
                    </LazyLoad>
                </div>
            </div>
            <p>{footnote}</p>
        </div>
    );
};

VideoBlock.propTypes = {
    videoTitle: PropTypes.string,
    playIconText: PropTypes.string,
    video: PropTypes.string,
    footnote: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    smallVideo: PropTypes.bool,
    videoPoster: PropTypes.object,
    ariaLabel: PropTypes.string,
    styles: PropTypes.string,
};

export default VideoBlock;
