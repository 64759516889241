import { useState, useEffect } from 'react';
import mapValues from 'lodash/mapValues';

import BazzarVoiceApi from '../../adapters/bazaarVoice.adapter';

const useRatingAndReviews = (productId, offset, sort) => {
    const [statistics, setStatistics] = useState({});
    const [opt, setOpt] = useState(sort);

    useEffect(() => {
        BazzarVoiceApi.getRating(productId, offset, sort).then(res => {
            if (res && res.Results.length > 0) {
                const rating = mapValues(res.Includes.Products, (product) =>
                    product.ReviewStatistics.AverageOverallRating);
                const total = mapValues(res.Includes.Products, (item) =>
                    item.ReviewStatistics.TotalReviewCount);

                const reviews = res.Results;
                const oldArr = sort === opt ? (statistics.allReviews || []) : [];
                const newArr = oldArr.concat(reviews);

                setStatistics({
                    allReviews: newArr,
                    averageOverallRating: rating[productId],
                    totalReviewCount: total[productId],
                });
                setOpt(sort);
            }
        });
    }, [offset, sort]);

    return statistics;
};

export default useRatingAndReviews;
