module.exports = (pageId, componentQueries, contentCollectionLimit) => `query {
page(id: "${pageId}") {
    sys {
        id
    }
    seoHead {
        title
        description
        pageType
        featuredImage {
            cfmedia {
                description
                url
                width
                height
            }
        }
        url
        ogTitle
        ogType
        ogDescription
        keywords
        bvData: bvDataCollection(limit: 20) {
            items {
                productName
                productId
                eans
                upcs
                family
            }
        }
        schemaCollection{
            items{
              name
              json
            }
        }
        pgdl: pgdlCollection {
            items {
              name,
              json
            }
        }
        twitterTitle
        twitterDescription
        breadcrumbs
    }
    contentCollection(limit: ${contentCollectionLimit}, preview: ${process.env.ENV === 'production' ? 'false' : 'true'}) {
        items {
            __typename
            ${componentQueries}
        }
    }
}
}`;
