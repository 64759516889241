import React, { useState, useContext, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import Products from './Products/Products';
import { Filters, FiltersMobile, ActiveFilters } from './Filters';
import { Button, Device, Modal } from '../UI';
import { updateFilters, areFiltersEmpty, removeFilter } from '../../helpers/filtering';
import { buildFiltersUrl, buildFiltersFromUrlHash } from './ProductListing.helper';
import Collections from './Collections/Collections';
import AppContext from '../../context/App.context';
import disableBodyScroll from '../../helpers/disalbeBodyScroll';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const ProductListing = ({ collections, products, leftBgImage, rightBgImage, showFilters, ariaLabel }) => {
    const [activeFilters, setActiveFilters] = useState({});
    const [isFiltersModalOpen, setIsFilterModalOpen] = useState(false);
    const { appData, appState } = useContext(AppContext);
    const { productListCollection } = appState;
    const { isModalOpen, setIsCollectionsOpen } = productListCollection;
    const resolution = useScreenSize();

    useEffect(() => {
        const filters = buildFiltersFromUrlHash(window.location.hash, appData.allFilters);
        setActiveFilters(filters);
    }, []);

    const onFilterSelect = (selectedFilter) => {
        const updatedFilters = updateFilters(selectedFilter, activeFilters);
        setActiveFilters(updatedFilters);

        const urlHash = buildFiltersUrl(updatedFilters, appData.allFilters);
        window.history.replaceState(null, null, urlHash);
    };

    const onFilterRemove = (filterToRemove) => {
        const updatedActiveFilters = removeFilter(filterToRemove, activeFilters);
        setActiveFilters(updatedActiveFilters);

        const urlHash = buildFiltersUrl(updatedActiveFilters, appData.allFilters);
        window.history.replaceState(null, null, urlHash);
    };

    const onFiltersReset = () => {
        setActiveFilters({});
        window.history.replaceState(null, null, '#');
    };

    const ResetButton = () => {
        if (areFiltersEmpty(activeFilters)) {
            return null;
        }

        return (
            <>
                <span className="selected-label">Selected: </span>
                <Button
                    onClick={onFiltersReset}
                    className="filters-reset"
                    iconClassName="remove-icon"
                >
                    Reset all filters
                </Button>
            </>
        );
    };

    const onToggleCollectionButton = open => {
        setIsCollectionsOpen(open);
        disableBodyScroll(open);
    };

    const onToggleFilterModal = open => {
        setIsFilterModalOpen(open);
        disableBodyScroll(open);
    };

    const FilterByButton = () => {
        return (
            <Button
                onClick={() => onToggleFilterModal(true)}
                className="filter-by-button"
            >
                Filter by:
            </Button>
        );
    };

    const onApplyFilters = (filters) => {
        setActiveFilters(filters);
        onToggleFilterModal(false);

        const urlHash = buildFiltersUrl(filters, appData.allFilters);
        window.location.replace(urlHash);
    };

    const CollectionsButton = () => {
        return (
            <Button onClick={() => onToggleCollectionButton(true)} className="collections-button">
                Collections
            </Button>
        );
    };

    const backgroundImage = `url(${getCdnImage(leftBgImage.cfmedia.url)}), url(${getCdnImage(rightBgImage.cfmedia.url)})`;

    return (
        <div className="product-listing" style={resolution && resolution.isDesktop ? { backgroundImage } : null} suppressHydrationWarning={true} aria-label={ariaLabel}>
            <LazyLoad offset={20}>
                <div className="content">
                    <div className="sidebar">
                        <Collections collections={collections.items} />
                    </div>
                    <div className="main">
                        <Device.TabletOrBigger>
                            <div className="products-header">
                                {showFilters && (
                                    <>
                                        <span>Filter by:</span>
                                        <Filters
                                            activeFilters={activeFilters}
                                            onChange={onFilterSelect}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="collections-container">
                                <CollectionsButton />
                            </div>
                            <div>
                                <ResetButton />
                                <ActiveFilters
                                    filters={activeFilters}
                                    onFilterRemove={onFilterRemove}
                                    onFiltersReset={onFiltersReset}
                                />
                            </div>
                            <div>
                                <Products
                                    products={products}
                                    filters={activeFilters}
                                />
                            </div>
                        </Device.TabletOrBigger>

                        <Device.Mobile>
                            <div className="buttonWrapper">
                                {showFilters && <FilterByButton />}
                                <CollectionsButton />
                            </div>
                            <Products
                                products={products}
                                filters={activeFilters}
                            />
                            <Modal
                                className="filter-modal"
                                isOpen={isFiltersModalOpen}
                                onClose={() => onToggleFilterModal(false)}
                            >
                                {showFilters && <FiltersMobile activeFilters={activeFilters} onApply={onApplyFilters} />}
                            </Modal>
                        </Device.Mobile>

                        <Device.TabletOrSmaller>
                            <Modal
                                className="modal"
                                isOpen={isModalOpen}
                                onClose={() => onToggleCollectionButton(false)}
                            >
                                <Collections collections={collections.items} />
                            </Modal>
                        </Device.TabletOrSmaller>
                    </div>
                </div>
            </LazyLoad>
        </div>
    );
};

ProductListing.propTypes = {
    collections: PropTypes.object,
    leftBgImage: PropTypes.object,
    products: PropTypes.object,
    rightBgImage: PropTypes.object,
    showFilters: PropTypes.bool,
    ariaLabel: PropTypes.string,
};

export default ProductListing;
