import includes from 'lodash/includes';
import { useState, useEffect } from 'react';
import slashify from '../../helpers/slashify';

const useCurrentPage = (pageSlug) => {
    const [isCurrentPage, setCurrentPage] = useState(false);

    useEffect(() => {
        if (process.browser) {
            const currentPageUrl = window.location.pathname;
            const doesPageMatch = includes([currentPageUrl, slashify(currentPageUrl), currentPageUrl.slice(0, -1)], pageSlug);

            setCurrentPage(doesPageMatch);
        }
    }, []);

    return isCurrentPage;
};

export default useCurrentPage;
