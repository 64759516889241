import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Button } from '../UI';
import { getSrcSet, getCdnImage } from '../../adapters/cloudinary.adapter';

const Card = (props) => {
    const { image, title, description, link, styles } = props;
    return (
        <div className="card">
            <div className={styles}>
                <picture>
                    <source srcSet={getSrcSet(image.url, 1600, 768)} media="(min-width: 768px)" />
                    <img src={getCdnImage(image.url)} alt={image.description} />
                </picture>
            </div>
            <div className="column-text">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {title}
                </ReactMarkdown>
                <div className="description-text">
                    <ReactMarkdown className="column-text" rehypePlugins={[rehypeRaw]}>
                        {description}
                    </ReactMarkdown>
                    <a href={link}>
                        <Button className="viewProductButton">
                            View Product
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    image: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
    }),
    title: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
    styles: PropTypes.string,
};

export default Card;
