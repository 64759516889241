module.exports = `
{
  productHeaderCollection(limit: 300) {
    items {
      name
      nameEn
      description
      productBenefit
      bvData {
        productId
      }
      filters: filtersCollection(limit: 10) {
        items {
          filterName
          nameEn
          label
        }
      }
      ingredientsLink {
        page {
          title
        }
      }
      seoHead {
        url
        featuredImage {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
      }
    }
  }
}
  `;
