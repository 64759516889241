import { getGenericFilterNames, buildFiltersUrl, buildFiltersFromUrlHash } from './ProductListing.helper';

describe('Testing getGenericFilterNames() function', () => {
    const input = ['Hair Type', 'Product Type'];

    test('It should return generic names for filter names', () => {
        expect(getGenericFilterNames(input)).toEqual({ 'Hair Type': 'filter1', 'Product Type': 'filter2' });
    });

    test('It should return generic reversed value/key filter names object when reverse param is provided', () => {
        expect(getGenericFilterNames(input, true)).toEqual({ 'filter1': 'Hair Type', 'filter2': 'Product Type' });
    });
});

describe('Testing buildFiltersUrl() function', () => {
    test('It should build filters from url hash', () => {
        const allFilters = [
            { filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' },
            { filterName: 'Product Type', nameEn: 'Some Product Value', label: 'Some value in french' },
            { filterName: 'Hair Needs', nameEn: 'Some Hair Needs Value', label: 'Some value in german' },
        ];

        const activeFilters = {
            'Hair Type': [{ filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' }],
            'Product Type': [{ filterName: 'Product Type', nameEn: 'Some Product Value', label: 'Some value in french' }],
        };
        const expectedHashUrl = '#filter1=Some Value&filter2=Some Product Value';


        expect(buildFiltersUrl(activeFilters, allFilters)).toBe(expectedHashUrl);
    });
});

describe('Testing buildFiltersFromUrlHash() function', () => {
    const allFilters = [
        { filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' },
        { filterName: 'Product Type', nameEn: 'Some Product Value', label: 'Some value in french' },
        { filterName: 'Hair Needs', nameEn: 'Some Hair Needs Value', label: 'Some value in german' },
    ];

    test('It should build correct filters when valid hasUrl is provided', () => {
        const urlHash = '#filter1=Some%20Value&filter2=Some%20Product%20Value';
        const expectedFilters = {
            'Hair Type': [{ filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' }],
            'Product Type': [{ filterName: 'Product Type', nameEn: 'Some Product Value', label: 'Some value in french' }],
        };

        expect(buildFiltersFromUrlHash(urlHash, allFilters)).toEqual(expectedFilters);
    });


    test('It should ignore filters names from hash url that does not exist in allFilters', () => {
        const urlHash = '#filter1=Some%20Value&sometest=blablabla&filter2=Some%20Product%20Value';

        const expectedFilters = {
            'Hair Type': [{ filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' }],
            'Product Type': [{ filterName: 'Product Type', nameEn: 'Some Product Value', label: 'Some value in french' }],
        };

        expect(buildFiltersFromUrlHash(urlHash, allFilters)).toEqual(expectedFilters);
    });

    test('It should ignore filters from hash url that does not exist in allFilters', () => {
        const urlHash = '#filter1=Some%20Value&filter3=blablabla&filter2=Some%20Product%20Value';

        const expectedFilters = {
            'Hair Type': [{ filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' }],
            'Product Type': [{ filterName: 'Product Type', nameEn: 'Some Product Value', label: 'Some value in french' }],
        };

        expect(buildFiltersFromUrlHash(urlHash, allFilters)).toEqual(expectedFilters);
    });

    test('It should build correct filter until the point when hash url is not valid', () => {
        const urlHash = '#filter1=Some%20Value&filfsftsdf+sdfs&sdf(sdfs';

        const expectedFilters = {
            'Hair Type': [{ filterName: 'Hair Type', nameEn: 'Some Value', label: 'Some value in spanish' }],
        };

        expect(buildFiltersFromUrlHash(urlHash, allFilters)).toEqual(expectedFilters);
    });

    test('It should return an empty object when invalid hash url is provided', () => {
        const urlHash = '#sdff%7(&=ilfsftsdf+sdfs&sdf(sdfs';
        const expectedFilters = {};

        expect(buildFiltersFromUrlHash(urlHash, allFilters)).toEqual(expectedFilters);
    });
});
