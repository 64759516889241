import React from 'react';
import PropTypes from 'prop-types';

import { getErrorsAmount } from './WriteReview.helper';
import labels from '../../constants/WriteReview.constants';

const NumberOfErrors = ({ errors }) => {
    const numberOfErrors = getErrorsAmount(errors);

    if (numberOfErrors < 1) {
        return null;
    }

    const message = labels.numberOfErrors.replace(/\{numberOfErrors\}/, numberOfErrors);

    return (
        <div className="number-of-errors">
            <p className="error">{message}</p>
        </div>
    );
};

NumberOfErrors.propTypes = {
    errors: PropTypes.object,
};

export default NumberOfErrors;
