import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fieldLabels from '../../../constants/WriteReview.constants';

const Checkbox = ({ label, checked, className, disabled, hasError, onChange, required }) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const checkboxClassName = [
        'checkbox event_button_click',
        `${isChecked ? 'checked' : ''}`,
        `${disabled ? 'disabled' : ''}`,
        `${hasError ? 'error' : ''}`,
        `${className || ''}`,
    ].join(' ').trim();

    const onCheckboxClick = () => {
        if (onChange) {
            onChange(!isChecked);
        }
        setIsChecked(!isChecked);
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onCheckboxClick();
        }
    };

    return (
        <div
            className={checkboxClassName}
            id={`checkbox-field${label ? `-${label}` : ''}`}
            role="checkbox"
            aria-checked={isChecked ? 'true' : 'false'}
            onClick={onCheckboxClick}
            onKeyPress={onKeyPress}
            data-action-detail={label}
            tabIndex={0}
        >
            <div className="box" />
            {label
                ? <label htmlFor={`checkbox-field-${label}`} className={required ? 'required' : ''}>{label}</label>
                : (
                    <label htmlFor="checkbox-field" className={required ? 'required' : ''}>
                        {fieldLabels.checkboxText} <a rel="noreferrer" target="_blank" href={fieldLabels.termsConditionsLink}>{fieldLabels.termsConditionsText}</a>.
                    </label>
                )
            }
        </div>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};

export default Checkbox;
