module.exports = `
... on TableInfoBlock {
  content: contentCollection {
    items {
      title
      image {
        name
        cfmedia {
          description
          url
          width
          height
        }
      }
      text
    }
  }
  wrapper {
    __typename
    ... on BlockWrapper {
      leftImage {
        cfmedia {
          description
          url
          width
          height
        }
      }
      rightImage {
        cfmedia {
          description
          url
          width
          height
        }
      }
    }
    ... on BlockWrapperWithImage {
      topImage {
        name
        cfmedia {
          description
          url
          width
          height
        }
      }
      leftImage {
        cfmedia {
          description
          url
          width
          height
        }
      }
      rightImage {
        cfmedia {
          description
          url
          width
          height
        }
      }
    }
  }
  ariaLabel
}
`;
