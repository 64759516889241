import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import { InternalLink, Img } from '../UI';

const HairQuiz = ({ title, image, link, ariaLabel }) => {
    return (
        <div className="hair-quiz" aria-label={ariaLabel}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {title}
            </ReactMarkdown>
            <InternalLink className="column-link" link={link} />
            <Img contentfulImage={image.cfmedia} />
        </div>
    );
};

HairQuiz.propTypes = {
    title: PropTypes.string,
    ariaLabel: PropTypes.string,
    image: PropTypes.object,
    link: PropTypes.object,
};

export default HairQuiz;
