import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage';

import { getFieldError, getPossibleMonths, getPossibleYears, isRequired, getLabelStyle } from '../WriteReview.helper';
import { fieldNames } from '../WriteReview.config';
import Select from './Select';
import { Checkbox } from '../../UI';

const Review = ({ fieldLabels, onChange, onBlur, errors }) => {
    const { title, nickname, dateOfBirth, location, email, hairType, tos } = fieldLabels;

    const onFieldChange = (e) => {
        const { name, value } = e.target;

        if ((name === 'month' || name === 'year') && ['month', 'year'].includes(value)) {
            return null;
        }

        onChange({
            field: name,
            value,
        });
    };

    const onFieldBlur = (e) => {
        onBlur({ field: e.target.name });
    };

    const nicknameClassName = getFieldError(errors.nickname) ? 'input-error' : null;
    const locationClassName = getFieldError(errors.location) ? 'input-error' : null;
    const emailClassName = getFieldError(errors.email) ? 'input-error' : null;

    return (
        <div className="review">
            <h2>{title}</h2>
            <div>
                <label
                    htmlFor={fieldNames.NICKNAME}
                    className={`field-label ${getLabelStyle(fieldNames.NICKNAME)}`}
                >
                    {nickname.label}
                </label>
                <div>
                    <input
                        type="text"
                        id={fieldNames.NICKNAME}
                        name={fieldNames.NICKNAME}
                        onChange={onFieldChange}
                        onBlur={onFieldBlur}
                        className={nicknameClassName}
                        autoComplete="on"
                        aria-describedby={`${fieldNames.NICKNAME}Help ${fieldNames.NICKNAME}Error`}
                        aria-required={isRequired(fieldNames.NICKNAME) ? 'true' : 'false'}
                        aria-invalid={getFieldError(errors.nickname) ? 'true' : 'false'}
                    />
                    <p id={`${fieldNames.NICKNAME}Help`} className="field-info">{nickname.info}</p>
                    <ErrorMessage id={`${fieldNames.NICKNAME}Error`} errors={errors.nickname} errorMessages={nickname.errorMessages} />
                </div>
            </div>
            <div>
                <div className={`field-label ${getLabelStyle(fieldNames.MONTH)}`}>
                    {dateOfBirth.label}
                </div>
                <div className="field-wrapper">
                    <Select
                        fieldName={fieldNames.MONTH}
                        fieldData={dateOfBirth.month}
                        options={getPossibleMonths()}
                        onFieldChange={onFieldChange}
                        onFieldBlur={onFieldBlur}
                        errors={errors.month}
                        required={isRequired(fieldNames.MONTH)}
                    />
                    <Select
                        fieldName={fieldNames.YEAR}
                        fieldData={dateOfBirth.year}
                        options={getPossibleYears()}
                        onFieldChange={onFieldChange}
                        onFieldBlur={onFieldBlur}
                        errors={errors.year}
                        required={isRequired(fieldNames.YEAR)}
                    />
                </div>
            </div>
            <div>
                <label
                    htmlFor={fieldNames.LOCATION}
                    className={`field-label ${getLabelStyle(fieldNames.LOCATION)}`}
                >
                    {location.label}
                </label>
                <div>
                    <input
                        type="text"
                        id={fieldNames.LOCATION}
                        name={fieldNames.LOCATION}
                        onChange={onFieldChange}
                        onBlur={onFieldBlur}
                        className={locationClassName}
                        autoComplete="on"
                        aria-describedby={`${fieldNames.LOCATION}Help ${fieldNames.LOCATION}Error`}
                        aria-required={isRequired(fieldNames.LOCATION) ? 'true' : 'false'}
                        aria-invalid={getFieldError(errors.location) ? 'true' : 'false'}
                    />
                    <p id={`${fieldNames.LOCATION}Help`} className="field-info">{location.info}</p>
                    <ErrorMessage
                        id={`${fieldNames.LOCATION}Error`}
                        errors={errors.location}
                        errorMessages={location.errorMessages}
                    />
                </div>
            </div>
            <div>
                <label
                    htmlFor={fieldNames.EMAIL}
                    className={`field-label ${getLabelStyle(fieldNames.EMAIL)}`}
                >
                    {email.label}
                </label>
                <div>
                    <input
                        type="text"
                        id={fieldNames.EMAIL}
                        name={fieldNames.EMAIL}
                        onChange={onFieldChange}
                        onBlur={onFieldBlur}
                        className={emailClassName}
                        autoComplete="on"
                        aria-describedby={`${fieldNames.EMAIL}Help ${fieldNames.EMAIL}Error`}
                        aria-required={isRequired(fieldNames.EMAIL) ? 'true' : 'false'}
                        aria-invalid={getFieldError(errors.email) ? 'true' : 'false'}
                    />
                    <p id={`${fieldNames.EMAIL}Help`} className="field-info">{email.info}</p>
                    <ErrorMessage
                        id={`${fieldNames.EMAIL}Error`}
                        errors={errors.email}
                        errorMessages={email.errorMessages}
                    />
                </div>
            </div>
            <div>
                <label
                    htmlFor={fieldNames.HAIR_TYPE}
                    className={`field-label ${getLabelStyle(fieldNames.HAIR_TYPE)}`}
                >
                    {hairType.label}
                </label>
                <div className="field-wrapper">
                    <Select
                        fieldName={fieldNames.HAIR_TYPE}
                        fieldData={hairType}
                        options={hairType.options}
                        onFieldChange={onFieldChange}
                        onFieldBlur={onFieldBlur}
                        errors={errors.hairType}
                        required={isRequired(fieldNames.HAIR_TYPE)}
                    />
                </div>
            </div>
            <div>
                <div className="field-label" />
                <div className="field-wrapper">
                    <Checkbox
                        onChange={checked => onFieldChange({ target: { name: fieldNames.TOS, value: checked } })}
                        hasError={Boolean(getFieldError(errors.tos))}
                        required={isRequired(fieldNames.TOS)}
                    />
                    <ErrorMessage errors={errors.tos} errorMessages={tos.errorMessages} />
                </div>
            </div>
        </div>
    );
};

Review.propTypes = {
    errors: PropTypes.object,
    fieldLabels: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default Review;
