import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReviewAccessibility from './ReviewAccessibility';
import ErrorMessage from '../ErrorMessage';

import { getFieldError, isRequired, getLabelStyle } from '../WriteReview.helper';
import { fieldNames } from '../WriteReview.config';

const INPUT_MAX_CHAR = 50;
const TEXTAREA_MAX_CHAR = 9999;

const Review = ({ fieldLabels, onChange, onBlur, errors }) => {
    const { title, reviewSummary, review } = fieldLabels;

    const [inputCharLeft, setInputCharLeft] = useState(INPUT_MAX_CHAR);
    const [textareaCharLeft, setTextareaCharLeft] = useState(TEXTAREA_MAX_CHAR);
    const [textareaCharCount, setTextareaCharCount] = useState(0);

    const onFieldChange = (e) => {
        const { name, value } = e.target;

        if (name === 'reviewSummary') setInputCharLeft(INPUT_MAX_CHAR - value.length);
        if (name === 'review') {
            setTextareaCharLeft(TEXTAREA_MAX_CHAR - value.length);
            setTextareaCharCount(value.length);
        }

        onChange({
            field: name,
            value,
        });
    };

    const onFieldBlur = (e) => {
        onBlur({ field: e.target.name });
    };

    const reviewSummaryClassName = getFieldError(errors.reviewSummary) ? 'input-error' : '';
    const reviewClassName = getFieldError(errors.reveiw) ? 'input-error' : '';

    return (
        <div className="review">
            <h2>{title}</h2>
            <div>
                <label
                    htmlFor="reviewSummary"
                    className={`field-label ${getLabelStyle(fieldNames.REVIEW_SUMMARY)}`}
                >
                    {reviewSummary.label}
                </label>
                <div>
                    <input
                        type="text"
                        name="reviewSummary"
                        id={fieldNames.REVIEW_SUMMARY}
                        onChange={onFieldChange}
                        onBlur={onFieldBlur}
                        className={reviewSummaryClassName}
                        autoComplete="on"
                        aria-describedby={`${fieldNames.REVIEW_SUMMARY}Help ${fieldNames.REVIEW_SUMMARY}Error`}
                        aria-required={isRequired('reviewSummary') ? 'true' : 'false'}
                        aria-invalid={getFieldError(errors.reviewSummary) ? 'true' : 'false'}
                        maxLength={INPUT_MAX_CHAR}
                    />
                    <p id={`${fieldNames.REVIEW_SUMMARY}Help`} className="field-info">{reviewSummary.info}</p>
                    <ReviewAccessibility
                        inputType="input"
                        inputCharLeft={inputCharLeft}
                    />
                    <ErrorMessage
                        id={`${fieldNames.REVIEW_SUMMARY}Error`}
                        errors={errors.reviewSummary}
                        errorMessages={reviewSummary.errorMessages}
                    />
                </div>
            </div>
            <div>
                <label
                    htmlFor="review"
                    className={`field-label ${getLabelStyle(fieldNames.REVIEW)}`}
                >
                    {review.label}
                </label>
                <div>
                    <textarea
                        name="review"
                        id={fieldNames.REVIEW}
                        onChange={onFieldChange}
                        onBlur={onFieldBlur}
                        className={reviewClassName}
                        rows="6"
                        autoComplete={review.label}
                        aria-describedby={`${fieldNames.REVIEW}Help ${fieldNames.REVIEW}Error`}
                        aria-required={isRequired('review') ? 'true' : 'false'}
                        aria-invalid={getFieldError(errors.reveiw) ? 'true' : 'false'}
                        maxLength={TEXTAREA_MAX_CHAR}
                    />
                    <p id={`${fieldNames.REVIEW}Help`} className="field-info">{review.info}</p>
                    <ReviewAccessibility
                        inputType="textarea"
                        textareaCharCount={textareaCharCount}
                        textareaCharLeft={textareaCharLeft}
                    />
                    <ErrorMessage
                        id={`${fieldNames.REVIEW}Error`}
                        errors={errors.review}
                        errorMessages={review.errorMessages}
                    />
                </div>
            </div>
        </div>
    );
};

Review.propTypes = {
    errors: PropTypes.object,
    fieldLabels: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default Review;
