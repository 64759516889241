import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Script from 'next/script';

const buildCatalogProducts = (data, pageInfo, domain, brandName) => {
    const productImageURL = get(pageInfo, 'featuredImage.cfmedia.url', '');

    return data.items.map((bvData) => {
        const catalog = {
            productId: bvData.productId,
            productName: bvData.productName,
            brandName,
            productImageURL,
            productPageURL: `${domain}${pageInfo.url}`,
            eans: bvData.eans
                ? bvData.eans.split(',').map((item) => item.trim())
                : [],
        };
        if (bvData.family) catalog.family = bvData.family;

        return catalog;
    });
};

const generateDccScript = (dccObject) => {
    const isMulti = dccObject.catalogData.catalogProducts.length > 1;

    const dccScript = isMulti
        ? `window.bvDCC = ${JSON.stringify(dccObject, null, 2)};

        window.bvCallback = function (BV) {
            for(var i=0, len=window.bvDCC.catalogData.catalogProducts.length; i < len; ++i){
                BV.pixel.trackEvent("CatalogUpdate", {
                    type: 'Product',
                    locale: window.bvDCC.catalogData.locale,
                    catalogProducts: [ window.bvDCC.catalogData.catalogProducts[i] ]
                });
            }
        };`
        : `window.bvDCC = ${JSON.stringify(dccObject, null, 2)};

        window.bvCallback = function (BV) {
            BV.pixel.trackEvent("CatalogUpdate", {
                type: 'Product',
                locale: window.bvDCC.catalogData.locale,
                catalogProducts: window.bvDCC.catalogData.catalogProducts
            });
        };`;

    return dccScript;
};

const BazaarDccHead = (props) => {
    const { pageInfo, bvDccData, domain, brandName, locale } = props;
    if (!bvDccData || !bvDccData.items || bvDccData.items.length === 0) {
        return null;
    }

    const catalogProducts = buildCatalogProducts(
        bvDccData,
        pageInfo,
        domain,
        brandName,
        locale,
    );

    const dccObject = {
        catalogData: {
            locale,
            catalogProducts,
        },
    };

    const dccScriptStr = generateDccScript(dccObject);

    return (
        <React.Fragment>
            <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{ __html: dccScriptStr }}
            />
        </React.Fragment>
    );
};

export default BazaarDccHead;

BazaarDccHead.propTypes = {
    pageInfo: PropTypes.object.isRequired,
    bvDccData: PropTypes.object,
    domain: PropTypes.string,
    brandName: PropTypes.string,
    locale: PropTypes.string,
};
