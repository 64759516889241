import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import Head from 'next/head';
import rehypeRaw from 'rehype-raw';
import { buildProduct } from './ProductHeader.helper';
import { getSeoSchemaProducts } from '../Scripts';
import { InternalLink } from '../UI';
import ProductImages from './ProductImages/ProductImages';
import ProductVariants from './ProductVariants/ProductVariants';
import BuyNowButton from '../BuyNowButton/BuyNowButton';
import HeaderActions from './HeaderActions';
import useScreenSize from '../Hooks/useScreenSize';
import { bvTrackInView, bvTrackViewedCGC } from './trackView.helper';
import bvPixel from '../../constants/bvPixel.constants';
import bvTrackPageView from '../Reviews/trackPageView.helper';

const ProductHeader = (props) => {
    const { name, tag, description, ingredientsLink, productVariants, msrp, shareButton, bvData, currentPageUrl, ariaLabel } = props;
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);
    const product = buildProduct(productVariants, selectedVariantIndex);
    const onProductVariantChange = (selectedIndex) => {
        setSelectedVariantIndex(selectedIndex);
    };
    const productId = bvData && bvData.productId;

    const seoSchemaProducts = productVariants
        && getSeoSchemaProducts(productVariants, currentPageUrl, name, description, process.env.DOMAIN_NAME);

    const { bvProduct, brand } = bvPixel;
    const resolution = useScreenSize();
    const isMobile = resolution?.isHandheld;

    const trackViewData = {
        productId,
        bvProduct,
        brand,
    };
    useEffect(() => {
        if (process.browser && window.BV && window.BV.pixel) {
            bvTrackInView(trackViewData);
            bvTrackViewedCGC(trackViewData);
            bvTrackPageView(productId);
        }
    }, []);

    return (
        <>
            <Head>
                {seoSchemaProducts}
            </Head>
            <div className="product-header" aria-label={ariaLabel}>
                <div className="content">
                    {
                        isMobile && (
                            <div className="product-heading mobile">
                                <HeaderActions
                                    tag={tag}
                                    pageUrl={currentPageUrl}
                                    productName={name}
                                    shareButton={shareButton}
                                    isMobile={true}
                                    gtin={product.gtin}
                                />
                            </div>
                        )
                    }
                    <ProductImages images={product.images} />
                    <div className="product-info">
                        <div className="product-heading">
                            <HeaderActions
                                tag={tag}
                                pageUrl={currentPageUrl}
                                productName={name}
                                shareButton={shareButton}
                                isMobile={false}
                                gtin={product.gtin}
                            />
                        </div>
                        <ReactMarkdown className="description" rehypePlugins={[rehypeRaw]}>
                            {description}
                        </ReactMarkdown>
                        <div className="ingredients-smartlabel">
                            <InternalLink link={ingredientsLink} />
                        </div>
                        <div className="retailers">
                            <BuyNowButton
                                selectedVariantIndex={selectedVariantIndex}
                                name={name}
                                variants={productVariants}
                                sku={product.gtin}
                            />
                            <ProductVariants
                                selectedVariantIndex={selectedVariantIndex}
                                name={name}
                                variants={productVariants}
                                onChange={onProductVariantChange}
                                msrp={msrp}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

ProductHeader.propTypes = {
    bvData: PropTypes.object,
    currentPageUrl: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    ingredientsLink: PropTypes.object,
    msrp: PropTypes.string,
    name: PropTypes.string,
    productVariants: PropTypes.object,
    shareButton: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ProductHeader;
