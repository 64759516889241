import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import LazyLoad from 'react-lazyload';
import { InternalLink } from '../UI';
import NixedIngredientsIcon from './NixedIngredientsIcon';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const NixedIngredients = (props) => {
    const { title, titleAlignment, nixedIngredientsLeftColumn, nixedIngredientsRightColumn, iconsCollection, linkedPage, ariaLabel } = props;
    const backgroundImage = props.backgroundImage && `url(${getCdnImage(props.backgroundImage.cfmedia.url)})`;

    const titleStyle = { textAlign: titleAlignment };

    const Icons = iconsCollection.items.map((icon, i) => {
        const iconTitle = icon && icon.image.alt;
        const iconName = icon && icon.image.name;
        const iconLink = icon && icon.nixedInternalLink;

        return (
            icon && icon.nixedInternalLink
                ? (
                    <InternalLink link={iconLink}>
                        <NixedIngredientsIcon
                            key={i}
                            iconTitle={iconTitle}
                            imageUrl={icon && icon.image.cfmedia}
                            iconLabel={iconName}
                        />
                    </InternalLink>
                )
                : (
                    <NixedIngredientsIcon
                        key={i}
                        iconTitle={iconTitle}
                        imageUrl={icon && icon.image.cfmedia}
                        iconLabel={iconName}
                    />
                )
        );
    });

    return (
        <LazyLoad offset={30}>
            <div
                className="nixed-ingredients-wrapper"
                style={{ backgroundImage }}
                suppressHydrationWarning={true}
                aria-label={ariaLabel}
            >
                <div className="nixed-ingredients">
                    <div className="text-wrapper">
                        <ReactMarkdown style={titleStyle} rehypePlugins={[rehypeRaw]}>
                            {title}
                        </ReactMarkdown>
                        <ReactMarkdown className="left-block" rehypePlugins={[rehypeRaw]}>
                            {nixedIngredientsLeftColumn}
                        </ReactMarkdown>
                        <ReactMarkdown className="right-block" rehypePlugins={[rehypeRaw]}>
                            {nixedIngredientsRightColumn}
                        </ReactMarkdown>
                    </div>
                    <div className="ingredients-icons">{Icons}</div>
                    <div className="read-more-wrapper">
                        <InternalLink link={linkedPage} className="read-more" />
                    </div>
                </div>
            </div>
        </LazyLoad>
    );
};

NixedIngredients.propTypes = {
    backgroundImage: PropTypes.object,
    title: PropTypes.string,
    titleAlignment: PropTypes.string.isRequired,
    nixedIngredientsLeftColumn: PropTypes.string,
    nixedIngredientsRightColumn: PropTypes.string,
    iconsCollection: PropTypes.object,
    linkedPage: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default NixedIngredients;
