module.exports = `{
    productListingCollection(where: {name: "Product Listing: All Products"}, limit:1) {
      items {
        name
        collections: collectionsCollection {
          items {
            label
            page {
              url
            }
          }
        }
      }
    }
  }`;
