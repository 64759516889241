import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { RatingStars, Img } from '../../UI';

import useImageFormat from '../../Hooks/useImageFormat';
import useProductRatings from '../../Hooks/useProductRatings';

const Header = ({ title, product }) => {
    const image = useImageFormat(product.featuredImage);

    const ratingLimit = 10;
    const ratings = useProductRatings({ items: [product] }, ratingLimit);
    const productId = product.bvData && product.bvData.productId;

    return (
        <div className="write-review-header">
            <div className="main-title" alt="">
                <h2>{title}</h2>
            </div>
            <div className="product-info">
                <div className="title-and-rating">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {product.name}
                    </ReactMarkdown>
                    <RatingStars averageRating={ratings[productId]} showRatingsLabel={true} starDimension="20px" />
                </div>
                <div>
                    <Img src={image.url} alt={image.alt} />
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    product: PropTypes.object,
    title: PropTypes.string,
};

export default Header;
