import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Img } from '../UI';

const ProductStrengthsContainer = ({ productStrengthCollection, title, ariaLabel }) => {
    const ProductStrength = productStrengthCollection.items.map((item, i) => {
        const { text, image } = item;

        return (
            <div className="product-strength" key={i}>
                <Img contentfulImage={image.cfmedia} />
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {text}
                </ReactMarkdown>
            </div>
        );
    });

    return (
        <div className="product-strengths-section" aria-label={ariaLabel}>
            <ReactMarkdown className="product-strengths-title" rehypePlugins={[rehypeRaw]}>
                {title}
            </ReactMarkdown>
            <div className="product-strengths-container">
                {ProductStrength}
            </div>
        </div>
    );
};

ProductStrengthsContainer.propTypes = {
    title: PropTypes.string,
    productStrengthCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ProductStrengthsContainer;
