module.exports = `
... on Sitemap {
  sitemapTitle
    sitemapContent: sitemapContentCollection(limit: 8) {
      items {
        sitemapBlockTitle
        sitemapBlockContent: sitemapBlockContentCollection(limit: 60) {
          items {
            __typename
            ... on LinkInternal {
              name
              label
              page {
                title
                url
              }
            }
            ... on ContentPlainTextMd {
              content
            }
          }
        }
      }
    }
    leftBackgroundImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    rightBackgroundImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    ariaLabel
  }
`;
