import React from 'react';
import PropTypes from 'prop-types';

const AriaLiveRegion = ({ current, total }) => {
    return (
        <div className="live-region" aria-live="polite" aria-atomic={true}>
            Item {current} of {total}
        </div>
    );
};


AriaLiveRegion.propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
};

export default AriaLiveRegion;
