module.exports = `
... on ErrorPage {
    errorTitle
    leftBackgroundImage {
        cfmedia {
            description
            url
            width
            height
        }    
    }
    rightBackgroundImage {
        cfmedia {
            description
            url
            width
            height
        }    
    }
    image {
        cfmedia {
            description
            url
            width
            height
        }    
    }
    notFoundText
    errorMessage
    linkBlock {
        backgroundImage {
            cfmedia {
                    description
                    url
                    width
                    height
                } 
        }
        contentCollection {
            items {
                page {
                name
                label
                page {
                  nameEn
                  title
                  description
                  url
                }
                }
                backgroundImage {
                    cfmedia {
                        description
                        url
                        width
                        height
                    }
                }
            }
        }
    }
    ariaLabel
}
`;
