import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Image from 'next/image';
import Card from '../card/card';
import { Button, Device } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const LandingPageCardCarousel = (props) => {
    const { cardsCollection, styles } = props;
    const [slideNumber, setSlideNumber] = useState(0);
    useEffect(() => {
        setSlideNumber(0);
    }, []);
    const slideback = (e) => {
        e.preventDefault();
        if (slideNumber > 0) {
            setSlideNumber(slideNumber - 1);
        } else if (slideNumber === 0) {
            setSlideNumber(cardsCollection.items.length - 1);
        }
    };
    const slidenext = (e) => {
        e.preventDefault();
        if (slideNumber < cardsCollection.items.length - 1) {
            setSlideNumber(slideNumber + 1);
        } else if (slideNumber === cardsCollection.items.length - 1) {
            setSlideNumber(0);
        }
    };
    return (
        <React.Fragment>
            <Device.Desktop>
                <div className="landingPageCardContainer">
                    <div className={styles}>
                        {cardsCollection?.items.map((card) => {
                            return (
                                <LazyLoad offset={20}>
                                    <Card {...card} />
                                </LazyLoad>
                            );
                        })}
                    </div>
                </div>
            </Device.Desktop>
            <Device.Mobile>
                <div className="landingPageCardContainerMobile">
                    <div className={`${styles}mobile`}>
                        <Card {...cardsCollection?.items[slideNumber]} />
                        <div className="slideChanger">
                            <Button className="slick-prev circled-arrow" onClick={(e) => slideback(e)} />
                            <ul className="slide-dots">
                                {cardsCollection?.items.map((card, index) => {
                                    return (
                                        index === slideNumber ? <li><Image alt="Ellipse_81" src={getCdnImage('https://images.ctfassets.net/xq8n4mwvbz58/35v3g7i7HIZvoLI6dZnkWA/32fb32ca2964e15751a77810e7ec2e36/Ellipse_81.png?h=250')} width={12} height={12} /></li>
                                            : <li><Image onClick={() => { setSlideNumber(index); }} alt="Ellipse_82" src={getCdnImage('https://images.ctfassets.net/xq8n4mwvbz58/4paZ9lRallttwKyzImzPcR/01b29419c0825fc98023aeed97bf489c/Ellipse_82.png?h=250')} width={12} height={12} /></li>
                                    );
                                })}
                            </ul>
                            <Button className="slick-next circled-arrow" onClick={(e) => slidenext(e)} />
                        </div>
                    </div>
                </div>
            </Device.Mobile>
            <Device.Tablet>
                <div className="landingPageCardContainerTablet">
                    <div className={`${styles}Tablet`}>
                        <Card {...cardsCollection?.items[slideNumber]} />
                        <div className="slideChanger">
                            <Button className="slick-prev circled-arrow" onClick={(e) => slideback(e)} />
                            <ul className="slide-dots">
                                {cardsCollection?.items.map((card, index) => {
                                    return (
                                        index === slideNumber ? <li><Image alt="Ellipse_81" src={getCdnImage('https://images.ctfassets.net/xq8n4mwvbz58/35v3g7i7HIZvoLI6dZnkWA/32fb32ca2964e15751a77810e7ec2e36/Ellipse_81.png?h=250')} width={12} height={12} /></li>
                                            : <li><Image onClick={() => { setSlideNumber(index); }} alt="Ellipse_82" src={getCdnImage('https://images.ctfassets.net/xq8n4mwvbz58/4paZ9lRallttwKyzImzPcR/01b29419c0825fc98023aeed97bf489c/Ellipse_82.png?h=250')} height={12} width={12} /></li>
                                    );
                                })}
                            </ul>
                            <Button className="slick-next circled-arrow" onClick={(e) => slidenext(e)} />
                        </div>
                    </div>
                </div>
            </Device.Tablet>
        </React.Fragment>
    );
};

LandingPageCardCarousel.propTypes = {
    cardsCollection: PropTypes.object,
    styles: PropTypes.string,
};

export default LandingPageCardCarousel;
