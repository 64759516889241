module.exports = `
... on GlossaryTable {
    glossaryTableTitle

    tableHeadings: glossaryTableHeadersCollection (limit: 500) {
        items {
            ingredientName
            purpose
            description
        }
    }

    tableRows: glossaryTableRowsCollection (limit: 500) {
        items {
            ingredientName
            purpose
            description
        }
    }
}
`;
