import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import get from 'lodash/get';
import LazyLoad from 'react-lazyload';
import useScreenSize from '../Hooks/useScreenSize';
import { settingsDesktop } from './slickSettingsDesktop.const';
import { settingsHandHeld } from './slickSettingsHandHeld.const';
import { AriaLiveRegion, InternalLink, Img } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

function IngredientsCarousel({ leftImage, rightImage, slidesCollection, ariaLabel }) {
    const imgL = leftImage && getCdnImage(leftImage.cfmedia.url);
    const imgR = rightImage && getCdnImage(rightImage.cfmedia.url);
    const allSlides = get(slidesCollection, 'items', []);
    const backgroundImage = `url(${imgL}), url(${imgR})`;
    const resolution = useScreenSize();

    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesLength = allSlides.length;

    const desktopSettings = {
        ...settingsDesktop,
        afterChange: setCurrentSlide,
    };

    const handHeldSettings = {
        ...settingsHandHeld,
        afterChange: setCurrentSlide,
    };

    const slides = allSlides.map((slide, index) => {
        const { title } = slide;

        return (
            <div className="slide" key={index}>
                <InternalLink link={slide}>
                    <Img
                        className="image-slide"
                        contentfulImage={slide.cloudinaryImage.cfmedia}
                        alt={`${slide.name} Image`}
                    />
                    <div className="text-slide">
                        <p>{title}</p>
                    </div>
                </InternalLink>
            </div>
        );
    });

    const renderSlides = () => (
        <>
            {resolution && resolution.isDesktop && <Slider {...desktopSettings}>{slides}</Slider>}
            {resolution && resolution.isHandheld && <Slider {...handHeldSettings}>{slides}</Slider>}
        </>

    );

    return (
        <LazyLoad offset={20}>
            <div className="ingredients-carousel-container" style={{ backgroundImage }} suppressHydrationWarning={true} aria-label={ariaLabel}>
                <div className="ingredients-carousel">
                    {renderSlides()}
                    <AriaLiveRegion current={currentSlide + 1} total={slidesLength} />
                </div>
            </div>
        </LazyLoad>
    );
}

IngredientsCarousel.propTypes = {
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    slidesCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default IngredientsCarousel;
