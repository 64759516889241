import React from 'react';
import PropTypes from 'prop-types';

const ReviewsFilter = ({ handleFilter }) => {
    const filters = ['Most recommended', 'Highest rating', 'Most recent', 'Lowest rating'];

    const onOptChange = (event) => {
        handleFilter(event.target.value);
    };

    const renderDropdown = () => {
        return filters.map((opt, index) => {
            return (
                <option value={opt} key={index}>{opt}</option>
            );
        });
    };

    return (
        <div className="sort-container">
            <label htmlFor="sort">Sort by:</label>
            <select id="sort" className="sort-review-select" onChange={onOptChange}>
                {renderDropdown()}
            </select>
        </div>

    );
};

ReviewsFilter.propTypes = {
    handleFilter: PropTypes.func,
};

export default ReviewsFilter;
