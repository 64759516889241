import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import BlockWrapper from '../BlockWrapper/BlockWrapper';
import BlockWrapperWithImage from '../BlockWrapperWithImage/BlockWrapperWithImage';
import { MDLinkRenderer, Img } from '../UI';

const TableInfoBlock = ({ content, wrapper, ariaLabel }) => {
    const { items } = content;
    const tables = chunk(items, 2);
    let Wrapper;
    let mainClass = 'table-info-block';

    if (!wrapper) {
        Wrapper = ({ children }) => <div className="no-wrapper">{children}</div>;
    } else {
        Wrapper = wrapper.__typename === 'BlockWrapper' ? BlockWrapper : BlockWrapperWithImage;
        mainClass = `${mainClass}${wrapper.topImage ? ' pulled' : ''}`;
    }

    const getTitleClass = (title = '') => title.length < 20 ? 'short' : '';

    const renderTable = (table, i) => {
        const [t1, t2] = table;

        return (
            <div className="resp-table" key={i}>
                <div className={`resp-td title-cell left ${getTitleClass(t1.title)}`}><h2>{t1.title}</h2></div>
                <div className="resp-td image-cell left">
                    <Img contentfulImage={t1.image.cfmedia} alt={t1.image.alt} />
                </div>
                <div className="resp-td text-cell left">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} components={{ link: MDLinkRenderer }} key={i}>
                        {t1.text}
                    </ReactMarkdown>
                </div>
                <div className={`resp-td title-cell right ${getTitleClass(t2 && t2.title)}`}><h2>{t2 && t2.title}</h2></div>
                <div className="resp-td image-cell right">
                    <Img contentfulImage={t2 && t2.image.cfmedia} alt={t2 && t2.image.alt} />
                </div>
                <div className="resp-td text-cell right">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} components={{ link: MDLinkRenderer }} key={i}>
                        {t2 && t2.text}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    return (
        <Wrapper {...wrapper}>
            <div className={mainClass} aria-label={ariaLabel}>
                {tables.map(renderTable)}
            </div>
        </Wrapper>
    );
};

TableInfoBlock.propTypes = {
    content: PropTypes.object,
    wrapper: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default TableInfoBlock;
