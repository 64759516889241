import React from 'react';
import PropTypes from 'prop-types';

const ActiveFilters = ({ filters, onFilterRemove }) => {
    if (!filters) {
        return null;
    }

    const keyPress = (e, filter) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onFilterRemove(filter);
        }
    };

    const renderFilter = (filterName) => {
        const filterType = filters[filterName];

        return filterType.map((filter, i) => {
            return (
                <div key={i}>
                    <span
                        className="remove-filter"
                        onClick={() => onFilterRemove(filter)}
                        onKeyPress={(e) => keyPress(e, filter)}
                        tabIndex={0}
                    />
                    {filter.label}
                </div>
            );
        });
    };

    return (
        <div className="active-filters">
            {Object.keys(filters).map(renderFilter)}
        </div>
    );
};

ActiveFilters.propTypes = {
    filters: PropTypes.object,
    onFilterRemove: PropTypes.func,
};

export default ActiveFilters;
