const slashify = (origUrl) => {
    let url = origUrl.trim();

    if (url.substr(-1, 1) !== '/') {
        url = `${url}/`;
    }

    return url;
};

export default slashify;
