import React from 'react';

export default (pgdlGtm, pgdlSeoHead, url, domainName) => {
    if ((!pgdlGtm || !pgdlGtm.pgdl) && (!pgdlSeoHead || pgdlSeoHead.items.length === 0)) {
        return <script />;
    }

    let PGDLObject = (pgdlGtm && pgdlGtm.pgdl) ? { ...pgdlGtm.pgdl } : {};

    if (pgdlSeoHead && pgdlSeoHead.items && pgdlSeoHead.items.length > 0) {
        pgdlSeoHead.items.forEach(item => {
            if (item && item.json) {
                let updatedJson = item.json;
                if (item.json.event) {
                    updatedJson = {
                        ...updatedJson,
                        event: updatedJson.event.map(evt => {
                            return {
                                ...evt,
                                label: `${domainName}${url}`,
                            };
                        }),
                    };
                }

                PGDLObject = { ...PGDLObject, ...updatedJson };
            }
        });
    }

    const PGDLObjectJson = JSON.stringify(PGDLObject);

    return (
        <script defer dangerouslySetInnerHTML={{ __html: `var PGdataLayer = ${PGDLObjectJson}` }} />
    );
};
