import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const CarouselArrow = (props) => {
    const { className, onClick } = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const ref = useRef();

    useEffect(() => {
        setIsDisabled(ref.current.className.split(' ').includes('slick-disabled'));
    }, [isDisabled, onClick]);

    const keyPress = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            onClick();
        }
    };

    const ariaLabel = className.indexOf('next') > -1 ? 'Next' : 'Previous';

    return (
        <button
            ref={ref}
            type="button"
            aria-label={ariaLabel}
            data-action-detail={`${ariaLabel} Slide`}
            className={`${className} circled-arrow event_button_click`}
            onClick={onClick}
            onKeyPress={keyPress}
            tabIndex={0}
            disabled={isDisabled}
        />
    );
};

CarouselArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default CarouselArrow;
