import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import labels from '../../../constants/label.constants';
import useClickedOutside from '../../Hooks/useClickedOutside';

const Modal = ({ style, className, children, onClose, isOpen }) => {
    if (!isOpen) {
        return null;
    }

    const modalRef = useRef();

    useClickedOutside(modalRef, onClose);

    const closeWithEnter = (event) => {
        if (event.key === 'Enter') {
            onClose();
        }
    };

    const closeWithEscape = (event) => {
        if (isOpen && event && (event.key === 'Escape' || event.key === 'Esc')) {
            onClose();
        }
    };

    useEffect(() => {
        if (process.browser && isOpen) {
            modalRef.current.focus();
            modalRef.current.onkeydown = closeWithEscape;
        }
    }, [modalRef, isOpen]);


    return (
        <div className="modal">
            <div className="modal-overlay">
                <div className={`modal-content ${className && className}`} style={style} ref={modalRef} tabIndex={0} aria-modal="true">
                    {className === 'filter-modal' ? <span>Filter by:</span> : null}
                    <div role="button" className="close" onClick={onClose} onKeyPress={closeWithEnter} tabIndex={0} aria-label={labels.modal.close} />
                    {children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    style: PropTypes.object,
};

export default Modal;
