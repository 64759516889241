import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ isDisabled, onClick, className, iconClassName, children }) => {
    const buttonClassName = isDisabled ? `${className} disabled` : className;

    const ariaLabel = typeof children === 'string' ? children : '';

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            className={buttonClassName}
            type="button"
            aria-label={ariaLabel}
        >
            <span className={iconClassName} />{children}
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default Button;
