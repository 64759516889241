import { errorRules, validations } from './WriteReview.config';

const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export const validate = (value, { rule, ruleValue }) => {
    switch (rule) {
        case errorRules.REQUIRED:
            if (!value) {
                return errorRules.REQUIRED;
            }
            break;
        case errorRules.MIN_LENGTH:
            if (value && value.length < ruleValue) {
                return errorRules.MIN_LENGTH;
            }
            break;
        case errorRules.MAX_LENGTH:
            if (value && value.length > ruleValue) {
                return errorRules.MAX_LENGTH;
            }
            break;
        case errorRules.VALID_EMAIL:
            if (!emailRegex.test(value)) {
                return errorRules.VALID_EMAIL;
            }
            break;
        default:
            return false;
    }
};

export const getFieldError = (errors) => {
    if (!errors) return null;

    const possibleErrors = Object.keys(errorRules).map(key => errorRules[key]);

    const result = errors.filter(error => possibleErrors.includes(error));

    return result[0] || null;
};

export const getErrors = (fields, allValidations) => {
    const errors = {};

    if (allValidations) {
        Object.keys(allValidations).forEach(key => {
            errors[key] = allValidations[key].map(validation => validate(fields[key], validation)).filter(error => Boolean(error));
        });
    }

    return errors;
};

export const getErrorsAmount = (errors) => {
    return Object.keys(errors).filter(key => errors[key].length > 0).length;
};

export const getPossibleYears = () => {
    const startingYear = 1900;
    const endingYear = (new Date().getFullYear()) - 3;

    const possibleYears = [];
    for (let i = endingYear; i >= startingYear; i--) {
        possibleYears.push(i);
    }

    return possibleYears;
};

export const getPossibleMonths = () => {
    const possibleMonths = [];

    for (let i = 1; i <= 12; i++) {
        possibleMonths.push(i);
    }

    return possibleMonths;
};

export const validateUserAge = (year, month, checkYear) => {
    const now = new Date();
    const maxValidDate = now.setFullYear(now.getFullYear() - checkYear);
    const userDate = new Date(year, (month - 1));

    return userDate.getTime() <= maxValidDate;
};

export const setLocalStorageUnderage = (isUnderage) => {
    if (window !== undefined) {
        window.sessionStorage.setItem('underage', (JSON.stringify(isUnderage)));
    }
};

export const getLocalStorageUnderage = () => {
    if (window !== undefined) {
        return (JSON.parse(window.sessionStorage.getItem('underage')) !== null);
    }
    return false;
};

export const isRequired = (fieldName) =>
    (fieldName && fieldName !== '' && validations[fieldName])
        ? validations[fieldName].some(item => item.rule === 'required')
        : false;

export const getLabelStyle = fieldName => isRequired(fieldName) ? 'required-field' : '';

export const getHairTypeId = (hairType) => {
    switch (hairType) {
        case 'Dry, Damaged Hair':
            return 'DryHair';
        case 'Damaged Hair':
            return 'DamagedHair';
        case 'Fine, Flat Hair':
            return 'FineFlatHair';
        case 'Colored Hair':
            return 'ColoredHair';
        case 'Curly Hair':
            return 'CurlyHair';
        case 'Oily Hair':
            return 'OilyHair';
        case 'Straight Hair':
            return 'StraightHair';
        default: break;
    }
};
