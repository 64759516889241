import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import { InternalLink, Img } from '../UI';

const CollectionBanner = ({ title, image, page, ariaLabel }) => {
    return (
        <div id="collection-banner" className="collection-banner" aria-label={ariaLabel}>
            <InternalLink link={{ url: page ? page.url : '' }} className="collection-banner-element">
                <ReactMarkdown className="collection-banner-title" rehypePlugins={[rehypeRaw]}>
                    {title}
                </ReactMarkdown>
                <Img contentfulImage={image.cfmedia} />
            </InternalLink>
        </div>
    );
};

CollectionBanner.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    page: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default CollectionBanner;
