import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import InternalLink from '../InternalLink/InternalLink';
import ExternalLink from '../ExternalLink/ExternalLink';


const MDLinkRenderer = ({ href, children }) => {
    const outbound = href.indexOf('http') === 0;
    const text = get(children[0], 'props.children[0].props.value', children[0].props.value || '');

    return outbound
        ? <ExternalLink data={{ title: text, url: href }} className="link-md" />
        : <InternalLink link={{ label: text, url: href }} className="link-md" />;
};

MDLinkRenderer.propTypes = {
    href: PropTypes.string,
    children: PropTypes.array,
};

export default MDLinkRenderer;
