import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import AppContext from '../../../context/App.context';
import { buildFiltersWithOptions, removeFilter, updateFilters } from '../../../helpers/filtering';
import ActiveFilters from './ActiveFilters';

import { Button, Checkbox } from '../../UI';

const FiltersMobile = ({ activeFilters, onApply }) => {
    const { appData } = useContext(AppContext);
    const filtersWithOptions = buildFiltersWithOptions(appData.allFilters);

    const [filtersToApply, setFiltersToApply] = useState(activeFilters);
    const [expandedGroup, setExpandedGroup] = useState();

    useEffect(() => {
        setFiltersToApply(activeFilters);
    }, [activeFilters]);

    const onFilterChange = (selectedFilter) => {
        const updatedFilters = updateFilters(selectedFilter, filtersToApply);
        setFiltersToApply(updatedFilters);
    };

    const renderOption = (option, i) => {
        const { filterName, label } = option;
        const isSelected = Boolean(get(filtersToApply, filterName, []).find(filter => filter.label === label));

        return (
            <div className="option" key={i}>
                <Checkbox checked={isSelected} label={option.label} onChange={() => onFilterChange(option)} />
            </div>
        );
    };

    const onKeyPress = (e, index) => {
        if (e.key === 'Enter' || e.key === ' ') {
            setExpandedGroup(index);
        }
    };

    const renderFilter = (filter, index) => {
        const hasSelectedItems = Boolean(filtersToApply[filter.filterName]);
        const groupClassName = `filter-type filter-${index} ${hasSelectedItems ? 'active' : ''}`;
        const optionsClassName = index === expandedGroup ? 'mobile-filter-options' : 'mobile-filter-options hidden';

        return (
            <>
                <div
                    role="button"
                    aria-expanded={index === expandedGroup}
                    className={groupClassName}
                    onClick={() => setExpandedGroup(index)}
                    onKeyPress={(e) => onKeyPress(e, index)}
                    tabIndex={0}
                >
                    {filter.filterName}
                </div>
                <div className={optionsClassName}>
                    {index === expandedGroup && filter.options.map(renderOption)}
                </div>
            </>
        );
    };

    const resetAllFilters = () => {
        setFiltersToApply({});
        setExpandedGroup(null);
    };

    const onFilterRemove = (filter) => {
        const updatedFilters = removeFilter(filter, filtersToApply);
        setFiltersToApply(updatedFilters);
    };

    return (
        <div className="filters">
            {filtersWithOptions.map((filter, index) => renderFilter(filter, index))}
            <ActiveFilters
                filters={filtersToApply}
                onFilterRemove={onFilterRemove}
                onFiltersReset={resetAllFilters}
            />
            <Button
                onClick={resetAllFilters}
                className="filters-reset"
            >
                Reset All Filters
            </Button>
            <Button
                onClick={() => onApply(filtersToApply)}
                className="apply-button"
            >
                Apply
            </Button>
        </div>
    );
};

FiltersMobile.propTypes = {
    activeFilters: PropTypes.object.isRequired,
    onApply: PropTypes.func.isRequired,
};

export default FiltersMobile;
