import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import LazyLoad from 'react-lazyload';
import Accordion from '../Accordion/Accordion';
import { MDLinkRenderer } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const NixedAccordionHolder = (props) => {
    const { title, accordion, footnote, image, ariaLabel } = props;

    const backgroundImage = `url(${getCdnImage(image.cfmedia.url)})`;

    return (
        <LazyLoad offset={30}>
            <div className="nixed-accordion-holder" style={{ backgroundImage }} suppressHydrationWarning={true} aria-label={ariaLabel}>
                <ReactMarkdown
                    className="title-block"
                    rehypePlugins={[rehypeRaw]}
                    components={{ link: MDLinkRenderer }}
                >
                    {title}
                </ReactMarkdown>
                <div className="accordion-wrapper">
                    <Accordion items={accordion.items} />
                </div>
                <div className="footnote">{footnote}</div>
            </div>
        </LazyLoad>
    );
};

NixedAccordionHolder.propTypes = {
    title: PropTypes.string,
    accordion: PropTypes.object,
    footnote: PropTypes.string,
    image: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default NixedAccordionHolder;
