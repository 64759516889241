import React from 'react';
import { CarouselArrow } from '../UI';

export const importedSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    customPaging: i => <button type="button" aria-label={`Carousel page ${i + 1}`} />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
