module.exports = `
... on ProductStrengthsContainer {
    title
    productStrengthCollection {
        items {
            __typename
            ... on ProductStrength {
                text
                image {
                  name
                  cfmedia {
                    url
                    width
                    height
                    description
                  }
                }
            }
        }
    }
    ariaLabel
  }
`;
