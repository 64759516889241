import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    EmailShareButton,
} from 'react-share';
import { shareButton } from '../../../constants/label.constants';
import { FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon } from './Icons';
import useClickedOutside from '../../Hooks/useClickedOutside';

const ShareButton = ({ buttonLabel, hashtag, message, title, url, channels = [] }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const buttonRef = useRef();

    useClickedOutside(buttonRef, () => setTooltipVisible(false));

    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible);
    };

    const toggleWithEnter = (event) => {
        if (event.key === 'Enter') {
            toggleTooltip();
        }
    };

    const closeWithEscape = (event) => {
        if (event && (event.key === 'Escape' || event.key === 'Esc')) {
            setTooltipVisible(false);
        }
    };

    useEffect(() => {
        if (process.browser) {
            buttonRef.current.onkeydown = closeWithEscape;
        }
    }, []);

    const renderTwitter = () => (
        <TwitterShareButton
            className="event_socialmedia_share"
            data-action-detail={`${url}, ${shareButton.twitter.ariaLabel}`}
            url={url}
            title={title}
            key={shareButton.twitter.key}
            aria-label={shareButton.twitter.ariaLabel}
            aria-describedby={shareButton.twitter.ariaDescribedby}
            tabIndex={0}
        >
            <TwitterIcon />
            <div hidden>
                <span>{shareButton.description}</span>
            </div>
        </TwitterShareButton>
    );

    const renderFacebook = () => (
        <FacebookShareButton
            className="event_socialmedia_share"
            data-action-detail={`${url}, ${shareButton.facebook.ariaLabel}`}
            url={url}
            quote={message}
            hashtag={hashtag}
            key={shareButton.facebook.key}
            aria-label={shareButton.facebook.ariaLabel}
            aria-describedby={shareButton.facebook.ariaDescribedby}
            tabIndex={0}
        >
            <FacebookIcon />
            <div hidden>
                <span>{shareButton.description}</span>
            </div>
        </FacebookShareButton>
    );

    const renderLinkedin = () => (
        <LinkedinShareButton
            className="event_socialmedia_share"
            data-action-detail={`${url}, ${shareButton.linkedIn.ariaLabel}`}
            url={url}
            title={title}
            summary={message}
            key={shareButton.linkedIn.key}
            aria-label={shareButton.linkedIn.ariaLabel}
            aria-describedby={shareButton.linkedIn.ariaDescribedby}
            tabIndex={0}
        >
            <LinkedinIcon />
            <div hidden>
                <span>{shareButton.description}</span>
            </div>
        </LinkedinShareButton>
    );

    const renderEmail = () => (
        <EmailShareButton
            className="event_socialmedia_share"
            data-action-detail={`${url}, ${shareButton.email.key}`}
            url={url}
            subject={title}
            body={message}
            key={shareButton.email.key}
            aria-label={shareButton.email.ariaLabel}
            tabIndex={0}
        >
            <EmailIcon />
        </EmailShareButton>
    );

    const renderChannel = (channel) => {
        const channelToRender = channel.toLowerCase();

        switch (channelToRender) {
            case 'email':
                return renderEmail();
            case 'linkedin':
                return renderLinkedin();
            case 'facebook':
                return renderFacebook();
            case 'twitter':
                return renderTwitter();
            default:
                return null;
        }
    };

    const tooltipClassName = tooltipVisible ? 'tooltip' : 'tooltip hidden';

    return (
        <div
            className="share-button"
            aria-label={shareButton.ariaLabel}
            ref={buttonRef}
            tabIndex={0}
            onKeyPress={toggleWithEnter}
            onClick={toggleTooltip}
        >
            <div className={tooltipClassName}>
                <div className="before" />
                <div className="block">
                    {channels.map(renderChannel)}
                </div>
                <div className="after" />
            </div>
            <span className="button" role="button">{buttonLabel}</span>
        </div>
    );
};

ShareButton.propTypes = {
    buttonLabel: PropTypes.string,
    channels: PropTypes.array,
    hashtag: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
};

export default ShareButton;
