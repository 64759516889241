import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import useImageFormat from '../Hooks/useImageFormat';

import useAlgolia from '../Hooks/useAlgolia';
import { getNumberOfResults, buildMessage, getSearchTerm } from './SearchResults.helper';

import { ButtonTab } from '../UI';
import ProductResults from './ProductResults';
import ContentResults from './ContentResults';

import '../ProductListing/Products/Products';

const SearchResults = ({ resultsMessage, noResultsMessage, errorMessage, loadingMessage, leftImage, rightImage }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const searchTerm = getSearchTerm();

    const { isLoading, isError, results } = useAlgolia(searchTerm);

    const products = get(results, 'products') || [];
    const content = get(results, 'content') || [];

    const backgroundImage = `url('${useImageFormat(leftImage).url}'), url('${useImageFormat(rightImage).url}')`;

    const Tabs = () => {
        const productsButtonClassName = selectedTabIndex === 0 ? 'active event_button_click' : 'event_button_click';
        const contentButtonClassName = selectedTabIndex === 1 ? 'active event_button_click' : 'event_button_click';

        return (
            <div role="tablist" className="tabs">
                <ButtonTab
                    onClick={() => setSelectedTabIndex(0)}
                    className={productsButtonClassName}
                    isActive={(selectedTabIndex === 0)}
                    controlsContent="search-products"
                    dataActionDetail="Product Results"
                >
                    Product results ({getNumberOfResults(products)})
                </ButtonTab>
                <ButtonTab
                    onClick={() => setSelectedTabIndex(1)}
                    className={contentButtonClassName}
                    isActive={(selectedTabIndex === 1)}
                    controlsContent="search-content"
                    dataActionDetail="Content Results"
                >
                    Content results ({getNumberOfResults(content)})
                </ButtonTab>
            </div>
        );
    };

    const Message = () => {
        /* see comment in SearchResults.helper */
        const cleanSearchTerm = searchTerm.replace(/cürl/ig, 'curl');

        if (isError) {
            return buildMessage(errorMessage, cleanSearchTerm);
        }

        if (isLoading) {
            return buildMessage(loadingMessage, cleanSearchTerm);
        }

        if (selectedTabIndex === 0 && (!products || products.length < 1)) {
            return buildMessage(noResultsMessage, cleanSearchTerm);
        }

        if (selectedTabIndex === 1 && (!content || content.length < 1)) {
            return buildMessage(noResultsMessage, cleanSearchTerm);
        }

        return buildMessage(resultsMessage, cleanSearchTerm);
    };

    return (
        <div className="search-results" style={{ backgroundImage }} suppressHydrationWarning={true}>
            <div className="content">
                <h1><Message /></h1>
                <Tabs />
                {!isLoading && !isError && (
                    <>
                        <ProductResults results={products} visible={selectedTabIndex === 0} />
                        <ContentResults results={content} visible={selectedTabIndex === 1} />
                    </>
                )}
            </div>
        </div>
    );
};

SearchResults.propTypes = {
    errorMessage: PropTypes.string,
    loadingMessage: PropTypes.string,
    noResultsMessage: PropTypes.string,
    resultsMessage: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
};

export default SearchResults;
