import axios from 'axios';

export default {
    getRating(productId, offset = 0, sort = 'TotalPositiveFeedbackCount:desc') {
        return axios
            .get(
                `${process.env.BAZAARVOICE__API_URL
                }/reviews.json?apiversion=${
                    process.env.BAZAARVOICE__API_VERSION
                }&passkey=${
                    process.env.BAZAARVOICE_API_KEY
                }&Filter=ProductId:${
                    productId
                }&Sort=${
                    sort
                }&Limit=${
                    process.env.BAZAARVOICE_REVIEWS_LIMIT
                }&Offset=${
                    offset
                }&include=Products&Stats=Reviews`,
            )
            .then(response => response.data)
            .catch((error) => {
                console.log('Failed to get reviews :', error);
            });
    },

    getMultipleProductRatings(productIds) {
        return axios
            .get(
                `${process.env.BAZAARVOICE__API_URL
                }/statistics.json?apiversion=${
                    process.env.BAZAARVOICE__API_VERSION
                }&passkey=${
                    process.env.BAZAARVOICE_API_KEY
                }&Filter=ProductId:${
                    productIds
                }&stats=Reviews`,
            )
            .then(response => response.data)
            .catch((error) => {
                console.log('Failed to get reviews :', error);
            });
    },

    submitHelpfulness(vote, contentID) {
        return new Promise((resolve, reject) => {
            const url = `${process.env.BAZAARVOICE__API_URL}`
                + `/submitfeedback.json?ApiVersion=${process.env.BAZAARVOICE__API_VERSION}`
                + `&Passkey=${process.env.BAZAARVOICE_API_KEY}`
                + '&FeedbackType=helpfulness'
                + '&ContentType=review'
                + `&ContentId=${contentID}`
                + `&Vote=${vote}`;
            axios
                .post(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    submitReview(fields, productID, blackBoxString, callbackUrl) {
        return new Promise((resolve, reject) => {
            const url = `${process.env.BAZAARVOICE__API_URL}`
                + `/submitreview.json?ApiVersion=${process.env.BAZAARVOICE__API_VERSION}`
                + `&Passkey=${process.env.BAZAARVOICE_API_KEY}`
                + `&ProductId=${productID}`
                + '&Action=Submit'
                + `&Rating=${fields.overallRating}`
                + `&Title=${encodeURIComponent(fields.reviewSummary)}`
                + `&ReviewText=${encodeURIComponent(fields.review)}`
                + `&contextdatavalue_Year=${+fields.year}`
                + `&contextdatavalue_Month=${+fields.month}`
                + `&UserNickname=${fields.nickname}`
                + `&UserLocation=${fields.location}`
                + `&UserEmail=${fields.email}`
                + `&IsRecommended=${fields.recommend === 'yes'}`
                + `&contextdatavalue_HairType=${fields.hairType}`
                + `&AgreedToTermsAndConditions=${true}`
                + `&SendEmailAlertWhenPublished=${true}`
                + `&SendEmailAlertWhenCommented=${true}`
                + `&fp=${encodeURIComponent(blackBoxString)}`
                + `&HostedAuthentication_AuthenticationEmail=${encodeURIComponent(fields.email)}`
                + `&HostedAuthentication_CallbackURL=${encodeURIComponent(callbackUrl)}`;
            axios
                .post(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    submitPreview(fields, productID, blackBoxString, callbackUrl) {
        return new Promise((resolve, reject) => {
            const url = `${process.env.BAZAARVOICE__API_URL}`
                + `/submitreview.json?ApiVersion=${process.env.BAZAARVOICE__API_VERSION}`
                + `&Passkey=${process.env.BAZAARVOICE_API_KEY}`
                + `&ProductId=${productID}`
                + '&Action=Preview'
                + `&Rating=${fields.overallRating}`
                + `&Title=${encodeURIComponent(fields.reviewSummary)}`
                + `&ReviewText=${encodeURIComponent(fields.review)}`
                + `&contextdatavalue_Year=${+fields.year}`
                + `&contextdatavalue_Month=${+fields.month}`
                + `&UserNickname=${fields.nickname}`
                + `&UserLocation=${fields.location}`
                + `&UserEmail=${fields.email}`
                + `&IsRecommended=${fields.recommend === 'yes'}`
                + `&contextdatavalue_HairType=${fields.hairType}`
                + `&AgreedToTermsAndConditions=${true}`
                + `&SendEmailAlertWhenPublished=${true}`
                + `&SendEmailAlertWhenCommented=${true}`
                + `&fp=${encodeURIComponent(blackBoxString)}`
                + `&HostedAuthentication_AuthenticationEmail=${encodeURIComponent(fields.email)}`
                + `&HostedAuthentication_CallbackURL=${encodeURIComponent(callbackUrl)}`;
            axios
                .post(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
