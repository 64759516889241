import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import useImageFormat from '../Hooks/useImageFormat';
import { InternalLink } from '../UI';

function Sitemap({ sitemapTitle, leftBackgroundImage, rightBackgroundImage, sitemapContent, ariaLabel }) {
    const imgL = useImageFormat(leftBackgroundImage).url;
    const imgR = useImageFormat(rightBackgroundImage).url;

    let sitemapItems = get(sitemapContent, 'items', []);
    const longSitemapItem = sitemapItems.slice(0, 1);
    sitemapItems = sitemapItems.slice(1);
    const backgroundImage = `url(${imgL}), url(${imgR})`;

    const renderSitemapLinks = (sitemapLinks) => (
        sitemapLinks.map((link, index) => (
            <li key={index}>
                {link.__typename === 'LinkInternal' && <InternalLink link={link} />}
                {link.__typename === 'ContentPlainTextMd' && <span>{link.content}</span>}
            </li>
        ))
    );

    const renderSitemapItems = (items) => (
        items.map((sitemap, index) => {
            const { sitemapBlockTitle, sitemapBlockContent } = sitemap;
            const sitemapLinks = sitemapBlockContent.items;

            return (
                <div className={`sitemap-block item-${index}`} key={index}>
                    <div className="sitemap-item">
                        <h2>{sitemapBlockTitle}</h2>
                        <ul>
                            {renderSitemapLinks(sitemapLinks)}
                        </ul>
                    </div>
                </div>
            );
        })
    );

    return (
        <div className="sitemap-container" style={{ backgroundImage }} suppressHydrationWarning={true} aria-label={ariaLabel}>
            <div className="sitemap-inner-container">
                <h1>{sitemapTitle}</h1>
                <div className="sitemap">
                    <div className="long">
                        {renderSitemapItems(longSitemapItem)}
                    </div>
                    <div className="normal-container">
                        <div className="normal">
                            {renderSitemapItems(sitemapItems)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Sitemap.propTypes = {
    sitemapTitle: PropTypes.string,
    leftBackgroundImage: PropTypes.object,
    rightBackgroundImage: PropTypes.object,
    sitemapContent: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default Sitemap;
