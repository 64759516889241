module.exports = `
... on ProductListing {
  name
  leftBgImage {
     cfmedia {
          description
          url
          width
          height
        }
  }
  rightBgImage {
     cfmedia {
          description
          url
          width
          height
        }
  }
  showFilters
  collections: collectionsCollection(limit:40) {
    items {
      __typename
      ... on LinkInternal {
        label
        page {
          url
        }
        openInNewWindow
        icon {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
        mainCollectionCategory
        category           
      }
    }
  }
  products: productsCollection(limit: 145) {
    items {
      ... on ProductHeader {
        __typename
        name
        productBenefit
        filters: filtersCollection(limit: 30) {
          items {
            filterName
            nameEn
            label
          }
        }
        bvData {
          productId
        }
        productvariants: productVariantsCollection(limit: 1){
          items{
            gtin
          }
        }
        seoHead {
          url
          featuredImage {
             cfmedia {
                description
                url
                width
                height
              }
          }
        }
      }

      ... on ProductFilter {
        __typename
        filterName
        label
      }
    }
  }
  ariaLabel
}
`;
