import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import rehypeRaw from 'rehype-raw';
import { ExternalLink } from '../UI';
import { countrySelector } from '../../constants/label.constants';

const CountrySelector = ({ countries, open, close, toggleOpen, isOpen }) => {
    const { title, firstRegion, secondRegion, thirdRegion, fourthRegion } = get(countries, 'items[0]', {});
    const [outOfViewport, setIsOutOfViewPort] = useState();
    const [currentLocalization, setCurrentLocalization] = useState('México');
    const selectorRef = useRef();

    const LiRenderer = ({ children }) => {
        const link = {
            url: get(children[0], 'props.href', ''),
            label: get(children[0], 'props.children[0].props.value', ''),
            openInNewWindow: true,
        };

        const className = link.label === currentLocalization ? 'current' : null;

        return (
            <li className={className}>
                <ExternalLink
                    toggleCountrySelector={toggleOpen}
                    setCurrentLocalization={setCurrentLocalization}
                    data={{ url: link.url, title: link.label }}
                    isCurrent={link.label === currentLocalization}
                />
            </li>
        );
    };

    LiRenderer.propTypes = {
        children: PropTypes.array,
    };

    const closeWithEscape = (event) => {
        if (isOpen && event && (event.key === 'Escape' || event.key === 'Esc')) {
            toggleOpen();
        }
    };

    const toggleWithEnter = (event) => {
        if (event.key === 'Enter') {
            toggleOpen();
        }
    };

    useEffect(() => {
        if (process.browser && isOpen) {
            selectorRef.current.focus();
            selectorRef.current.onkeydown = closeWithEscape;
        }
    }, [selectorRef, isOpen]);

    useEffect(() => {
        if (process.browser && isOpen) {
            if (typeof window !== 'undefined') {
                const { bottom } = selectorRef.current.getBoundingClientRect();
                setIsOutOfViewPort(bottom > window.innerHeight);
            }
        }
        if (typeof window !== 'undefined') {
            const richTextLinks = document.querySelectorAll('.country-list li a');
            for (let i = 0; i < richTextLinks.length; i++) {
                richTextLinks[i].setAttribute('class', 'event_outbound_link');
                richTextLinks[i].setAttribute('data-action-detail', richTextLinks[i].getAttribute('href'));
            }
        }
    });

    return (
        <div
            tabIndex={0}
            className={`country-selector ${isOpen ? 'open' : ''}`}
            onMouseEnter={open}
            onMouseLeave={close}
            onKeyPress={toggleWithEnter}
        >
            <button
                type="button"
                className="label"
                onClick={toggleOpen}
                aria-expanded={isOpen}
                tabIndex={-1}
            >
                Select Country / Region
            </button>
            <div className={`dropdown-menu${outOfViewport ? ' out-of-viewport' : ''}`} tabIndex={0} ref={selectorRef} aria-label="country selector dropdown menu">
                <div className="dropdown-menu-header">
                    <h2 className="select-country">{title}</h2>
                    <div role="button" className="mobile-close-icon" tabIndex={0} onClick={toggleOpen} aria-label={countrySelector.mobileClose}>
                        <span className="line" />
                        <span className="line" />
                    </div>
                </div>
                <div className="country-list">
                    <ReactMarkdown className="column first-region" rehypePlugins={[rehypeRaw]} components={{ listItem: LiRenderer }}>
                        {firstRegion}
                    </ReactMarkdown>
                    <ReactMarkdown className="column" rehypePlugins={[rehypeRaw]} components={{ listItem: LiRenderer }}>
                        {secondRegion}
                    </ReactMarkdown>
                    <ReactMarkdown className="column" rehypePlugins={[rehypeRaw]} components={{ listItem: LiRenderer }}>
                        {thirdRegion}
                    </ReactMarkdown>
                    <ReactMarkdown className="column" rehypePlugins={[rehypeRaw]} components={{ listItem: LiRenderer }}>
                        {fourthRegion}
                    </ReactMarkdown>
                </div>
            </div>
        </div>
    );
};

CountrySelector.propTypes = {
    countries: PropTypes.object,
    isOpen: PropTypes.bool,
    toggleOpen: PropTypes.func,
    open: PropTypes.func,
    close: PropTypes.func,
};

export default CountrySelector;
