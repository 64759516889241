export const getAverageRating = (allRatings, productId) => {
    return Math.floor((allRatings[productId] || 0) * 10) / 10;
};

export const generateCallbackUrl = (domainName, locale) => {
    return `${domainName}/${locale.toLowerCase()}`;
};

export const loadCSwidget = () => {
};
