module.exports = `
... on Carousel {
  slides: slidesCollection(limit: 15) {
    items {
      title
      paragraph
      image {
        name
        cfmedia {
          description
          url
          width
          height
        }
      }
      link {
        name
        label
        page {
          url
        }
      }
    }
  }
  ariaLabel
}
`;
