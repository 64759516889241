import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Accordion = ({ items, ariaLabel }) => {
    const [allItems, setItems] = useState([]);

    const toggleItems = (accordionItems, id) => {
        const [...tempItems] = accordionItems;
        tempItems[id].visible = !(tempItems[id].visible);
        return tempItems;
    };

    useEffect(() => {
        if (process.browser) {
            const preparedItems = items.items.map((item, index) => (
                { id: index, div: `item-${index}`, visible: false, ...item }
            ));
            setItems(preparedItems);
        }
    }, []);


    const onItemClick = (id) => {
        const tempItems = toggleItems(allItems, id);
        setItems(tempItems);
    };

    const toggleWithEnter = (event, id) => {
        if (event.key === 'Enter') {
            onItemClick(id);
        }
    };

    const renderItems = () => allItems.map((item, index) => (
        <div key={index} className="accordion-item">
            <div
                role="button"
                type="button"
                tabIndex={0}
                className="item-tab event_faq_question_open event_button_click"
                data-action-detail={item.title}
                onClick={() => onItemClick(item.id)}
                onKeyPress={(e) => toggleWithEnter(e, item.id)}
                aria-label={item.title}
                aria-expanded={item.visible}
            >
                <div className={`item-icon ${item.visible ? 'up' : 'down'}`} />

                <h3>{item.title}</h3>

            </div>
            <div className={item.visible ? 'item-content' : 'item-content item-hidden'}>
                <ReactMarkdown className="text-block" rehypePlugins={[rehypeRaw]}>
                    {item.text}
                </ReactMarkdown>
            </div>
        </div>
    ));

    return (
        <div className="accordion" aria-label={ariaLabel}>
            {renderItems()}
        </div>
    );
};

export default Accordion;

Accordion.propTypes = {
    items: PropTypes.object.isRequired,
    ariaLabel: PropTypes.string,
};
