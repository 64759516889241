module.exports = `
... on SearchResults {
    resultsMessage
    noResultsMessage
    loadingMessage
    errorMessage
    leftImage {
         cfmedia {
          description
          url
          width
          height
        }
    }
    rightImage {
         cfmedia {
          description
          url
          width
          height
        }
    }
}
`;
