import { buildInternalLink } from './Header.helper';

test('Should build internal link object', () => {
    const input = {
        '__typename': 'LinkInternal',
        'label': 'First Page',
        'page': {
            'url': '/first-page',
        },
    };

    const expectedOutput = {
        'label': 'First Page',
        'page': {
            'url': '/first-page',
        },
        'title': 'First Page',
        'type': 'link',
        'url': '/first-page',
        'target': '_self',
    };

    expect(buildInternalLink(input)).toEqual(expectedOutput);
});
