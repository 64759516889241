import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { InternalLink, Img } from '../../UI';

const Collections = ({ collections }) => {
    const ref = useRef();
    const [preparedItems, setItems] = useState([]);

    const filterCollections = (items, cat) => {
        const filteredItems = items.map(item => {
            if (item.category === cat) {
                return { visible: true, ...item };
            }
            return { visible: false, ...item };
        });
        return filteredItems;
    };

    const handleClick = (e) => {
        const tempItems = filterCollections(collections, e.target.textContent);
        setItems(tempItems);
    };

    useEffect(() => {
        if (process.browser) {
            const prepareItems = collections.map(item => {
                if (item.mainCollectionCategory) {
                    return { visible: true, ...item };
                }
                return { visible: false, ...item };
            });
            setItems(prepareItems);
        }
    }, []);

    const renderCollection = preparedItems.map((item, index) => {
        const { label, icon, mainCollectionCategory } = item;

        return (
            <li
                style={{ cursor: 'pointer' }}
                onClick={handleClick}
                ref={ref}
                className={mainCollectionCategory !== null
                    ? 'main-collection-item'
                    : `sub-collection-item ${item.visible ? 'show' : 'hidden'}`}
                key={index}
            >
                <InternalLink link={item}>
                    <Img contentfulImage={icon.cfmedia} />
                    <ReactMarkdown className="text-block" rehypePlugins={[rehypeRaw]}>
                        {label}
                    </ReactMarkdown>
                </InternalLink>
            </li>
        );
    });

    return (
        <nav className="product-collections">
            <h2 className="select-collection">Select collection:</h2>
            <ul className="collection-items">
                {renderCollection}
            </ul>
        </nav>
    );
};

Collections.propTypes = {
    collections: PropTypes.array,
};

export default Collections;
