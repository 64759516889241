import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import get from 'lodash/get';
import StickyBanner from '../StickyBanner/StickyBanner';
import { detailsPage } from '../../../src/constants/label.constants';
import {
    GtmHead,
    GtmBody,
    getSeoSchema,
    BazaarDccHead,
    getPgDataLayer,
    getDnsPrefetch,
} from '../Scripts';
import slashify from '../../helpers/slashify';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = ({
    children,
    header,
    footer,
    stickyBanner,
    pgDataLayer,
    breadcrumbs,
    pageInfo = {},
    productHeaderImg,
    privacyLink,
}) => {
    const {
        title,
        description,
        featuredImage,
        ogType,
        url,
        ogTitle,
        ogDescription,
        keywords,
        bvData,
        schemaCollection,
        pgdl,
        twitterTitle,
        twitterDescription,
        pageType,
    } = pageInfo;

    const footerProps = get(footer, 'footer.items[0]', {});
    const pgdlScript = getPgDataLayer(
        pgDataLayer,
        pgdl,
        url,
        process.env.DOMAIN_NAME,
    );
    const seoSchema = getSeoSchema(schemaCollection, url, process.env.DOMAIN_NAME);
    const absoluteUrl = `${process.env.DOMAIN_NAME}${slashify(url)}`;

    const croppedFeaturedImage = featuredImage && featuredImage.cfmedia && featuredImage.cfmedia.url;

    useEffect(() => {
        if (
            pageType === detailsPage.articleDetail || pageType === detailsPage.productDetail
        ) {
            window.PGdataLayer.event = [
                {
                    category: 'event_informational_action',
                    action:
                        pageType === 'Article Details Page'
                            ? 'event_view_article_page'
                            : 'event_view_product_detail_page',
                    label: typeof window !== 'undefined' ? window.location.href : '',
                    value: '',
                },
            ];
        }
    }, []);

    return (
        <React.Fragment>
            <Head>
                <title>{title}</title>
                {getDnsPrefetch()}
                <meta name="description" content={description} />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="keywords" content={keywords} />
                <meta
                    data-react-helmet="true"
                    name="robots"
                    content={process.env.META_ROBOTS}
                />
                {ogType && <meta property="og:type" content={ogType} />}
                <meta property="og:url" content={absoluteUrl} />
                {ogTitle && <meta property="og:title" content={ogTitle} />}
                {ogDescription && (
                    <meta property="og:description" content={ogDescription} />
                )}
                {croppedFeaturedImage && (
                    <>
                        <meta property="og:image" content={croppedFeaturedImage} />
                        <meta name="twitter:image" content={croppedFeaturedImage} />
                    </>
                )}
                {twitterTitle && (
                    <meta property="twitter:title" content={twitterTitle} />
                )}
                {twitterDescription && (
                    <meta property="twitter:description" content={twitterDescription} />
                )}
                {pgdlScript}
                {seoSchema}
                {productHeaderImg && (
                    <link
                        rel="preload"
                        as="image"
                        href={`${productHeaderImg}?fm=webp`}
                        crossOrigin="true"
                    />
                )}

                <link
                    rel="preload"
                    href="/fonts/AvenirNextW05-Demi.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/AvenirNextW05-Medium.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/DidotLTW05-Bold.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/DidotLTW05-Italic.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                />

                <link rel="stylesheet" href="/css/en-font.css" />

                <link rel="icon" type="image/png" href="/images/favicon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link key="canonical" rel="canonical" href={absoluteUrl} />
                <GtmHead />
                <BazaarDccHead
                    pageInfo={pageInfo}
                    bvDccData={bvData}
                    domain={process.env.DOMAIN_NAME}
                    brandName={process.env.BVDCC_BRAND_NAME}
                    locale={process.env.BVDCC_LOCALE}
                />
            </Head>
            <GtmBody />
            <Header
                navigation={header.navigation}
                countries={header.countries}
                breadcrumbs={breadcrumbs}
                pageInfo={pageInfo}
            />
            <div role="main" className="layout">
                {children}
                <div role="region" className="sticky-banner-div sticky-banner-content">
                    <StickyBanner stickyBanner={stickyBanner} />
                </div>
                <Footer {...footerProps} privacyLink={privacyLink} />
            </div>
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    breadcrumbs: PropTypes.array,
    pageInfo: PropTypes.object,
    header: PropTypes.object,
    footer: PropTypes.object,
    pgDataLayer: PropTypes.object,
    productHeaderImg: PropTypes.string,
    privacyLink: PropTypes.string,
};

export default Layout;
