module.exports = `
... on ContactUs {
    title
    iframeLink
    leftBackgroundImage {
        cfmedia {
            description
            url
            width
            height
        }
    }
    rightBackgroundImage {
        cfmedia {
            description
            url
            width
            height
        }
    }
    ariaLabel
}
`;
