import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import LazyLoad from 'react-lazyload';
import Image from 'next/image';
import { zeroPercentImg } from '../../constants/label.constants';

const Percent = (props) => {
    const { leftList, rightList, ariaLabel } = props;

    return (
        <LazyLoad offset={20}>
            <div className="percent" aria-label={ariaLabel}>
                <div className="ornament">
                    <Image
                        src="/images/zero-percent.svg"
                        alt={zeroPercentImg.alt}
                        width="0"
                        height="0"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </div>
                <div className="text-wrapper">
                    <ReactMarkdown className="left-list" rehypePlugins={[rehypeRaw]}>
                        {leftList}
                    </ReactMarkdown>
                    <ReactMarkdown className="right-list" rehypePlugins={[rehypeRaw]}>
                        {rightList}
                    </ReactMarkdown>
                </div>
            </div>
        </LazyLoad>
    );
};

Percent.propTypes = {
    leftList: PropTypes.string,
    rightList: PropTypes.string,
    ariaLabel: PropTypes.string,
};

export default Percent;
