import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExternalLink, Img } from '../../UI';

import RatingStars from '../../UI/RatingStars/RatingStars';
import { reviewsVoteIcons, syndicationSource } from '../../../constants/label.constants';

import { formatDate } from './formatDate.helper';
import bvTrackImpressions from './trackImpresions.helper';

const ReviewCard = ({ review, votedReview, id, feedbackSubmit, productId }) => {
    const {
        UserNickname,
        UserLocation,
        Title,
        Rating,
        ReviewText,
        TotalPositiveFeedbackCount,
        LastModeratedTime,
        IsSyndicated,
        IsRecommended,
        SyndicationSource,
        ContextDataValues,
        ClientResponses,
    } = review;

    const [isDisabled, setIsDisabled] = useState(null);

    useEffect(() => {
        const checkReview = votedReview.filter(voted => voted.reviewId === id);
        if (checkReview.length > 0) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [feedbackSubmit]);

    useEffect(() => {
        if (process.browser && window.BV && window.BV.pixel) {
            bvTrackImpressions(id, productId);
        }
    }, []);

    const handleRecommendedProduct = () => {
        if (IsRecommended === null) return false;
        return IsRecommended ? 'Yes, I recommend this product' : 'No, I don\'t recommend this product';
    };

    return (
        <>
            <div className="item">
                <p className="user-nickname">{UserNickname}</p>
                {UserLocation && <p className="user-location">User location: {UserLocation}</p>}
            </div>
            <div className="item center">
                <p className="title">{Title}</p>
                <RatingStars
                    showRatingsLabel={true}
                    averageRating={Rating}
                />
                <p className="review-text">{ReviewText}</p>
                {handleRecommendedProduct()}
                <p className="review-date">{formatDate(LastModeratedTime)}</p>
                {IsSyndicated && SyndicationSource && (
                    <ExternalLink data={SyndicationSource.ContentLink}>
                        <Img alt={syndicationSource.alt} src={SyndicationSource.LogoImageUrl} />
                        <p className="review-additional">{` Publicada originalmente en ${SyndicationSource.Name}`}</p>
                    </ExternalLink>
                )}
                {ContextDataValues && ContextDataValues.IncentivizedReview && ContextDataValues.IncentivizedReview.Value && (
                    <p className="review-additional">This reviewer received incentives from the product manufacturer</p>
                )}
                {ClientResponses.length > 0 && (
                    <>
                        <p className="client-response">{`${ClientResponses[0].Department} ${formatDate(ClientResponses[0].Date)}`}</p>
                        <p className="client-response">{ClientResponses[0].Response}</p>
                    </>
                )}
            </div>
            <div className="item">
                {TotalPositiveFeedbackCount !== 0 && <p className="total-positive-feedback">{TotalPositiveFeedbackCount} People find this review helpful</p>}
                {!IsSyndicated && (
                    <div className="feedback">
                        <p id={`review${id}Feedback`}>Was this review helpful?</p>
                        <button
                            aria-describedby={`reviewTitle${id} review${id}Feedback`}
                            onClick={feedbackSubmit}
                            className={isDisabled ? 'disabled' : null}
                            type="button"
                            disabled={isDisabled}
                            aria-label={reviewsVoteIcons.voteUp}
                        >
                            <Img
                                name="Positive"
                                className="positive-feedback-icon"
                                src="https://images.ctfassets.net/a8l3ylu84syn/6pILT2i3DgZFbcf45pNcBs/348c05d83638d87bc6e931ef7096b79d/review_thumb_up.png?h=250&fm=webp"
                                alt="Positive Feedback"
                            />
                        </button>
                        <button
                            aria-describedby={`reviewTitle${id} review${id}Feedback`}
                            onClick={feedbackSubmit}
                            type="button"
                            className={isDisabled ? 'disabled' : null}
                            disabled={isDisabled}
                            aria-label={reviewsVoteIcons.voteDown}
                        >
                            <Img
                                name="Negative"
                                className="negative-feedback-icon"
                                src="https://images.ctfassets.net/a8l3ylu84syn/6OQykzAAhAEBxHzoHd6Ahk/2c8f10124aa270cbe29c7827326d0ae1/review_thumb_down.png?h=250&fm=webp"
                                alt="Negative Feedback"
                            />
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

ReviewCard.propTypes = {
    productId: PropTypes.string,
    review: PropTypes.object,
    feedbackSubmit: PropTypes.func,
    votedReview: PropTypes.array,
    id: PropTypes.string,
};

export default ReviewCard;
