import times from 'lodash/times';

export const getLabelFromUrl = (string) => {
    return string && string.replace(/[-_]/g, ' ');
};

export const generateBreadcrumbs = (seoHead, locale) => {
    const currentPageUrl = seoHead.url.replace(`/${locale.toLowerCase()}`, '');
    const urlParts = currentPageUrl.split('/').filter(part => part !== '');

    const { breadcrumbs } = seoHead;
    const breadcrumbsParts = breadcrumbs && breadcrumbs.split('/').filter(part => part !== '');

    const getBreadcrumbsLabels = () => breadcrumbsParts && breadcrumbsParts.map(part => getLabelFromUrl(part));

    const breadcrumbsLabels = {
        label: getBreadcrumbsLabels(),
    };

    const breadcrumbsResults = urlParts.map((_, index) => {
        const partUrl = `/${times(index + 1, i => urlParts[i]).join('/')}`;

        return {
            label: breadcrumbsLabels && breadcrumbsLabels.label && breadcrumbsLabels.label[index],
            url: partUrl,
        };
    });

    return breadcrumbsResults;
};
