import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '../UI/BackgroundImage/BackgroundImage';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const BlockWrapper = (props) => {
    const { leftImage, rightImage } = props;

    const leftImagePath = leftImage && getCdnImage(leftImage.cfmedia.url);
    const rightImagePath = rightImage && getCdnImage(rightImage.cfmedia.url);

    const background = {
        backgroundImage: `url(${leftImagePath}), url(${rightImagePath})`,
    };

    return (
        <BackgroundImage className="block-wrapper" backgroundImage={background}>
            {props.children}
        </BackgroundImage>

    );
};

BlockWrapper.propTypes = {
    children: PropTypes.element,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
};

export default BlockWrapper;
