import PropTypes from 'prop-types';
import React from 'react';
import useScreenSize from '../Hooks/useScreenSize';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const ContactUs = (props) => {
    const { title, iframeLink, leftBackgroundImage, rightBackgroundImage, ariaLabel } = props;
    const backgroundImage = `url(${getCdnImage(leftBackgroundImage.cfmedia.url)}), url(${getCdnImage(rightBackgroundImage.cfmedia.url)})`;
    const resolution = useScreenSize();

    return (
        <div className="contact-us" style={resolution && resolution.isDesktop ? { backgroundImage } : null} aria-label={ariaLabel}>
            <div className="contact-us-heading">
                <h1>{title}</h1>
            </div>
            <iframe src={iframeLink && iframeLink} title="contact-us" />
        </div>
    );
};

ContactUs.propTypes = {
    title: PropTypes.string,
    iframeLink: PropTypes.string,
    leftBackgroundImage: PropTypes.object,
    rightBackgroundImage: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ContactUs;
