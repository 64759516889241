module.exports = `
... on ProductLineBanners {
    title
    productLineCollection {
      items {
        title
        image {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
        internalLink {
          page {
            url
          }
        }
      }
    }
    ariaLabel
  }
`;
