import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LazyLoad from 'react-lazyload';
import { ExternalLink, InternalLink, Img } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const PetaBlock = ({ title, leftImage, rightImage, linksCollection, ariaLabel }) => {
    const imgL = getCdnImage(leftImage.cfmedia.url);
    const imgR = getCdnImage(rightImage.cfmedia.url);
    const bgMainImage = getCdnImage('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg');

    const backgroundImage = `url(${imgL}), url(${imgR}), url(${bgMainImage})`;

    const linkItems = get(linksCollection, 'items', []);

    const renderLink = (item, index) => {
        if (item.__typename === 'LinkExternal') {
            return (
                <ExternalLink key={index} data={item}>
                    <Img contentfulImage={item.icon.cfmedia} />
                </ExternalLink>
            );
        }
        if (item.__typename === 'LinkInternal') {
            const { url } = item.page;
            return (
                <InternalLink
                    key={index}
                    link={{ url }}
                >
                    <Img contentfulImage={item.icon.cfmedia} />
                </InternalLink>
            );
        }
        return null;
    };


    const links = linkItems.map((link, i) => {
        return (
            renderLink(link, i)
        );
    });

    return (
        <LazyLoad offset={20}>
            <div className="peta-block-container" style={{ backgroundImage }} suppressHydrationWarning={true} aria-label={ariaLabel}>
                <div className="peta-info-box">
                    <ReactMarkdown className="peta-title" rehypePlugins={[rehypeRaw]}>
                        {title}
                    </ReactMarkdown>
                    <div className="peta-icons">
                        {links}
                    </div>
                </div>
            </div>
        </LazyLoad>
    );
};

PetaBlock.propTypes = {
    title: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    linksCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default PetaBlock;
