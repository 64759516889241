module.exports = `
...on CollectionCarousel{
  title
  slidesCollection {
    items {
      __typename
      ... on Image {
        name
        cfmedia {
          description
          url
          width
          height
        }
      }
      ... on Video {
        name
        videoPoster {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
        video {
          title
          url
        }
      }
    }
  }
}
  
`;
