module.exports = `
{
  footer: footerCollection(where: {name: "Global footer"}, limit: 1) {
    items {
      socialBlockTitle
      socialBlockLinksCollection {
        items {
          title
          url
          target
          image {
            name
            cfmedia {
              description
              url
              width
              height
            }
          }
        }
      }
      smartlabel {
        title
        url
        target
        image {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
      }
      footerLinksCollection {
        items {
          __typename
          ... on LinkInternal {
            label
            page {
              url
            }
            openInNewWindow
          }
          ... on LinkExternal {
            title
            url
            target
            image {
              name
              cfmedia {
                description
                url
                width
                height
              }
            }
            adChoices
            ccpa
          }
        }
      }
      ariaLabel
    }
  }
}
`;
