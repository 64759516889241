import get from 'lodash/get';

export const buildProduct = (productVariants, selectedVariantIndex) => {
    const selectedProductVariant = get(productVariants, `items[${selectedVariantIndex}]`);

    return {
        images: selectedProductVariant.images,
        gtin: selectedProductVariant.gtin,
    };
};
