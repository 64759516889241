module.exports = `
... on WriteReview {
    product {
        name
        seoHead {
          url
          featuredImage {
            name
            cfmedia {
              description
              url
              width
              height
            }
          }
        }
        bvData {
          productId
        }
      }
  footerLinks: footerLinksCollection {
    items {
      url
      title
      target
      ccpa
    }
  }
}
`;
