const reviewErrMsg = 'Please enter a review with minimum 50 and maximum of 9999 characters';
const nicknameErrMsg = 'Please enter your nickname with minimum 4 and maximum of 25 characters';

export default {
    'mainTitle': 'Write your review',
    'numberOfErrors': 'There are {numberOfErrors} items that need review in this page',
    'productRating': {
        'title': 'Your product rating',
        'overallRating': {
            'label': 'Overall rating',
            'errorMessages': [
                { 'type': 'required', 'message': 'Overall rating is required' },
            ],
        },
        'recommend': {
            'label': 'Would you recommend this product?',
            'optionYes': 'Yes',
            'optionNo': 'No',
            'errorMessages': [
                { 'type': 'required', 'message': 'Please make a selection' },
            ],
        },
    },
    'productReview': {
        'title': 'Your product review',
        'reviewSummary': {
            'label': 'Review Summary / Title',
            'info': 'Max 50 characters',
            'errorMessages': [
                { 'type': 'required', 'message': 'Summary / Title is required' },
                { 'type': 'max_length', 'message': 'Please enter a summary with a max of 50 characters' },
            ],
        },
        'review': {
            'label': 'Your review',
            'info': 'Minimum 50 characters',
            'errorMessages': [
                { 'type': 'required', 'message': 'Your review is required' },
                { 'type': 'max_length', 'message': reviewErrMsg },
                { 'type': 'min_length', 'message': reviewErrMsg },
            ],
        },
        'accessibility': {
            'charLeftInfo': 'characters left',
            'minCharInfo': 'This field requires minimum of 50 characters',
            'success': '50 characters entered',
        },
    },
    'userInformation': {
        'title': 'Your information',
        'nickname': {
            'label': 'Name / Nickname',
            'info': 'Example: Jackie27. For privacy reasons do not use your full name or email',
            'errorMessages': [
                { 'type': 'required', 'message': 'Your name / nickname is required' },
                { 'type': 'max_length', 'message': nicknameErrMsg },
                { 'type': 'min_length', 'message': nicknameErrMsg },
            ],
        },
        'dateOfBirth': {
            'label': 'Date of birth',
            'month': {
                'label': 'Month',
                'errorMessages': [
                    { 'type': 'required', 'message': 'Please enter the Month of Birth' },
                ],
            },
            'year': {
                'label': 'Year',
                'errorMessages': [
                    { 'type': 'required', 'message': 'Please enter the Year of Birth' },
                ],
            },
        },
        'location': {
            'label': 'Your location',
            'errorMessages': [
                { 'type': 'required', 'message': 'Your location is required' },
                { 'type': 'max_length', 'message': 'Please enter your location up to 50 characters' },
            ],
        },
        'email': {
            'label': 'Your Email',
            'info': 'We will only use your email address in regards to your submission.',
            'errorMessages': [
                { 'type': 'required', 'message': 'Your email is required' },
                { 'type': 'valid_email', 'message': 'Enter a valid E-mail address' },
                { 'type': 'max_length', 'message': 'Please enter an email with a max of 255 characters' },
            ],
        },
        'hairType': {
            'label': 'Hair Type',
            'options': ['Dry, Damaged Hair', 'Fine, Flat Hair', 'Colored Hair', 'Curly Hair', 'Oily Hair', 'Straight Hair'],
            'errorMessages': [
                { 'type': 'required', 'message': 'Please select your hair type' },
            ],
        },
        'tos': {
            'label': 'By selecting this checkbox and the Submit button, I agree to the conditions stated in the Terms & Conditions',
            'errorMessages': [
                { 'type': 'required', 'message': 'Please accept Terms and Conditions' },
            ],
        },
        'buttons': {
            'cancel': 'Cancel',
            'preview': 'Preview',
        },
    },
    'previewModal': {
        'title': 'Confirm your review',
        'userLocation': 'User location:',
        'yesRecommend': 'Yes, I recommend this product',
        'notRecommend': 'No, I don\'t recommend this product',
        'buttons': {
            'edit': 'Edit',
            'submit': 'Submit',
        },
    },
    'thankYouModal': {
        'title': 'Thank you for submitting!',
        'message': 'Thank you so much for your review. It means so much to us! We\'re checking it out now and we\'ll post it as soon as we can on our site. Be on the look out!',
        'continueButton': 'Continue',
    },
    'errorModal': {
        'title': 'Sorry',
        'message': 'Service is currently unavailable please try again later.',
        'continueButton': 'Continue',
    },
    'footer': {
        'paragraph': 'Trust is a cornerstone of our corporate mission, and the success of our business depends on it. P&G is committed to maintaining your trust by protecting personal information we collect about you, our consumers.',
    },
    'gerneral': {
        'genericFieldError': 'Please correct this field', // this is just a backup if no error message is provided for a field
    },
    'underageText': 'We\'re sorry, but we can\'t accept your messages or personal information because you do not meet our eligibility requirements. But that doesn\'t mean you can\'t explore the rest of our site and learn more about us! Check out [www.pg.com] (https://www.pg.com) for information about P&G and its brands.',
    'checkboxText': 'By selecting this checkbox and the Submit button, I agree to the conditions stated in the',
    'termsConditionsText': 'Terms & Conditions',
    'termsConditionsLink': 'https://termsandconditions.pg.com/en-us/',
};
