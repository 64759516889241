import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CarouselArrow, AriaLiveRegion } from '../../UI';

const NumberSlider = ({ images, current, onChange }) => {
    const [currentSlide, setCurrentSlide] = useState(current);
    const slidesLength = images.length;
    const counter = `${currentSlide + 1}/${slidesLength}`;

    const onPrevClick = () => {
        if (currentSlide === 0) {
            setCurrentSlide(slidesLength - 1);
            onChange(images.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
            onChange(current - 1);
        }
    };

    const onNextClick = () => {
        if (currentSlide === slidesLength - 1) {
            setCurrentSlide(0);
            onChange(0);
        } else {
            setCurrentSlide(currentSlide + 1);
            onChange(current + 1);
        }
    };

    return (
        <div className="number-slider">
            <CarouselArrow className="mobile-arrow-prev" onClick={onPrevClick} />
            <span>{counter}</span>
            <CarouselArrow className="mobile-arrow-next" onClick={onNextClick} />
            <AriaLiveRegion current={currentSlide + 1} total={slidesLength} />
        </div>
    );
};

NumberSlider.propTypes = {
    current: PropTypes.number,
    images: PropTypes.array,
    onChange: PropTypes.func,
};

export default NumberSlider;
