module.exports = `
... on TextInfoBlock {
  __typename
  title
  leftBlock: leftBlockCollection(limit: 10) {
    items {
      ... on ContentPlainTextMd {
        __typename
        content
      }
      ... on Image {
        __typename
        name
        cfmedia {
          description
          url
          width
          height
        }
      }
      ... on LinkInternal {
        __typename
        label
        page {
          title
          url
        }
      }
    }
  }
  rightBlock: rightBlockCollection(limit: 10) {
    items {
      ... on ContentPlainTextMd {
        __typename
        content
      }
      ... on LinkExternal {
        __typename
        title
        url
        target
      }
      ... on Image {
        __typename
        name
        cfmedia {
          description
          url
          width
          height
        }
        styles
      }
      ... on LinkInternal {
        __typename
        label
        page {
          title
          url
        }
      }
    }
  }
  cornerImage {
    name
    cfmedia {
      description
      url
      width
      height
    }
  }
  wrapper {
    __typename
    ... on BlockWrapper {
      leftImage {
        cfmedia {
          description
          url
          width
          height
        }
        styles
      }
      rightImage {
        cfmedia {
          description
          url
          width
          height
        }
        styles
      }
    }
    ... on BlockWrapperWithImage {
      topImage {
        name
        cfmedia {
          description
          url
          width
          height
        }
      }
      leftImage {
        cfmedia {
          description
          url
          width
          height
        }
      }
      rightImage {
        cfmedia {
          description
          url
          width
          height
        }
      }
      logos: logosCollection(limit: 2) {
        items {
            name
            cfmedia {
              description
              url
              width
              height
            }
        }
      } 
      heading
    }
  }
  share {
    title
    hashtag
    message
    buttonLabel
  }
  ariaLabel
  bottomBlock
  styles
}
`;
