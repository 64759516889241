import React from 'react';
import {
    TwitterIcon as DefaultTwitterIcon,
    FacebookIcon as DefaultFacebookIcon,
    LinkedinIcon as DefaultLinkedinIcon,
    EmailIcon as DefaultEmailIcon,
} from 'react-share';

import customIcons from './custom-icons';

import Img from '../Img/Img';

export const TwitterIcon = () => {
    if (!customIcons.twitter) {
        return <DefaultTwitterIcon size={22} />;
    }

    return <Img src={customIcons.twitter} alt="Twitter share" />;
};

export const FacebookIcon = () => {
    if (!customIcons.facebook) {
        return <DefaultFacebookIcon size={22} />;
    }

    return <Img src={customIcons.facebook} alt="Facebook share" />;
};

export const LinkedinIcon = () => {
    if (!customIcons.linkedin) {
        return <DefaultLinkedinIcon size={22} />;
    }

    return <Img src={customIcons.linkedin} alt="Linkedin share" />;
};

export const EmailIcon = () => {
    if (!customIcons.email) {
        return <DefaultEmailIcon size={22} />;
    }

    return <Img src={customIcons.email} alt="Email share" />;
};
