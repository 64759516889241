module.exports = `
... on TextTable{
    title
    leftImage{
       cfmedia {
          description
          url
          width
          height
        }
    }
    rightImage{
       cfmedia {
          description
          url
          width
          height
        }
    }
    contentCollection{
      items{
        nameEn
        content
      }
    }
    ariaLabel
    styles
  }
`;
