module.exports = `
{
  stickyBannerCollection(where: {name: "Sticky Banner"}, limit: 1) {
    items {
        sys {
          id
        }
        name
        title
        imageCollection(limit: 2) {
          items {
            sys {
              id
            }
            name
            image {
              url
            }
            altText
            link
            target
            styles
            gaEventLabel
            gaEventClass
          }
        }
      }
  }
}
`;
