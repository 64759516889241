module.exports = `
... on PetaBlock {
    title
    leftImage {
      cfmedia {
        description
        url
        width
        height
      }
    }
    rightImage {
      cfmedia {
        description
        url
        width
        height
      }
    }
    linksCollection {
      items {
        __typename
        ... on LinkExternal {
          url
          target
          image {
            name
            cfmedia {
              description
              url
              width
              height
            }
          }
        }
        ... on LinkInternal {
          page {
            url
          }
          openInNewWindow
          icon {
            name
            cfmedia {
              description
              url
              width
              height
            }
          }                
        }
      }
    }
    ariaLabel
  }
`;
