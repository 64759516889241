import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage';
import { getFieldError } from '../WriteReview.helper';

const Select = ({ fieldName, fieldData, options, errors, onFieldChange, onFieldBlur, required }) => {
    const className = getFieldError(errors) ? 'input-error' : '';

    return (
        <div>
            <select
                name={fieldName}
                onChange={onFieldChange}
                onBlur={onFieldBlur}
                className={className}
                aria-required={required ? 'true' : 'false'}
            >
                <option>{fieldData.label}</option>
                {options.map((option, index) => <option key={index}>{option}</option>)}
            </select>
            <ErrorMessage errors={errors} errorMessages={fieldData.errorMessages} />
        </div>
    );
};

Select.propTypes = {
    errors: PropTypes.array,
    fieldData: PropTypes.object,
    fieldName: PropTypes.string,
    onFieldBlur: PropTypes.func,
    onFieldChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
};

export default Select;
