module.exports = `
... on ProductListingHeader {
    name
    topImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    leftImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    rightImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    mainDescription
    additionalDescription
    ariaLabel
  }
`;
