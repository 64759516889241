import React from 'react';
import PropTypes from 'prop-types';
import CountrySelector from '../../CountrySelector/CountrySelector';
import { SearchInput, InternalLink } from '../../UI';

const Subheader = ({ countries, openCSelector, closeCSelector, toggleCSelector, isCSelectorOpen, currentLocale }) => {
    const contactUsLink = {
        url: `/${currentLocale}/contact-us/`,
        label: 'Contact us',
    };

    return (
        <div className="subheader">
            <div className="subheader-left">
                <CountrySelector
                    countries={countries}
                    open={openCSelector}
                    close={closeCSelector}
                    toggleOpen={toggleCSelector}
                    isOpen={isCSelectorOpen}
                />
            </div>
            <div className="subheader-right">
                <SearchInput redirectUrl={`/${currentLocale}/search/`} />
                <InternalLink link={contactUsLink} label={contactUsLink} />
            </div>
        </div>
    );
};

Subheader.propTypes = {
    closeCSelector: PropTypes.func,
    countries: PropTypes.object,
    currentLocale: PropTypes.string,
    isCSelectorOpen: PropTypes.bool,
    openCSelector: PropTypes.func,
    toggleCSelector: PropTypes.func,
};

export default Subheader;
