import get from 'lodash/get';

export const slashify = (origUrl) => {
    let url = origUrl.trim();

    if (url.substr(-1, 1) !== '/' && !url.includes('?')) {
        url = `${url}/`;
    }

    return url;
};

export const transformImage = (url, additionalParams = null) => {
    return additionalParams ? `${url}?${additionalParams}` : url;
};

export const getImageData = (image, additionalParams) => {
    let url = get(image, 'url', '');
    if (additionalParams && typeof additionalParams === 'string') {
        url = `${url}?${additionalParams}`;
    }

    return {
        url: url || '',
        alt: get(image, 'description', ''),
        width: get(image, 'width', 0),
        height: get(image, 'height', 0),
    };
};
