require('dotenv').config();
const axios = require('axios');
const get = require('lodash/get');
const chalk = require('chalk');
const queries = require('../queries');
const runtimeConfigInit = require('../../runtimeConfig');

const {
    ENV,
    CF_SPACE_ID,
    CF_ENVIRONMENT,
    CF_DELIVERY_ACCESS_TOKEN,
    CONTENTFUL_PREVIEW_ACCESS_TOKEN,
} = runtimeConfigInit && process.env;

const baseApiUrl = 'https://graphql.contentful.com/content/v1/spaces/{spaceId}/environments/{envId}';
const environment = CF_ENVIRONMENT || 'master';
const finalApiUrl = baseApiUrl.replace('{spaceId}', CF_SPACE_ID).replace('{envId}', environment);

const buildOptions = query => ({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ENV === 'production' ? CF_DELIVERY_ACCESS_TOKEN : CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query }),
});

const getPageContentTypes = (pageId) => {
    const options = buildOptions(queries.pageContentTypes(pageId));

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => res.data.data.page.contentCollection.items)
        .catch(() => console.log('Error fetching page content types'));
};

const getPage = (pageId, componentsQuery, contentCollectionLimit) => {
    const options = buildOptions(queries.singlePage(pageId, componentsQuery, contentCollectionLimit));

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => res.data.data.page)
        .catch(() => console.log(chalk.bgRed(queries.singlePage(pageId, componentsQuery, contentCollectionLimit))));
};

const getAllPages = () => {
    const options = buildOptions(queries.allPages);

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => res.data.data.pageCollection.items)
        .catch(() => console.warn('Error fetching slugs'));
};

const getProductDetailPages = () => {
    const options = buildOptions(queries.productDetailPages);

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data.pageCollection.items', []))
        .catch(() => console.warn('Error fetching product detail pages'));
};

const getHeader = () => {
    const options = buildOptions(queries.headerNavigation);

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data'))
        .catch(() => console.warn('Error fetching header', chalk.bgRed(queries.headerNavigation)));
};

const getFooter = () => {
    const options = buildOptions(queries.footer);

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data'))
        .catch(() => console.warn('Error fetching footer navigation'));
};

const getProductHeaders = () => {
    const options = buildOptions(queries.productHeaders);

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data.productHeaderCollection.items'))
        .catch(() => console.warn('Error fetching product header'));
};

const getPGDataLayer = () => {
    const options = buildOptions(queries.pgDataLayer(ENV));

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data.pgDataLayerCollection.items[0]'))
        .catch(() => console.warn('Error fetching PGDataLayer'));
};

const getProductListingFilters = () => {
    const options = buildOptions(queries.productListingFilters);

    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data.productFilters.items'))
        .catch(() => console.warn('Error fetching product listing filters'));
};

const getProductCollections = () => {
    const options = buildOptions(queries.productCollections);
    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data.productListingCollection.items[0].collections.items'))
        .catch(() => console.warn('Error fetching product collections'));
};

const getStickyBanner = () => {
    const options = buildOptions(queries.stickyBanner);
    return axios.post(finalApiUrl, options.body, { headers: options.headers })
        .then(res => get(res.data, 'data.stickyBannerCollection.items'))
        .catch(() => console.warn('Error fetching sticky banner'));
};

module.exports = {
    getPage,
    getAllPages,
    getHeader,
    getFooter,
    getPageContentTypes,
    getPGDataLayer,
    getProductListingFilters,
    getProductHeaders,
    getProductCollections,
    getProductDetailPages,
    getStickyBanner,
};
