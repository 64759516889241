import { detect } from 'detect-browser';

const browser = detect();

export function getImageUrl(imageUrl, width = 'auto') {
    const imageWidth = width ? `?w=${width}` : '';
    return `https:${imageUrl}${imageWidth}`;
}
const URLparser = (url = '') => {
    try {
        const loc = new URL(url);
        const serachParams = loc.searchParams;
        return {
            isQueryAvailable: !!loc.search?.trim(),
            isWidthAvailbale: serachParams.has('w'),
            isFormatAvailable: serachParams.has('fm'),
            isQualityAvailable: serachParams.has('q'),
        };
    } catch (msg) {
        console.error(msg);
    }
};
export const getCdnImage = (src, dimension_params = {}) => {
    const width = dimension_params?.w;
    const quality = dimension_params?.q;
    if (typeof src !== 'string' || !src) return '';
    if (src.includes('data:image/')) return src;
    let end_point_url = !src.includes('http') ? `https:${src}` : src;
    const {
        isFormatAvailable,
        isQueryAvailable,
        isWidthAvailbale,
        isQualityAvailable,
    } = URLparser(end_point_url);
    if (isFormatAvailable && isWidthAvailbale) return end_point_url;
    if (!src.includes('svg')) {
        const isIos = browser && (browser.name === 'safari' || browser.name === 'ios');
        const image_format = 'webp';
        if (!isFormatAvailable) {
            if (isIos) {
                end_point_url = `${end_point_url}`;
            } else {
                end_point_url = `${end_point_url}${
                    isQueryAvailable ? '&' : '?'
                }fm=${image_format}`;
            }
        }
        if (width && width !== 'auto' && !isWidthAvailbale) {
            if (isQualityAvailable) {
                end_point_url = `${end_point_url}&w=${width}`;
            } else {
                end_point_url = `${end_point_url}${isIos ? '?w=' : '&w='}${width}`;
            }
        }
        if (!isQualityAvailable) {
            if (end_point_url.includes('w=') || end_point_url.includes('fm=')) {
                end_point_url = `${end_point_url}&q=${quality || 70}`;
            } else {
                end_point_url = `${end_point_url}?q=${quality || 70}`;
            }
        }
    }
    return end_point_url;
};
export function getSrcSet(imageUrl, originalImgWidth, minWidth) {
    const fluidSizes = [400, 600, 800, 1200, 1400, 1600];
    const imageSrcSet = [];

    let filteredSizes = fluidSizes.filter((size) => size <= originalImgWidth);

    if (minWidth) {
        filteredSizes = fluidSizes.filter((size) => size >= minWidth);
    }

    filteredSizes.map((imgSize) => {
        imageSrcSet.push(
            `${getCdnImage(imageUrl, {
                w: `${imgSize}`,
            })} ${imgSize}w`,
        );
        return imgSize;
    });

    return imageSrcSet.join(',');
}
