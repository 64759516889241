import React from 'react';
import PropTypes from 'prop-types';
import { Img } from '../UI';

const NixedIngredientsIcon = (props) => {
    const { imageUrl, iconLabel } = props;

    // We are returning empty alt tag in Img due to Accessibility requirements
    return (
        <div className="nixed-ingredients-icon">
            <Img contentfulImage={imageUrl} />
            <p>{iconLabel}</p>
        </div>
    );
};

NixedIngredientsIcon.propTypes = {
    imageUrl: PropTypes.string,
    iconLabel: PropTypes.string,
};

export default NixedIngredientsIcon;
