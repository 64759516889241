module.exports = `
... on NixedAccordionHolder {
  title
  accordion {
    items: slidesCollection(limit: 6) {
      items {
        title
        text
      }
    }
  }
  footnote
  image {
    cfmedia {
      description
      url
      width
      height
    }
  }
  ariaLabel
}
`;
