import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import slashify from '../../../helpers/slashify';

const InternalLink = ({ link, className, children, eventClass = 'event_internal_link', dataActionDetailPrefix = '', onFocus = () => {}, isAriaCurrent }) => {
    if (!link) {
        return null;
    }
    const [ariaLabelText, setAriaLabelText] = useState('');
    const href = get(link, 'page.url') || get(link, 'url');

    useEffect(() => {
        setAriaLabelText(href && href.split('/'));
    }, [href]);

    const label = get(link, 'label', '');
    const title = get(link, 'page.title', link.title);
    const openInNewWindow = get(link, 'openInNewWindow', false);
    const target = openInNewWindow ? '_blank' : '_self';

    let linkClass = className || '';
    linkClass = `${linkClass} internal-link ${eventClass}`;

    let dataAction = dataActionDetailPrefix ? `${dataActionDetailPrefix}, ${process.env.DOMAIN_NAME}${href}` : `${process.env.DOMAIN_NAME}${href}`;
    if (eventClass === 'event_button_click') dataAction = `${process.env.DOMAIN_NAME}${href}, ${label}`;

    return (
        <a
            className={linkClass}
            href={href && slashify(href)}
            target={target}
            data-action-detail={dataAction}
            title={title}
            tabIndex={0}
            onFocus={onFocus}
            aria-current={isAriaCurrent ? 'page' : null}
            aria-label={ariaLabelText && `Learn more about ${ariaLabelText[ariaLabelText.length - 2]}`}
        >
            {children || label.charAt(0).toUpperCase() + label.slice(1)}
        </a>
    );
};

InternalLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    link: PropTypes.object,
    eventClass: PropTypes.string,
    dataActionDetailPrefix: PropTypes.string,
    onFocus: PropTypes.func,
    isAriaCurrent: PropTypes.bool,
};

export default InternalLink;
