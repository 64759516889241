const buildComponentsQuery = (contentTypes) => {
    const presentTypes = [];
    let componentQuery = '';

    contentTypes.forEach((contentType) => {
        if (!contentType) return;

        const component = contentType.__typename;

        try {
            const fileContent = require(`../components/${component}/${component}.query.js`);

            if (!presentTypes.find(comp => comp === component)) {
                componentQuery += fileContent;
                presentTypes.push(component);
            }
        } catch {
            console.warn(`${component} component doesn't exist`);
        }
    });

    return componentQuery;
};

export default buildComponentsQuery;
