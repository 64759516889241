module.exports = `
... on VideoBlock {
    leftImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    rightImage {
       cfmedia {
          description
          url
          width
          height
        }
    }
    video
    videoTitle
    footnote
    smallVideo
    playIconText
    videoPoster {
      cfmedia {
        description
        url
        width
        height
      }
    }
    ariaLabel
    styles
}
`;
