module.exports = `
{
  navigation: navigationCollection(where: {name: "Main Navigation"}, limit: 1) {
    items {
      links: mainItemsCollection(limit: 8) {
        items {
          __typename
          ... on LinkInternal {
            label
            openInNewWindow
            page {
              url
              hideInNavigation
            }
          }
          ... on NavigationDropdown {
            hideInNavigation
            label
            leftImage {
              cfmedia {
                description
                url
                width
                height
              }
            }
            rightImage {
              cfmedia {
                description
                url
                width
                height
              }
            }
            columns: columnsCollection(limit: 6) {
              items {
                __typename
                ... on NavigationDropdownListing {
                  links: linksCollection(limit: 20) {
                    items {
                      ... on LinkInternal {
                        label
                        page {
                          url
                        }
                        openInNewWindow
                      }
                      ... on LinkExternal {
                        title
                        url
                        target
                      }
                    }
                  }
                }
                ... on NavigationDropdownImage {
                  nameEn
                  image {
                    cfmedia {
                      description
                      url
                      width
                      height
                    }
                  }
                }
                ... on NavigationDropdownColumn {
                  name
                  nameEn
                  heading {
                    label
                    page {
                      url
                    }
                  }
                  links: linksCollection(limit: 15) {
                    items {
                      ... on LinkInternal {
                        label
                        page {
                          featuredImage {
                            cfmedia {
                              description
                              url
                              width
                              height
                            }
                          }
                          url
                        }
                        openInNewWindow
                      }
                      ... on LinkExternal {
                        title
                        url
                        target
                      }
                    }
                  }
                  exploreAllLink {
                    label
                    page {
                      url
                    }
                  }
                }
              }
            }
            categoryLandingPage {
              label
              page {
                url
                title
              }
            }
          }
        }
      }
      mobileLinksCollection {
        items {
          label
          page {
            url
          }
        }
      }
    }
  }
  countries: countrySelectorCollection(where: {nameEn: "Header Country Selector"}, limit: 1) {
    items {
      title
      currentLocale
      firstRegion
      secondRegion
      thirdRegion
      fourthRegion
    }
  }
}
`;
