import React from 'react';
import PropTypes from 'prop-types';
import { ShareButton } from '../UI';

const HeaderActions = ({ tag, shareButton, pageUrl, productName, isMobile }) => {
    return (
        <>
            <h1 className={isMobile ? 'mobile-view' : 'desktop-view'}>
                {productName}
            </h1>
            {tag && <p className="product-tag">{tag}</p>}
            <div className="actions">
                {shareButton && (
                    <ShareButton
                        url={pageUrl}
                        buttonLabel={shareButton.buttonLabel}
                        hashtag={shareButton.hashtag}
                        title={shareButton.title}
                        message={shareButton.message}
                        channels={['facebook', 'twitter', 'linkedin', 'email']}
                    />
                )}
            </div>
        </>
    );
};

HeaderActions.propTypes = {
    tag: PropTypes.string,
    isMobile: PropTypes.bool,
    pageUrl: PropTypes.string,
    productName: PropTypes.string,
    shareButton: PropTypes.object,
};

export default HeaderActions;
