import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { InternalLink, Img } from '../UI';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const LinkBlockContainer = (props) => {
    const renderBkgImage = () => {
        if (props.backgroundImage) {
            return <Img alt="" contentfulImage={props.backgroundImage.cfmedia} />;
        }
        return null;
    };

    const links = get(props.contentCollection, 'items', []).map((item, index) => {
        const { label } = item.page;
        const url = get(item.page, 'page.url');
        const backgroundImage = `url(${getCdnImage(item.backgroundImage.cfmedia.url)})`;

        return (
            <InternalLink key={index} link={{ url }} className="block-link event_button_click" ariaLabel={`Learn more about ${label}`} data-action-detail={`Learn more about ${label}`}>
                <div className="block-content" style={{ backgroundImage }} suppressHydrationWarning={true}>
                    <h3>{label}</h3>
                    <div className="arrow-right" />
                </div>
            </InternalLink>
        );
    });

    renderBkgImage.propTypes = {
        backgroundImage: PropTypes.object,
    };

    return (
        <LazyLoad offset={30}>
            <div className="link-block" aria-label={props.ariaLabel}>
                <div className="link-block-wrapper">
                    {links}
                </div>
                {renderBkgImage()}
            </div>
        </LazyLoad>
    );
};

LinkBlockContainer.propTypes = {
    backgroundImage: PropTypes.object,
    contentCollection: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default LinkBlockContainer;
