
import { useState, useEffect } from 'react';

import BazzarVoiceApi from '../../adapters/bazaarVoice.adapter';
import { getProductIds } from '../../helpers/products';

const useMultipleProductRatings = (products) => {
    const [allRatings, setAllRatings] = useState({});
    useEffect(() => {
        const productIds = getProductIds(products);

        BazzarVoiceApi.getMultipleProductRatings(productIds).then(res => {
            if (res && res.Results.length > 0) {
                const ratings = {};
                res.Results.forEach(product => {
                    ratings[product.ProductStatistics.ProductId] = product.ProductStatistics.ReviewStatistics.AverageOverallRating;
                });
                setAllRatings(ratings);
            }
        });
    }, []);

    return allRatings;
};

export default useMultipleProductRatings;
