module.exports = `
{
  pageCollection(where: {nameEn_contains: "Product Detail Page"}, limit: 70) {
    items {
      sys {
        id
      }
      seoHead {
        nameEn
        url
        featuredImage {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
      }
      contentCollection(limit: 8) {
        items {
          __typename
          ... on ProductHeader {
            nameEn
            name
            description
            productBenefit
            bvData {
              productId
            }
            ingredientsLink {
              page {
                title
              }
            }
          }
          ... on StepInfoBlock {
            textBlock
          }
          ... on TextInfoBlock {
            title
            leftBlock: leftBlockCollection(limit: 1) {
              items {
                ... on ContentPlainTextMd {
                  content
                }
              }
            }
            rightBlock: rightBlockCollection(limit: 1) {
              items {
                ... on ContentPlainTextMd {
                  content
                }
              }
            }
          }
          ... on ProductStrengthsContainer {
            productStrengthCollection(limit: 6) {
              items {
                __typename
                ... on ProductStrength {
                  text
                  image {
                    name
                    cfmedia {
                      url
                      width
                      height
                      description
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
