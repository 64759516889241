import React from 'react';

export const buildMessage = (message, searchTerm) => {
    const parts = message.split(/({term})/g);
    return parts.map((part, i) => part === '{term}' ? <b key={i}>{decodeURIComponent(searchTerm)}</b> : part);
};

export const getNumberOfResults = (results) => {
    return results ? results.length : 0;
};

export const getSearchTerm = () => {
    return decodeURIComponent((typeof window !== 'undefined' && window.location.search.replace('?q=', '')) || '');
};

export const cutExtraTitle = (title) => {
    return title.replace(/([|-])\s?Herbal Essences$/, '');
};
