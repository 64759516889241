import React from 'react';
import { CarouselArrow } from '../UI';

export const settingsHandHeld = {
    centerPadding: '70px',
    slidesToShow: 3,
    centerMode: true,
    infinite: true,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                centerPadding: '155px',
                centerMode: true,
                infinite: true,
            },
        },
        {
            breakpoint: 610,
            settings: {
                centerPadding: '110px',
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 510,
            settings: {
                centerPadding: '70px',
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 410,
            settings: {
                centerPadding: '45px',
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
            },
        },
    ],
};
