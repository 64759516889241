import React from 'react';
import PropTypes from 'prop-types';

const ProductVariants = ({ variants, onChange, selectedVariantIndex, name }) => {
    if (variants.items.length < 1) {
        return null;
    }

    const onVariantChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className="product-variants">
            <label htmlFor="sizeSelect">Size: </label>
            <select
                data-action-detail={`${name} - ${variants.items[selectedVariantIndex].size}`}
                className="event_buy_now_choose_product"
                name="variants"
                id="sizeSelect"
                onChange={onVariantChange}
            >
                {
                    variants.items.map((variant, index) => (
                        <option key={variant.gtin} value={index}>
                            {variant.size}
                        </option>
                    ))
                }
            </select>
        </div>
    );
};

ProductVariants.propTypes = {
    onChange: PropTypes.func,
    variants: PropTypes.object,
    name: PropTypes.string,
    selectedVariantIndex: PropTypes.number,
};

export default ProductVariants;
