module.exports = `
... on IngredientsBlockContainer{
    title
    leftImage {
      cfmedia {
          description
          url
          width
          height
      }
    }
    rightImage {
      cfmedia {
          description
          url
          width
          height
      }
    }
    contentCollection {
      items{
        page{
          title
          url
         }
        text
        imageCloudinary {
          name
          cfmedia {
            description
            url
            width
            height
          }
        }
      }
    }
    ariaLabel
  }
`;
