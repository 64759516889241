export const buildThumbnails = (images) => {
    /*
        React-slick doesn't support carousel sliding for situations where
        there are less images than slidesToScroll number in configuration
        setting so in this case we are doubling images in the array
    */
    const thumbnails = images.items.length <= 3
        ? [...images.items, ...images.items]
        : images.items;

    return thumbnails;
};
