import bvPixel from '../../constants/bvPixel.constants';

const { containerId, minPixels, minTime } = bvPixel;

export const bvTrackInView = (inViewData) => {
    // BV lib. is loaded via Layout in the head of the document, and it can be used this way even
    // if it is not defined in this specific function
    // eslint-disable-next-line no-undef
    BV.pixel.trackInView(inViewData, {
        minPixels,
        containerId,
    });
};

export const bvTrackViewedCGC = (inViewData) => {
    // BV lib. is loaded via Layout in the head of the document, and it can be used this way even
    // if it is not defined in this specific function
    // eslint-disable-next-line no-undef
    BV.pixel.trackViewedCGC(inViewData, {
        minPixels,
        minTime,
        containerId,
    });
};
