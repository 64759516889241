export const errorRules = {
    REQUIRED: 'required',
    MAX_LENGTH: 'max_length',
    MIN_LENGTH: 'min_length',
    VALID_EMAIL: 'valid_email',
};

export const fieldNames = {
    OVERALL_RATING: 'overallRating',
    RECOMMEND: 'recommend',
    REVIEW_SUMMARY: 'reviewSummary',
    REVIEW: 'review',
    NICKNAME: 'nickname',
    YEAR: 'year',
    MONTH: 'month',
    LOCATION: 'location',
    EMAIL: 'email',
    HAIR_TYPE: 'hairType',
    TOS: 'tos',
};

export const validations = {
    overallRating: [{ rule: errorRules.REQUIRED }],
    recommend: [{ rule: errorRules.REQUIRED }],
    reviewSummary: [
        { rule: errorRules.REQUIRED },
        { rule: errorRules.MAX_LENGTH, ruleValue: 100 },
    ],
    review: [
        { rule: errorRules.REQUIRED },
        { rule: errorRules.MIN_LENGTH, ruleValue: 50 },
        { rule: errorRules.MAX_LENGTH, ruleValue: 9999 },
    ],
    nickname: [
        { rule: errorRules.REQUIRED },
        { rule: errorRules.MIN_LENGTH, ruleValue: 4 },
        { rule: errorRules.MAX_LENGTH, ruleValue: 25 },
    ],
    year: [{ rule: errorRules.REQUIRED }],
    month: [{ rule: errorRules.REQUIRED }],
    location: [
        { rule: errorRules.REQUIRED },
        { rule: errorRules.MAX_LENGTH, ruleValue: 50 },
    ],
    email: [
        { rule: errorRules.REQUIRED },
        { rule: errorRules.VALID_EMAIL },
        { rule: errorRules.MAX_LENGTH, ruleValue: 255 },
    ],
    hairType: [{ rule: errorRules.REQUIRED }],
    tos: [{ rule: errorRules.REQUIRED }],
};
