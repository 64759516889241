import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import labels from '../../constants/label.constants';
import { AriaLiveRegion, Img } from '../UI';
import { importedSettings } from './slickSettings.const';

const CollectionCarousel = (props) => {
    const [clicked, setClicked] = useState(false);
    const [crouselIndex, setCrouselIndex] = useState(20);
    const { slidesCollection } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [clientRender, setClientRender] = useState(false);
    const slidesLength = slidesCollection.items.length;
    const settings = {
        ...importedSettings,
        afterChange: setCurrentSlide,
    };

    useEffect(() => {
        if (process.browser) {
            setClientRender(true);
        }
    }, []);

    const clickHandler = (i) => {
        setClicked(true);
        setCrouselIndex(i);
    };
    const handleClose = () => {
        setClicked(false);
    };

    const playWithEnter = (event) => {
        if (event.key === 'Enter') {
            clickHandler();
        }
    };

    const slides = slidesCollection.items.map((item, index) => {
        if (item.__typename === 'Image') {
            return (
                <div key={index} className="Slide">
                    <Img contentfulImage={item.cfmedia} />
                </div>
            );
        }

        return (
            <div
                role="button"
                tabIndex={0}
                className="Slide video-poster-wrapper"
                onKeyPress={playWithEnter}
                onClick={() => clickHandler(index)}
                aria-label={labels.video.play}
            >
                <Img className="video-poster" contentfulImage={item.videoPoster.cfmedia} />
                <div className="play-wrapper">
                    <div className="arrow-play-video event_video_play" />
                    <span>{labels.video.play}</span>
                </div>
            </div>
        );
    });

    // "separate initializations for server and client due to a react-slik bug; don't change!"
    const renderSlides = () => (
        <>
            {!clientRender && <Slider {...settings}>{slides}</Slider>}
            {clientRender && <Slider {...settings}>{slides}</Slider>}
        </>
    );

    return (
        <>
            <div className="slides-collection-container">
                <div className="slides">
                    {renderSlides()}
                    <AriaLiveRegion current={currentSlide + 1} total={slidesLength} />
                </div>
            </div>
            {clicked ? (
                <div className="overlay" onClick={handleClose}>
                    <div className="video-container">
                        <button type="button">x</button>
                        <iframe
                            title="pop up video"
                            src={slidesCollection.items[crouselIndex]?.video.url}
                            style={{
                                width: '100%',
                            }}
                            allow="autoplay"
                        />
                    </div>
                </div>
            ) : (<></>)}

        </>
    );
};

CollectionCarousel.propTypes = {
    title: PropTypes.string,
    slidesCollection: PropTypes.object,
    leftImg: PropTypes.object,
    rightImg: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default CollectionCarousel;
