import React from 'react';
import PropTypes from 'prop-types';

const ButtonTab = ({ isDisabled, onClick, className, iconClassName, children, isActive, controlsContent, dataActionDetail }) => {
    const buttonClassName = isDisabled ? `${className} disabled` : className;

    const ariaLabel = typeof children === 'string' ? children : '';

    return (
        <button
            role="tab"
            onClick={onClick}
            disabled={isDisabled}
            className={buttonClassName}
            type="button"
            aria-label={ariaLabel}
            aria-selected={isActive ? 'true' : 'false'}
            aria-disabled={isDisabled ? 'true' : 'false'}
            aria-controls={controlsContent || ''}
            data-action-detail={dataActionDetail}
        >
            <span className={iconClassName} />{children}
        </button>
    );
};

ButtonTab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    controlsContent: PropTypes.string.isRequired,
    dataActionDetail: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default ButtonTab;
