import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, RatingStars } from '../../UI';
import AppContext from '../../../context/App.context';
import labels from '../../../constants/WriteReview.constants';

const ConfirmModal = ({ isOpen, onClose, onSubmit, fields, submitStatus, previewStatus, gtin }) => {
    if (!isOpen) {
        return null;
    }

    const { appState, ref } = useContext(AppContext);

    const { writeAndReview } = appState;
    const { setIsWriteReviewFormOpen } = writeAndReview;

    const contentWrapperRef = ref;

    const renderCurrentDate = () => {
        const date = new Date().toLocaleDateString(process.env.LOCALE);
        return <span>{date}</span>;
    };

    const Preview = () => {
        if (submitStatus === 'success' || submitStatus === 'error') return null;

        const { title, buttons, yesRecommend, notRecommend, userLocation } = labels.previewModal;
        const { nickname, overallRating, reviewSummary, review, recommend, location } = fields;

        return (
            <>
                <h2>{title}</h2>
                <div className="modal-content-wrapper">
                    <div className="user-info">
                        <div className="current-date">{renderCurrentDate()}</div>
                        <RatingStars averageRating={overallRating} showRatingsLabel={true} />
                        <p className="nickname">{nickname}</p>
                        <p className="user-location">{userLocation} {location}</p>
                    </div>
                    <div className="review-preview">
                        <RatingStars averageRating={overallRating} showRatingsLabel={true} />
                        <h4>{reviewSummary}{renderCurrentDate()}</h4>
                        <p>{review}</p>
                        <p className="recommend">
                            {recommend === 'yes' ? yesRecommend : notRecommend}
                        </p>
                        {previewStatus && <p role="alert" className="error">{previewStatus}</p>}
                        <div className="buttons">
                            {previewStatus === null && <button type="button" data-action-detail={gtin} onClick={onSubmit} className="primary-button event_submit_review" aria-label={buttons.submit}>{buttons.submit}</button>}
                            <button type="button" onClick={onClose} className="secondary-button" aria-label={buttons.edit}>{buttons.edit}</button>
                        </div>
                    </div>
                </div>
                <input type="hidden" name="blackBox" id="ioBlackBox" />
            </>
        );
    };

    const renderStatus = () => {
        const { title, message, continueButton } = labels.thankYouModal;

        const handleSubmit = () => {
            if (submitStatus === 'success') {
                onClose();
                setIsWriteReviewFormOpen(false);
                contentWrapperRef.current.scrollIntoView();
            }
            onClose();
        };

        return (
            <div className="centered-content">
                <h2>{title}</h2>
                <p>{message}</p>
                <button type="button" className="primary-button" onClick={handleSubmit} aria-label={continueButton}>{continueButton}</button>
            </div>
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Preview labels={labels.previewModal} />
            {submitStatus === 'success' && renderStatus(labels.thankYouModal)}
            {submitStatus === 'error' && renderStatus(labels.errorModal)}
        </Modal>
    );
};

ConfirmModal.propTypes = {
    fields: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitStatus: PropTypes.string.isRequired,
    previewStatus: PropTypes.array.isRequired,
    gtin: PropTypes.string,
};

export default ConfirmModal;
